import { Component } from '@angular/core';
import bibAtalho from 'src/app/biblioteca/bibAtalho';
import { Criterio } from 'src/app/modelo/criterio';
import { Entrega } from 'src/app/modelo/entrega';
import { EntregaProduto } from 'src/app/modelo/entregaProduto';
import { Financeiro } from 'src/app/modelo/financeiro';
import { Identificacao } from 'src/app/modelo/identificacao';
import { Movimentacao } from 'src/app/modelo/movimentacao';
import { MovimentacaoProduto } from 'src/app/modelo/movimentacaoProduto';
import { MovimentacaoQuantidade } from 'src/app/modelo/movimentacaoQuantidade';
import { Negociacao } from 'src/app/modelo/negociacao';
import { Parceiro } from 'src/app/modelo/parceiro';
import { Produto } from 'src/app/modelo/produto';
import { Resultado } from 'src/app/modelo/resultado';
import { Transporte } from 'src/app/modelo/transporte';
import { Usuario } from 'src/app/modelo/usuario';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';
import { Util } from 'src/app/utilitario/util';

@Component({
  templateUrl: './separarEntregaFrm.component.html',
  styleUrls: ['./separarEntregaFrm.component.css'],
})
export class SepararEntregaFrmComponent extends PaginaComponent {
  private produtos: Produto[] = [];
  public categoria: string;
  public colaborador: string;
  public confirmada: boolean = false;
  public criteriosResponsavel: Criterio[] = [new Criterio('ID_MENU', this.utilSessao.getPermissao().idMenu)];
  public entrega: Entrega = new Entrega();
  public focoBuscaResponsavel: number;
  public focoIdSenha: number;
  public focoObservacao: number;
  public idColaborador: number;
  public idLojas: string;
  public indiceFoco: string = 'q0';
  public movimentacao: Movimentacao = new Movimentacao();
  public observacao: string;
  public posicaoProduto: number = 0;
  public posicaoQuantidade: number = 0;
  public quantidadeFocada: boolean = false;
  public util: Util = new Util();

  ngOnInit(): void {
    this.categoria = this.utilSessao.getApresentaCategoria();
    this.ehAlteracao();
  }

  ehAlteracao(): void {
    this.utilSessao.getLojas().forEach((loja) => (this.idLojas = this.util.concatenar(this.idLojas, loja.id, ',')));
    const id: number = this.utilSessao.getIdentificacao('id')?.conteudo;
    this.idColaborador = this.utilSessao.getUsuario().idColaborador;
    this.colaborador = this.utilSessao.getUsuario().colaborador;
    this.listarMovimentacao(id);
  }

  listarMovimentacao(id: number): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ID', id)), this.bibServico.movimentacao).subscribe((res) => {
      this.movimentacao = this.plainToClass(Movimentacao, res[0]) as any;
      this.setMovimentacaoPermiteEntrega();
      this.listarMovimentacaoProduto(this.movimentacao.id);
    });
  }

  private setMovimentacaoPermiteEntrega(): void {
    let criterios: Criterio[] = [new Criterio('ID_MOVIMENTACAO', this.movimentacao.id), new Criterio('TIPO', 'R'), new Criterio('PARCIALMENTE_PAGO', 'S')];
    this.comunicacaoService.listar(new Transporte(criterios), this.bibServico.financeiro).subscribe((res) => {
      const financeiros: Financeiro[] = this.plainToClass(Financeiro, res) as any;
      this.comunicacaoService.listar(new Transporte(new Criterio('ID', this.movimentacao.idNegociacao)), this.bibServico.negociacao).subscribe((res) => {
        const negociacao: Negociacao = this.plainToClass(Negociacao, res[0]) as any;
        this.movimentacao.permitirEntregaDireta = negociacao.permitirEntregaDireta;
        this.movimentacao.permiteEntrega = financeiros.length > 0 || this.movimentacao.valorTotal == 0 ? 'S' : 'N';
      });
    });
  }

  listarMovimentacaoProduto(idMovimentacao: number): void {
    this.comunicacaoService.listar(new Transporte([new Criterio('ID_MOVIMENTACAO', idMovimentacao), new Criterio('LOJAS', this.idLojas)]), this.bibServico.movimentacaoProduto).subscribe((res) => {
      this.movimentacao.movimentacaoProdutos = this.plainToClass(MovimentacaoProduto, res) as any;
      const idProdutos: number[] = this.movimentacao.movimentacaoProdutos.map((movimentacaoProduto) => movimentacaoProduto.idProduto);
      this.comunicacaoService.listar(new Transporte([new Criterio('IDS', idProdutos.toString()), new Criterio('ID_LOJA', this.movimentacao.idLoja)]), this.bibServico.produto).subscribe((res) => {
        this.produtos = this.plainToClass(Produto, res) as any;
        const idsMovimentacaoProdutos: number[] = this.movimentacao.movimentacaoProdutos.map((movimentacaoProduto) => movimentacaoProduto.id);
        this.comunicacaoService.listar(new Transporte([new Criterio('IDS_MOVIMENTACAO_PRO', idsMovimentacaoProdutos.toString()), new Criterio('LOJAS', this.idLojas)]), this.bibServico.movimentacaoQuantidade).subscribe((res) => {
          const movimentacaoQuantidades: MovimentacaoQuantidade[] = this.plainToClass(MovimentacaoQuantidade, res) as any;
          this.setProdutosDados();
          this.setMovimentacaoQuantidades(movimentacaoQuantidades);
          this.ordenarProdutosCategoria();
          this.focoBuscaResponsavel = Math.random();
        });
      });
    });
  }

  setProdutosDados(): void {
    this.produtos.forEach((produto) => {
      this.movimentacao.movimentacaoProdutos.forEach((movimentacaoProduto) => {
        if (movimentacaoProduto.idProduto == produto.id) {
          movimentacaoProduto.grupo = produto.grupo;
          movimentacaoProduto.categoria = produto.categoria;
          movimentacaoProduto.numeroProduto = produto.numero;
          movimentacaoProduto.quantidadeDisponvel = produto.quantidadeDisponivel;
        }
      });
    });
  }

  setColaborador(colaborador: Parceiro): void {
    this.idColaborador = colaborador.id;
    this.colaborador = colaborador.razaoSocial;
  }

  setMovimentacaoQuantidades(movimentacaoQuantidades: MovimentacaoQuantidade[]): void {
    this.movimentacao.movimentacaoProdutos.map((movimentacaoProduto) =>
      movimentacaoQuantidades.map((movimentacaoQuantidade) => {
        if (movimentacaoProduto.id == movimentacaoQuantidade.idMovimentacaoProduto) {
          movimentacaoProduto.movimentacaoQuantidades.push(movimentacaoQuantidade);
        }
      })
    );
  }

  ordenarProdutosCategoria(): void {
    this.movimentacao.movimentacaoProdutos.sort((a, b) => (a['categoria'] == b['categoria'] ? 0 : a['categoria'] > b['categoria'] ? 1 : -1));
  }

  exigirSenha(): void {
    if (this.utilSessao.getUsuario()?.agrow == 'S') {
      this.persistirSepararEntrega();
    } else {
      if (this.idColaborador) {
        this.focoIdSenha = Math.random();
      } else {
        this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.verifiqueObrigatorio));
      }
    }
  }

  conferirSenha(chave: string): void {
    if (chave && chave.length == 6) {
      this.focoIdSenha = null;
      this.listarUsuarioChave(chave);
    }
  }

  listarUsuarioChave(chave: string): void {
    this.comunicacaoService.listar(new Transporte([new Criterio('KEY', chave), new Criterio('ID_COLABORADOR', this.idColaborador)]), this.bibServico.usuario).subscribe((res) => {
      const usuario: Usuario = this.plainToClass(Usuario, res[0]) as any;
      if (usuario) {
        if (usuario.idColaborador == this.idColaborador) {
          this.persistirSepararEntrega();
        } else {
          this.utilSessao.setResultado(new Resultado(false, this.util.substituir(this.bibDialogo.chaveNaoVinculadaAoResponsavel, [this.colaborador])));
        }
      } else {
        this.utilSessao.setResultado(new Resultado(false, this.util.substituir(this.bibDialogo.chaveNaoVinculadaAoResponsavel, [this.colaborador])));
      }
    });
  }

  persistirSepararEntrega(): void {
    if (this.ehValido()) {
      this.entrega.observacao = this.observacao;
      this.entrega.dataHoraSeparacao = new Date();
      this.entrega.idLoja = this.movimentacao.idLoja;
      this.entrega.idMovimentacao = this.movimentacao.id;
      this.entrega.idColaboradorSep = this.idColaborador;
      this.entrega.entregaProdutos = [];
      this.movimentacao.movimentacaoProdutos.forEach((movimentacaoProduto) => {
        movimentacaoProduto.movimentacaoQuantidades.forEach((movimentacaoQuantidade) => {
          if (movimentacaoQuantidade.quantidadeSepararEntrega > 0) {
            let entregaProduto: EntregaProduto = new EntregaProduto();
            entregaProduto.idEmpresa = movimentacaoProduto.idEmpresa;
            entregaProduto.idProduto = movimentacaoProduto.idProduto;
            entregaProduto.idMovimentacao = this.movimentacao.id;
            entregaProduto.idMovimentacaoQuantidade = movimentacaoQuantidade.id;
            entregaProduto.quantidade = movimentacaoQuantidade.quantidadeSepararEntrega;
            entregaProduto.idLoja = movimentacaoQuantidade.idLoja;
            entregaProduto.idLocal = movimentacaoQuantidade.idLocal;
            entregaProduto.idLote = movimentacaoQuantidade.idLote;
            this.entrega.entregaProdutos.push(entregaProduto);
          }
        });
      });
      super.persistir(new Transporte(this.entrega), this.bibServico.entrega, null).subscribe(() => {
        if (!this.utilSessao.falha) {
          this.confirmada = true;
          if (this.utilSessao.getEmpresa().idRelatorioEntrega) {
            this.utilSessao.setIdentificacao(new Identificacao('controlaPermissao', false));
            this.util.abrirRelatorio(this.entrega.id, this.utilSessao.getEmpresa().idRelatorioEntrega, true, true);
          }
        }
      });
    }
  }

  ehValido(): boolean {
    const obrigatorio = this.ehValidoObrigatorio();
    const quantidade = this.ehValidoQuantidade();
    return obrigatorio && quantidade;
  }

  ehValidoObrigatorio(): boolean {
    if (!this.idColaborador) {
      this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.verifiqueObrigatorio));
      return false;
    }
    return true;
  }

  ehValidoQuantidade(): boolean {
    let peloMenosUmProduto: boolean = false;
    let quantidadeSuperiorRestante: boolean = true;
    this.movimentacao.movimentacaoProdutos.map((movimentacaoProduto) => {
      movimentacaoProduto.movimentacaoQuantidades.map((movimentacaoQuantidade) => {
        if (movimentacaoQuantidade.quantidadeSepararEntrega > 0) {
          peloMenosUmProduto = true;
        }
        if (movimentacaoQuantidade.quantidadeSepararEntrega > movimentacaoQuantidade.quantidadePendente) {
          quantidadeSuperiorRestante = false;
          this.utilSessao.setResultado(new Resultado(false, 'O produto ' + movimentacaoProduto.produto + ' está com quantidade (' + movimentacaoQuantidade.quantidadeSepararEntrega + ') maior que a quantidade restante (' + movimentacaoQuantidade.quantidadePendente + ').'));
        }
      });
      if (peloMenosUmProduto == false) {
        this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.informeQuantidadeUmPro));
      }
    });

    return peloMenosUmProduto && quantidadeSuperiorRestante;
  }

  setQuantidadeEntregue(evento: any, indiceProduto: number, indiceQuantidade: number): void {
    switch (evento.keyCode) {
      case bibAtalho.espaco:
        if (this.quantidadeFocada) {
          const movimentacaoQuantidade: MovimentacaoQuantidade = this.movimentacao.movimentacaoProdutos[indiceProduto].movimentacaoQuantidades[indiceQuantidade];
          movimentacaoQuantidade.quantidadeSepararEntrega = movimentacaoQuantidade.quantidadePendente;
          setTimeout((_) => this.focarProximoDisponivel(indiceProduto, indiceQuantidade));
        }
        break;
    }
  }

  focarProximoDisponivel(indiceProduto: number, indiceQuantidade: number): void {
    this.posicaoProduto = indiceProduto;
    this.posicaoQuantidade = indiceQuantidade + 1;
    let chegouObjetivo: boolean = false;
    let elementoDisponivel: HTMLElement;
    while (!chegouObjetivo) {
      elementoDisponivel = this.encontrarProximoDisponivel();
      if (elementoDisponivel) {
        chegouObjetivo = true;
      } else {
        chegouObjetivo = this.continuarProcurandoDisponivel();
      }
    }
    if (elementoDisponivel != null) {
      elementoDisponivel.focus();
    } else {
      this.focoObservacao = Math.random();
    }
  }

  encontrarProximoDisponivel(): HTMLElement {
    this.indiceFoco = 'P' + this.posicaoProduto + '_' + this.posicaoQuantidade;
    let elementoDisponivel: HTMLElement = document.getElementById(this.indiceFoco);
    if (elementoDisponivel != null) {
      const movimentacaoQuantidade: MovimentacaoQuantidade = this.movimentacao.movimentacaoProdutos[this.posicaoProduto].movimentacaoQuantidades[this.posicaoQuantidade];
      const desabilitado: boolean = this.confirmada || movimentacaoQuantidade.quantidadePendente == 0 || (this.movimentacao.permiteEntrega != 'S' && this.movimentacao.permitirEntregaDireta != 'S');
      if (desabilitado) {
        elementoDisponivel = null;
      }
    }
    return elementoDisponivel;
  }

  continuarProcurandoDisponivel(): boolean {
    let chegouObjetivo: boolean = false;
    if (this.posicaoQuantidade >= this.movimentacao.movimentacaoProdutos[this.posicaoProduto].movimentacaoQuantidades.length - 1) {
      if (this.posicaoProduto >= this.movimentacao.movimentacaoProdutos.length - 1) {
        chegouObjetivo = true;
      } else {
        this.posicaoProduto++;
        this.posicaoQuantidade = 0;
      }
    } else {
      this.posicaoQuantidade++;
    }
    return chegouObjetivo;
  }

  setPosicao(evento: boolean, j: number, i: number): void {
    this.quantidadeFocada = evento;
    if (evento) {
      this.posicaoProduto = j;
      this.posicaoQuantidade = i;
    }
  }

  entregarTodos(): void {
    this.movimentacao.movimentacaoProdutos.forEach((movimentacaoProduto, i) => {
      movimentacaoProduto.movimentacaoQuantidades.forEach((movimentacaoQuantidade) => {
        movimentacaoQuantidade.quantidadeSepararEntrega = movimentacaoQuantidade.quantidadePendente;
      });
    });
  }
}
