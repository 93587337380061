import { Component } from '@angular/core';
import { Criterio } from 'src/app/modelo/criterio';
import { Filtro } from 'src/app/modelo/filtro';
import { Financeiro } from 'src/app/modelo/financeiro';
import { Movimentacao } from 'src/app/modelo/movimentacao';
import { Transporte } from 'src/app/modelo/transporte';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';
import filtros from './filtro';
import { format } from 'date-fns';

@Component({
  templateUrl: './separarEntrega.component.html',
})
export class SepararEntregaComponent extends PaginaComponent {
  public filtros: Filtro[] = filtros;
  public idLojas: string;
  public entregaMovimentacoes: Movimentacao[] = [];
  public movimentacoes: Movimentacao[] = [];
  public categoria: string;
  public dataAtualizacao: Date;
  public consultaManual: boolean = true;
  private colunaOrdem: string = '';
  private ordem: number = 1;
  public quantidadeRegistro: number;
  private criterios: Criterio[] = [];
  public parado: boolean = true;

  ngOnInit(): void {
    const entregaMovimentacoes: Movimentacao[] = JSON.parse(sessionStorage.getItem('entregaMovimentacoes'));
    if (entregaMovimentacoes) {
      this.entregaMovimentacoes = this.plainToClass(Movimentacao, entregaMovimentacoes) as any;
    }
  }

  listar(criterios: Criterio[]): void {
    this.ehApresentarCategoriaSubTitulo();
    criterios = this.adicionarCriterioHoje(criterios);
    this.comunicacaoService.listar(new Transporte(criterios, 0, 0, true, this.utilSessao.numeroRegistro), this.bibServico.movimentacaoEntrega, false, true, true, this.consultaManual || this.parado).subscribe((res) => {
      const movimentacoes: Movimentacao[] = this.plainToClass(Movimentacao, res) as any;
      if (criterios.filter((criterio) => criterio.nome == 'HOJE').length == 0) {
        if (criterios.length == 1) {
          this.definirMovimentacaoAntiga(movimentacoes.filter((movimentacao) => format(movimentacao.data, 'dd/MM/yy') != format(new Date(), 'dd/MM/yy')));
        }
      } else {
        this.entregaMovimentacoes = this.entregaMovimentacoes.filter((movimentacao) => format(movimentacao.data, 'dd/MM/yy') != format(new Date(), 'dd/MM/yy'));
        for (const entregaMovimentacao of this.entregaMovimentacoes) {
          movimentacoes.push(entregaMovimentacao);
        }
      }
      this.movimentacoes = movimentacoes;
      this.quantidadeRegistro = this.movimentacoes.length;
      this.analisarFinanceiro();
    });
  }

  listarId(): void {
    const id: number = JSON.parse(sessionStorage.getItem('entregaId'));
    sessionStorage.removeItem('entregaId');
    if (id) {
      this.comunicacaoService.listar(new Transporte([new Criterio("ID", id)], 0, 0, true, this.utilSessao.numeroRegistro), this.bibServico.movimentacaoEntrega, false, true, true, false).subscribe((res) => {
        const movimentacaoAtualizada: Movimentacao = this.plainToClass(Movimentacao, res[0]) as any;
        if (movimentacaoAtualizada) {
          let entregaMovimentacao: Movimentacao = this.entregaMovimentacoes.find((entregaMovimentacao) => entregaMovimentacao.id == id);
          if (entregaMovimentacao) {
            entregaMovimentacao = movimentacaoAtualizada;
          }

          let movimentacao: Movimentacao = this.movimentacoes.find((movimentacao) => movimentacao.id == id);
          if (movimentacao) {
            movimentacao = movimentacaoAtualizada;
          }
        } else {
          this.definirMovimentacaoAntiga(this.entregaMovimentacoes.filter((entregaMovimentacao) => entregaMovimentacao.id != id));
          this.movimentacoes = this.movimentacoes.filter((movimentacao) => movimentacao.id != id);
          this.quantidadeRegistro = this.movimentacoes.length;
        }
      });
    }
  }

  ehApresentarCategoriaSubTitulo(): void {
    this.categoria = '';
    const criterio: Criterio = this.criterios.find((criterio) => criterio.nome == 'ID_CATEGORIA');
    if (criterio) {
      this.categoria = criterio.apresentacao;
    }
    this.utilSessao.setApresentaCategoria(this.categoria);
  }

  adicionarCriterioHoje(criterios: Criterio[]): Criterio[] {
    if (this.entregaMovimentacoes.length > 0 && criterios.length == 1 && criterios.filter((criterio) => criterio.nome == 'ID').length == 0) {
      criterios.push(new Criterio("HOJE", "S"));
    } else {
      this.definirMovimentacaoAntiga([]);
    }
    return criterios;
  }

  analisarFinanceiro(): void {
    const idMovimentacoes: number[] = this.movimentacoes.filter((movimentacao) => movimentacao.permiteEntrega != 'S').map((movimentacao) => movimentacao.id);
    if (idMovimentacoes.length > 0) {
      let criterios: Criterio[] = [new Criterio('IDS_MOVIMENTACAO', idMovimentacoes.toString()), new Criterio('TIPO', 'R'), new Criterio('PARCIALMENTE_PAGO', 'S')];
      this.comunicacaoService.listar(new Transporte(criterios), this.bibServico.financeiro, false, true, true, this.consultaManual || this.parado).subscribe((res) => {
        this.atualizarEntrega(this.plainToClass(Financeiro, res) as any);
        this.listarId();
      });
    } else {
      this.listarId();
    }
  }

  atualizarEntrega(financeiros: Financeiro[]) {
    financeiros.forEach((financeiro) => {
      this.movimentacoes.find((movimentacao) => movimentacao.id == financeiro.idMovimentacao).permiteEntrega = 'S';
    });
    sessionStorage.setItem('entregaMovimentacoes', JSON.stringify(this.movimentacoes));
  }

  ordenar(coluna: string): void {
    this.ordem = this.colunaOrdem == coluna ? this.ordem * -1 : 1;
    this.colunaOrdem = coluna;
    this.movimentacoes.sort((a, b) => {
      return a[coluna] === b[coluna] ? 0 : a[coluna] > b[coluna] ? 1 * this.ordem : -1 * this.ordem;
    });
  }

  irEntrega(movimentacao: Movimentacao, i: number, movimentacoes: Movimentacao[]): void {
    if (format(movimentacao.data, 'dd/MM/yy') != format(new Date(), 'dd/MM/yy')) {
      sessionStorage.setItem('entregaId', JSON.stringify(movimentacao.id));
    }
    this.ir(movimentacao.id, i, movimentacoes);
  }

  definirMovimentacaoAntiga(entregaMovimentacoes: Movimentacao[]) {
    this.entregaMovimentacoes = entregaMovimentacoes.filter((movimentacao) => format(movimentacao.data, 'dd/MM/yy') != format(new Date(), 'dd/MM/yy'));
    sessionStorage.setItem('entregaMovimentacoes', JSON.stringify(this.entregaMovimentacoes));
  }
}
