<layout (filtroEmt)="listar($event, utilSessao.posicao, this.paginacao)" [filtros]="filtros">
  <registro [posicao]="utilSessao.posicao" [paginacao]="paginacao" [numeroRegistro]="utilSessao.numeroRegistro"></registro>
  <table class="table table-responsive-sm">
    <thead>
      <tr>
        <th class="l-5"></th>
        <th class="l-5"></th>
        <th class="l-5 clique" (click)="listar(add('lojaAbreviacao'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.loja }}</th>
        <th class="l-5 clique" (click)="listar(add('data'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.data }}</th>
        <th class="l-10 text-right clique" (click)="listar(add('numero'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.numero }}</th>
        <th class="l-5 clique" (click)="listar(add('responsavel'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.res }}</th>
        <th [ngClass]="parametro == 'DEPOSITO' || parametro == 'GESTOR' ? 'l-55' : 'l-35'" class="clique" (click)="listar(add('parceiro'), this.utilSessao.posicao, this.paginacao)">{{ parametro == 'DEPOSITO' ? bibDialogo.cliente : bibDialogo.clienteFornecedor }}</th>
        <th *ngIf="parametro != 'DEPOSITO' && parametro != 'GESTOR'" class="l-20 clique" (click)="listar(add('aprovacaoMotivo'), this.utilSessao.posicao, this.paginacao)">{{ bibDialogo.motivo }}</th>
        <th class="l-5"></th>
        <th *ngIf="parametro != 'COMISSAO' && aprovacoes.length > 0 && aprovacoes[0].idAprovacaoMotivo != 14" class="l-5"></th>        
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let aprovacao of aprovacoes; let i = index">
        <tr>
          <td><i *ngIf="aprovacao.finalizado == 'S'" [ngClass]="bibIcone.cadeadoFechado"></i></td>
          <td>
            <icone *ngIf="aprovacao.situacao == 'P'" [icone]="bibIcone.circulo" [cor]="bibPropriedade.icone.azul" [mensagem]="bibDialogo.pendente" [tamanho]="bibPropriedade.tamanho.medio"></icone>
            <icone *ngIf="aprovacao.situacao == 'R'" [icone]="bibIcone.menos" [cor]="bibPropriedade.icone.vermelho" [mensagem]="bibDialogo.recusado + ' por ' + aprovacao.aprovadorReprovador + ' em ' + (aprovacao.dataHoraAlteracao | data: 'dd/MM/yy HH:mm')" [tamanho]="bibPropriedade.tamanho.medio" [ajudaDireita]="true"></icone>
            <icone *ngIf="aprovacao.situacao == 'A'" [icone]="bibIcone.checado" [cor]="bibPropriedade.icone.verde" [mensagem]="bibDialogo.aprovado + ' por ' + aprovacao.aprovadorReprovador + ' em ' + (aprovacao.dataHoraAlteracao | data: 'dd/MM/yy HH:mm')"  [tamanho]="bibPropriedade.tamanho.medio" [ajudaDireita]="true"></icone>
          </td>
          <td>{{ aprovacao.lojaAbreviacao }}</td>
          <td>{{ aprovacao.data | data }}</td>
          <td class="text-right">{{ aprovacao.numero }}</td>
          <td class="limitar">{{ aprovacao.responsavelAbreviacao }} <ajuda [ajuda]="aprovacao.responsavel"></ajuda></td>
          <td class="limitar">{{ aprovacao.numeroParceiro + ' - ' + aprovacao.parceiro }}</td>
          <td *ngIf="parametro != 'DEPOSITO' && parametro != 'GESTOR'" class="limitar">{{ aprovacao.aprovacaoMotivo }}</td>
          <!-- <td><atencao *ngIf="aprovacao.situacao == 'A' || aprovacao.situacao == 'R'" class="aprovacao.situacao == 'A' ? bibPropriedade.atencao.sucesso : bibPropriedade.atencao.cuidado" [atencao]=""></atencao></td> -->
          <td>
            <btnAdicional [icone]="aprovacao.situacao == 'A' || aprovacao.situacao == 'R' ? bibIcone.olho : bibIcone.editar" (btnAdicional)="abrirModal(aprovacao.id)"></btnAdicional>
          </td>
          <td *ngIf="parametro != 'COMISSAO'">
            <btnAdicional [icone]="bibIcone.prancheta" (btnAdicional)="abrirModalOrigem(aprovacao)" [mensagem]="aprovacao.categoria == 'G' ? bibDialogo.visualizarFinanceiro : bibDialogo.visualizarMovimentacao"></btnAdicional>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
  <paginacao *ngIf="!this.utilSessao.telaInterna" [demanda]="true" [paginacao]="paginacao" [numeroRegistro]="utilSessao.numeroRegistro" (alteracao)="listar(utilSessao.criterios, $event, this.paginacao)" (paginacaoEmt)="listar(utilSessao.criterios)"></paginacao>
</layout>
<modal *ngIf="componente" [componente]="componente" (modalEmt)="componente = null"></modal>
