import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { TabelaPreco } from 'src/app/agrow/modelo/tabelaPreco';
import { TabelaPrecoConfiguracao } from 'src/app/agrow/modelo/tabelaPrecoConfiguracao';
import bibAtalho from 'src/app/biblioteca/bibAtalho';
import { BibNomenclatura } from 'src/app/biblioteca/bibNomenclatura';
import { ImagemComponent } from 'src/app/essencial/imagem/imagem.component';
import { ClienteEspecial } from 'src/app/modelo/clienteEspecial';
import { Criterio } from 'src/app/modelo/criterio';
import { Estoque } from 'src/app/modelo/estoque';
import { EstoqueProdutoMinimoMaximo } from 'src/app/modelo/estoqueProdutoMinimoMaximo';
import { Fabricante } from 'src/app/modelo/fabricante';
import { Grupo } from 'src/app/modelo/grupo';
import { Identificacao } from 'src/app/modelo/identificacao';
import { Loja } from 'src/app/modelo/loja';
import { Permissao } from 'src/app/modelo/permissao';
import { Produto } from 'src/app/modelo/produto';
import { ProdutoEstoqueMinimoMaximo } from 'src/app/modelo/produtoEstoqueMinimoMaximo';
import { ProdutoFoto } from 'src/app/modelo/produtoFoto';
import { Resultado } from 'src/app/modelo/resultado';
import { Transporte } from 'src/app/modelo/transporte';
import { TabelaPrecoModalComponent } from 'src/app/pagina/movimentacao/frm/produto/tabelaPrecoModal/tabelaPrecoModal.component';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';
import { Util } from 'src/app/utilitario/util';

@Component({
  selector: 'pesquisaProduto',
  templateUrl: './pesquisaProduto.component.html',
  styleUrls: ['./pesquisaProduto.component.css'],
})
export class PesquisaProdutoComponent extends PaginaComponent {
  @ViewChild('corpoTabelaProduto') private corpoTabelaProduto: ElementRef;
  private criterio: string = '';
  private criterios: Criterio[] = [new Criterio('NOME', this.criterio), new Criterio('FABRICANTE', this.criterio), new Criterio('GRUPO', this.criterio)];
  private estoques: Estoque[] = [];
  private idCliente: number;
  private idColaborador: number;
  private modalEmt: any;
  private produtoEstoqueMininoMaximos: ProdutoEstoqueMinimoMaximo[] = [];
  private produtoModal: Produto;
  public abaFotoAtiva: boolean;
  public abaMaisInformacoes: boolean = false;
  public acrescimoPercentual: number;
  public apresentaFiltros: boolean = false;
  public bibNomenclatura: BibNomenclatura = new BibNomenclatura();
  public blocoQuantidadeAberto: boolean = false;
  public clienteEspecial: ClienteEspecial;
  public componente: any = null;
  public custoGerencial: string;
  public entrada: boolean = false;
  public entradaServico: boolean = false;
  public estoqueProdutoMinimoMaximos: EstoqueProdutoMinimoMaximo[] = [];
  public filtro = this.criterios[0];
  public filtroFabricante = new Criterio('ID_FABRICANTE');
  public filtroGrupo = new Criterio('ID_GRUPO');
  public filtroSaldoAte = new Criterio('SALDO_ATE');
  public filtroSaldoDe = new Criterio('SALDO_DE');
  public focoBusca: number;
  public focoQuantidadeProduto: number;
  public idLoja: number = 0;
  public interna: boolean = false;
  public loja: Loja;
  public lojas: Loja[] = [];
  public naturezaOperacaoUsaCustoReposicao: string = 'N';
  public nomeFabricante: string;
  public nomeGrupo: string;
  public nomenclaturaCustoGerencial: string = '';
  public orcamento: boolean;
  public pesquisando: boolean = false;
  public posicaoProduto: number = -1;
  public possuiFoto: boolean;
  public produtoFotos: ProdutoFoto[] = [];
  public produtos: Produto[] = [];
  public reiniciando: boolean;
  public retornaConsulta: boolean = false;
  public saida: boolean = false;
  public selecionados: Produto[] = [];
  public usaAbaSelecionada: boolean = false;
  public util: Util = new Util();
  public utilizouEspaco: boolean = false;
  public mostraEstoque: boolean = false;
  public mostraObservacao: boolean = false;
  public mostraTabelaPreco: boolean = false;
  public mostraFoto: boolean = true;
  public utilizaTabelaPreco: boolean = false;
  public pesquisandoCodigoBarra: boolean = false;
  public quantidadeDesejadaCodigoBarra: number = 0;
  public pesquisaVinculadaTela: boolean = false;
  public ehF8: boolean = false;
  public permissaoProdutoValor: Permissao;
  public usaEntregaOpcional: boolean = false;
  public usaLimitarEstoquePorLoja: boolean = this.utilSessao.getEmpresa().limitarEstoque == 3;

  ngOnInit(): void {
    this.ehRetornaConsulta();
    this.criarSiglaNomenclatura();
    this.idLoja = this.util.coalesce(this.utilSessao.getIdentificacao('idLoja')?.conteudo, this.utilSessao.getLojas()[0].id);
    this.loja = this.utilSessao.getLojas().find((loja) => loja.id == this.idLoja);
    this.idCliente = this.utilSessao.getIdentificacao('idCliente')?.conteudo;
    this.idColaborador = this.utilSessao.getIdentificacao('idColaborador')?.conteudo;
    this.orcamento = this.utilSessao.getIdentificacao('orcamento')?.conteudo;
    this.naturezaOperacaoUsaCustoReposicao = this.utilSessao.getIdentificacao('naturezaOperacaoUsaCustoReposicao')?.conteudo;
    if (this.retornaConsulta) {
      this.acrescimoPercentual = this.utilSessao.getIdentificacao('acrescimoPercentual')?.conteudo;
      this.entrada = this.utilSessao.getIdentificacao('entrada')?.conteudo;
      this.entradaServico = this.utilSessao.getIdentificacao('entradaServico')?.conteudo;
      this.interna = this.utilSessao.getIdentificacao('interna')?.conteudo;
      this.saida = this.utilSessao.getIdentificacao('saida')?.conteudo;
      this.clienteEspecial = this.utilSessao.getIdentificacao('clienteEspecial')?.conteudo;
    }
    this.utilNotificacao.adicionaProdutos.subscribe(() => {
      if (this.selecionados.length > 0) {
        this.adicionarProduto();
      }
    });
    this.modalEmt = this.modalNotificacao.modalEmt.subscribe((identificacao) => {
      if (identificacao.nome == this.bibClasse.tabelaPrecoConfiguracao) {
        let tabelaPrecoConfiguracao: TabelaPrecoConfiguracao = this.plainToClass(TabelaPrecoConfiguracao, identificacao.conteudo);
        for (let indice: number = 0; indice < this.produtos.length; indice++) {
          if (this.produtos[indice] && this.produtos[indice].idProduto == this.produtoModal.idProduto && this.produtos[indice].unidade == this.produtoModal.unidade) {
            this.produtos[indice].precoVenda = tabelaPrecoConfiguracao.valor;
            this.produtos[indice].idTabelaPrecoConfiguracao = tabelaPrecoConfiguracao.id;
            this.produtos[indice].tabelaPrecoConfiguracao = tabelaPrecoConfiguracao;
          }
        }
        this.produtoModal = null;
      }
    });
    if (this.entrada == false && this.saida == false) {
      this.ehF8 = true;
    }
    const ID_MENU_PRODUTO_VALOR: number = 73;
    this.permissaoProdutoValor = this.utilSessao.getPermissoes().find((permissao) => permissao.idMenu == ID_MENU_PRODUTO_VALOR);
    this.usaEntregaOpcional = this.utilSessao.getEmpresa().separarEntregarOpcional == 'S';
  }

  criarSiglaNomenclatura(): void {
    this.custoGerencial = this.bibNomenclatura.escrever(this.bibNomenclatura.custoGerencial);
    let nomes: string[] = this.custoGerencial.split(' ');
    if (nomes.length == 1) {
      this.nomenclaturaCustoGerencial = nomes[0].substr(0, 3);
    } else {
      nomes.forEach((nome) => {
        this.nomenclaturaCustoGerencial += nome.substr(0, 1);
      });
    }
  }

  ehRetornaConsulta(): void {
    if (this.utilSessao.getIdentificacao('retornaConsulta')) {
      this.retornaConsulta = this.utilSessao.getIdentificacao('retornaConsulta')?.conteudo == true;
    }
  }

  @HostListener('window:keyup', ['$event'])
  keyboardInput(event: KeyboardEvent) {
    switch (event.keyCode) {
      case bibAtalho.setaCima:
        if (!this.blocoQuantidadeAberto) {
          event.preventDefault();
          this.marcarLista(-1);
        }
        break;

      case bibAtalho.setaBaixo:
        if (!this.blocoQuantidadeAberto) {
          event.preventDefault();
          this.marcarLista(1);
        }
        break;

      case bibAtalho.espaco:
        event.preventDefault();
        if (this.produtos.length > 0 && this.posicaoProduto >= 0 && this.retornaConsulta) {
          this.utilizouEspaco = true;
          this.ehSetSelecionado(this.posicaoProduto);
        }
        event.stopImmediatePropagation();
        break;

      case bibAtalho.enter:
        event.preventDefault();
        if (this.selecionados.length > 0 && !this.pesquisando && !this.blocoQuantidadeAberto) {
          if (this.produtos.length > 0 && this.posicaoProduto >= 0) {
            this.adicionarProduto();
          }
        } else if (this.blocoQuantidadeAberto) {
          this.blocoQuantidadeAberto = false;
        }
        break;

      case bibAtalho.cancelar:
        event.preventDefault();
        if (!this.blocoQuantidadeAberto) {
          this.focoBusca = Math.random();
          this.filtro.valor = '';
        } else {
          this.focoQuantidadeProduto = Math.random();
        }
        event.stopImmediatePropagation();
        break;

      case bibAtalho.fechar:
        this.modalNotificacao.modalEmt.emit(new Identificacao());
        break;
    }
  }

  adicionarProduto(): void {
    let produtosSelecionados: Produto[] = this.selecionados;
    this.ehSetProdutoIdLocal(produtosSelecionados);
    this.modalNotificacao.modalEmt.emit(new Identificacao(this.bibClasse.produto, produtosSelecionados));
    this.selecionados = [];
  }

  ehSetProdutoIdLocal(produtos: Produto[]): void {
    produtos.forEach((produto) => {
      produto.quantidade = this.util.arredondar(produto.quantidade, this.empresaRegraCasasDecimais);
      let estoqueComLocais: Estoque[] = this.estoques.filter((estoque) => estoque.idLoja == this.idLoja && estoque.idProduto == produto.id && estoque.idLocal != null);
      let estoqueSemLocais: Estoque[] = this.estoques.filter((estoque) => estoque.idLoja == this.idLoja && estoque.idProduto == produto.id && estoque.idLocal == null);
      if (estoqueComLocais.length > 0 && estoqueSemLocais.length == 0) {
        let idLocais: number[] = estoqueComLocais.map((estoque) => estoque.idLocal);
        idLocais = idLocais.filter((idLocal, indice) => idLocais.indexOf(idLocal) === indice);
        if (idLocais.length == 1) {
          produto.idLocal = idLocais[0];
        }
      }
    });
  }

  listarProduto(): void {
    if (this.ehValidoFiltro()) {
      this.listarLojas();
      this.listarTabelasPreco();
      const criterios: Criterio[] = [];
      this.ehPesquisaCodigoBarra();
      this.alterarValorFiltro(this.filtro.valor);
      criterios.push(this.filtro);
      criterios.push(new Criterio('ATIVO', 'S'));
      if (this.interna) criterios.push(new Criterio('INTERNA', 'S'));
      if (this.saida) criterios.push(new Criterio('SAIDA', 'S'));
      if (this.entrada) criterios.push(new Criterio('ENTRADA', 'S'));
      if (this.entradaServico) criterios.push(new Criterio('TIPO', 'S'));
      if ((this.interna) || (this.saida) || (this.entrada) || (this.entradaServico)) {
        this.pesquisaVinculadaTela = true;
      }
      if ((this.saida) || (!this.interna && !this.saida && !this.entrada)) {
        criterios.push(new Criterio('USO_CONSUMO', 'N'));
      }
      if (this.utilSessao.getEmpresa().idProdutoPersonalizado != null) {
        if (!this.orcamento) criterios.push(new Criterio('IGNORAR_PERSONALIZADO', 'S'));
      }
      if (this.apresentaFiltros) {
        criterios.push(this.filtroFabricante);
        criterios.push(this.filtroGrupo);
        criterios.push(this.filtroSaldoDe);
        criterios.push(this.filtroSaldoAte);
        criterios.forEach(criterio => {
          if (criterio.nome === "NOME") {
            Object.keys(criterio).forEach(key => {
              if (criterio[key] === null || criterio[key] === "") {
                delete criterio[key];
              }
            });
          }
        });
      }

      criterios.push(new Criterio('ID_LOJA', this.idLoja));
      this.comunicacaoService.listar(new Transporte(criterios), this.bibServico.produto).subscribe((res) => {
        this.limparLista();
        this.produtos = this.plainToClass(Produto, res) as any;
        if (this.produtos.length > 0) {
          this.produtos.forEach((produto) => {
            const lojaPrecoVenda: Loja = this.utilSessao.getLojas().find((loja) => loja.id == this.idLoja);
            const descontoMinimo: number = lojaPrecoVenda && lojaPrecoVenda.regraPrecoVenda && lojaPrecoVenda.regraPrecoVenda > 0 ? produto.precoVenda * (lojaPrecoVenda.regraPrecoVenda / 100) : 0;
            produto.precoVendaMinimo = produto.precoVenda - descontoMinimo;
          });
          this.buscarConfiguracaoClienteEspecial();
          this.ordenarProduto();
          this.configurarInformacaoProduto();
          setTimeout((_) => this.marcarLista(1));
          if (this.retornaConsulta && this.produtos.length == 1 && this.pesquisandoCodigoBarra) {
            this.ehSetSelecionado(0);
          }
        }
      });
    }
  }

  buscarConfiguracaoClienteEspecial(): void {
    if (this.clienteEspecial) {
      this.calcularValorClienteEspecial(this.clienteEspecial);
    }
  }

  calcularValorClienteEspecial(clienteEspecial): void {
    if (this.naturezaOperacaoUsaCustoReposicao != '2' && this.naturezaOperacaoUsaCustoReposicao != '3') {
      this.produtos.forEach((produto) => {
        switch (clienteEspecial.precificacao) {
          case 1:
            produto.custoReposicao = produto.custoReposicao + (produto.custoReposicao / 100) * clienteEspecial.percentual;
            produto.precoVenda = produto.custoReposicao;
            break;
          case 2:
            produto.custoGerencial = produto.custoGerencial + (produto.custoGerencial / 100) * clienteEspecial.percentual;
            produto.precoVenda = produto.custoGerencial;
            break;
          case 3:
            produto.custoNfe = produto.custoNfe + (produto.custoReposicao / 100) * clienteEspecial.percentual;
            produto.precoVenda = produto.custoNfe;
            break;
          case 4:
            produto.precoVenda = produto.precoVenda - (produto.precoVenda / 100) * clienteEspecial.percentual;
            break;
        }
      });
    }
  }

  ehValidoFiltro(): boolean {
    if (this.apresentaFiltros == false) {
      if (this.filtro.valor) {
        return true;
      }
    } else {
      const quantidadeCaracter: number = this.contarLetras();
      if (quantidadeCaracter < 4 && !this.filtroFabricante.valor && !this.filtroGrupo.valor && !this.filtroSaldoAte.valor && !this.filtroSaldoDe.valor) {
        this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.preenchaNoMinimo3caractresOuAlgumaOutraOpcaoDoFiltro));
        return false;
      }
      return true;
    }
    this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.verifiqueObrigatorio));
    return false;
  }

  contarLetras(): number {
    let quantidadeNumeroLetras: number = 0;
    if (this.filtro.valor.length > 0) {
      for (let index = 0; index < this.filtro.valor.length; index++) {
        if (this.filtro.valor[index].match('[A-Za-z0-9]')) {
          quantidadeNumeroLetras++;
        }
      }
    }
    return quantidadeNumeroLetras;
  }

  ehPesquisaCodigoBarra(): void {
    this.quantidadeDesejadaCodigoBarra = 0;
    if (this.filtro.valor.indexOf('*') > 0) {
      const posicaoAsterisco: number = this.filtro.valor.indexOf('*');
      if (Number(Number(this.filtro.valor.substring(0, posicaoAsterisco).replace(',', '.')))) {
        this.quantidadeDesejadaCodigoBarra = Number(this.filtro.valor.substring(0, posicaoAsterisco).replace(',', '.'));
        let codigoBarra: string = this.filtro.valor.substring(posicaoAsterisco + 1, 100);
        if (Number(codigoBarra) && codigoBarra.length == 13) {
          this.filtro.valor = codigoBarra;
        }
      }
    }
  }

  alterarValorFiltro(valor: string): void {
    if (this.filtro.valor != valor) {
      this.pesquisando = true;
    }
    this.filtro.valor = typeof valor == 'object' ? '' : valor;
    if (Number(this.filtro.valor)) {
      if (this.filtro.valor.length == 13) {
        this.filtro.nome = 'CODIGO_BARRA';
      } else {
        if (this.filtro.valor.length != 13 && this.filtro.nome != 'REFERENCIA') {
          this.filtro.nome = 'NUMERO';
        }
      }
    } else {
      if (!Number(this.filtro.valor) && this.filtro.nome != 'REFERENCIA') {
        this.filtro.nome = 'NOME';
      }
    }

    this.pesquisandoCodigoBarra = false;
    if (this.filtro.nome == 'CODIGO_BARRA' && this.filtro.valor.length == 13) {
      this.pesquisandoCodigoBarra = true;
    }
  }

  limparLista(): void {
    this.posicaoProduto = -1;
    this.produtos = [];
    this.estoques = [];
    this.estoqueProdutoMinimoMaximos = [];
  }

  ordenarProduto(): void {
    this.produtos.sort((a, b) => {
      if (a['ordemPolegada'] > b['ordemPolegada']) return 1;
      if (a['ordemPolegada'] < b['ordemPolegada']) return -1;
      if (a['nome'] > b['nome']) return 1;
      if (a['nome'] < b['nome']) return -1;
    });
  }

  configurarInformacaoProduto(): void {
    for (const produto of this.produtos) {
      produto.id = produto.idProduto;
      if (!this.entrada && this.acrescimoPercentual > 0) {
        produto.precoVenda = produto.precoVenda + (produto.precoVenda * this.acrescimoPercentual) / 100;
      }
      this.configurarValorProdutoComBaseNoFator(produto);
      produto.precoVendaOriginal = produto.precoVenda;
    }
  }

  configurarValorProdutoComBaseNoFator(produto: Produto): void {
    if (produto.fator) {
      if (produto.operacao == 'M') {
        produto.precoVenda = this.util.arredondar(produto.precoVenda * produto.fator, this.empresaRegraCasasDecimais);
        produto.custoReposicao = this.util.arredondar(produto.custoReposicao * produto.fator, this.empresaRegraCasasDecimais);
        produto.custoGerencial = this.util.arredondar(produto.custoGerencial * produto.fator, this.empresaRegraCasasDecimais);
      }
      if (produto.operacao == 'D') {
        produto.precoVenda = this.util.arredondar(produto.precoVenda / produto.fator, this.empresaRegraCasasDecimais);
        produto.custoReposicao = this.util.arredondar(produto.custoReposicao / produto.fator, this.empresaRegraCasasDecimais);
        produto.custoGerencial = this.util.arredondar(produto.custoGerencial / produto.fator, this.empresaRegraCasasDecimais);
      }
    }
  }

  marcarLista(incremento: number): void {
    if (this.produtos.length > 0) {
      this.posicaoProduto = this.util.marcarLista(this.posicaoProduto, incremento, this.produtos);
      if (this.posicaoProduto >= this.posicao) {
        this.rolarBarraRolagem();
      } else {
        this.posicaoProduto = this.posicaoProduto + incremento * -1;
      }
      if (this.posicaoProduto >= 0) {
        Promise.resolve(null).then(() => this.corpoTabelaProduto.nativeElement.focus());
      }
      if (this.abaMaisInformacoes) {
        this.listarEstoque(this.produtos[this.posicaoProduto].id, this.posicaoProduto);
      }
    }
  }
  listarLojas(): void {
    let criterios: Criterio[] = [];
    criterios.push(new Criterio('ATIVO', 'S'));
    if (this.usaLimitarEstoquePorLoja) {
      criterios.push(new Criterio('LIMITAR_LOJA_ESTOQUE', 'S'));
    }
    this.comunicacaoService.listar(new Transporte(criterios), this.bibServico.loja).subscribe((res) => {
      this.lojas = this.plainToClass(Loja, res) as any;
      this.lojas.sort((a, b) => (a['abreviacao'] == b['abreviacao'] ? 0 : a['abreviacao'] > b['abreviacao'] ? 1 : -1));
    });
  }

  listarTabelasPreco(): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ATIVO', 'S')), this.bibServico.tabelaPreco).subscribe((res) => {
      this.utilizaTabelaPreco = this.plainToClass(TabelaPreco, res[0]) ? true : false;
    });
  }

  ehSetSelecionado(posicao: number): void {
    const produto: Produto = this.usaAbaSelecionada ? this.selecionados[posicao] : this.produtos[posicao];
    if (produto) {
      produto.selecionado = produto.selecionado == 'N' ? 'S' : 'N';
      if (!this.usaAbaSelecionada && produto.selecionado == 'S' && !(this.produtos.length == 1 && this.pesquisandoCodigoBarra)) {
        this.blocoQuantidadeAberto = true;
        setTimeout((_) => {
          this.focoQuantidadeProduto = Math.random();
        });
      }
      this.produtos[posicao].quantidade = this.quantidadeDesejadaCodigoBarra > 0 ? this.quantidadeDesejadaCodigoBarra : this.produtos[posicao].quantidade;
      this.setProdutoSelecionado(produto);
    }
  }

  setProdutoSelecionado(produto: Produto): void {
    if (produto.selecionado == 'S') {
      if (this.selecionados.find((selecionado) => selecionado.id == produto.id) == undefined) {
        this.selecionados.push(produto);
      }
    } else {
      for (let index = 0; index < this.selecionados.length; index++) {
        if (this.selecionados[index].id == produto.id) {
          this.selecionados.splice(index, 1);
          break;
        }
      }
    }
    if (this.retornaConsulta && this.produtos.length == 1 && this.pesquisandoCodigoBarra) {
      this.modalNotificacao.modalEmt.emit(new Identificacao('ProdutoCodigoBarra', this.produtos));
    }
  }

  rolarBarraRolagem(): void {
    const posicaoCalculada: number = this.posicaoProduto - this.posicao;
    this.corpoTabelaProduto.nativeElement.scrollTop = posicaoCalculada * 21.39;
  }

  setAbaSelecionada(usaAbaSelecionada: boolean): void {
    this.usaAbaSelecionada = usaAbaSelecionada;
    this.abaMaisInformacoes = false;
    this.posicao = 0;
    this.posicaoProduto = -1;
    if (this.usaAbaSelecionada) {
      setTimeout((_) => {
        this.focoQuantidadeProduto = Math.random();
      });
    }
  }

  carregarFotos(idProduto: number): void {
    if (this.abaFotoAtiva) {
      this.comunicacaoService.listar(new Transporte(new Criterio('ID_PRODUTO', idProduto)), this.bibServico.produtoFoto).subscribe((res) => {
        this.produtoFotos = this.plainToClass(ProdutoFoto, res) as any;
      });
    }
  }

  abrirModalImagem(imagemBase64: string): void {
    this.utilSessao.setIdentificacao(new Identificacao('imagemBase64', imagemBase64));
    this.componente = ImagemComponent;
  }

  abrirModalTabelaPreco(produto: Produto): void {
    this.produtoModal = produto;
    this.utilSessao.setIdentificacao(new Identificacao('valor', produto.precoVendaOriginal));
    this.utilSessao.setIdentificacao(new Identificacao('idCliente', null));
    this.utilSessao.setIdentificacao(new Identificacao('idColaborador', null));
    this.utilSessao.setIdentificacao(new Identificacao('idProduto', produto.id));
    this.utilSessao.setIdentificacao(new Identificacao('produto', produto.nome));
    this.utilSessao.setIdentificacao(new Identificacao('retornaConsulta', false));
    this.componente = TabelaPrecoModalComponent;
  }

  fecharCard(): void {
    this.blocoQuantidadeAberto = false;
  }

  setApresentaFiltro(): void {
    this.apresentaFiltros = this.apresentaFiltros == false ? true : false;
    this.abaMaisInformacoes = false;
    if (this.apresentaFiltros == false) {
      this.limparFiltros();
    }
  }

  selecionarTodos(): void {
    this.produtos.forEach((produto, i) => {
      this.ehSetSelecionado(i);
      produto.quantidade = i + 1;
    });
    this.blocoQuantidadeAberto = false;
  }

  limparFiltros(): void {
    this.filtroFabricante.valor = null;
    this.filtroGrupo.valor = null;
    this.nomeGrupo = null;
    this.nomeFabricante = null;
    this.filtroSaldoAte.valor = null;
    this.filtroSaldoDe.valor = null;
  }

  setFiltroFabricante(fabricante: Fabricante): void {
    this.nomeFabricante = fabricante.nome;
    this.filtroFabricante.valor = fabricante.id;
  }

  setFiltroGrupo(grupo: Grupo): void {
    this.nomeGrupo = grupo.nome.trim();
    this.filtroGrupo.valor = grupo.id;
  }

  setFiltroSaldoAte(valor: number): void {
    this.filtroSaldoAte.valor = valor;
    if (valor == 0) {
      this.filtroSaldoAte.valor = null;
    }
  }

  mostrarObservacao(idProduto: number, indiceProduto: number) {
    this.abaMaisInformacoes = true;
    this.listarEstoque(idProduto, indiceProduto);
    this.ativarAbaObservacao();
  }

  mostrarFoto(idProduto: number, indiceProduto: number) {
    this.abaMaisInformacoes = true;
    this.listarEstoque(idProduto, indiceProduto);
    this.ativarAbaFotos(idProduto);
  }

  alterarTamanhoInformacoes(idProduto: number, indiceProduto: number): void {
    this.abaMaisInformacoes = !this.abaMaisInformacoes;
    this.listarEstoque(idProduto, indiceProduto);
  }

  ativarAbaEstoque(): void {
    this.abaFotoAtiva = false;
    this.mostraEstoque = true;
    this.mostraObservacao = false;
    this.mostraFoto = false;
  }

  ativarAbaObservacao(): void {
    this.abaFotoAtiva = false;
    this.mostraEstoque = false;
    this.mostraObservacao = true;
    this.mostraFoto = false;
  }

  ativarAbaFotos(id: number): void {
    this.mostraEstoque = false;
    this.mostraObservacao = false;
    this.mostraFoto = true;
    this.abaFotoAtiva = true;
    this.carregarFotos(id);
  }

  ngOnDestroy(): void {
    if (this.modalEmt != null) {
      this.modalEmt.unsubscribe();
    }
  }

  listarEstoque(idProduto: number, indiceProduto: number): void {
    if (this.abaMaisInformacoes) {
      this.comunicacaoService.listar(new Transporte(new Criterio('ID_PRODUTO', idProduto)), this.bibServico.estoque).subscribe((res) => {
        this.estoques = this.plainToClass(Estoque, res) as any;
        this.listarProdutoEstoqueMaximoMinino(idProduto, indiceProduto);
      });
    } else {
      this.posicaoProduto = indiceProduto;
    }
    if (this.mostraObservacao) {
      if (!this.produtos[indiceProduto].observacao) {
        this.mostraObservacao = false;
        this.mostraEstoque = true;
      }
    } else {
      if (this.mostraFoto) {
        if (this.produtos[indiceProduto].foto != 'S') {
          this.mostraFoto = false;
          this.mostraEstoque = true;
        }
      }
    }
  }

  listarProdutoEstoqueMaximoMinino(idProduto: number, indiceProduto: number): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ID_PRODUTO', idProduto)), this.bibServico.produtoEstoqueMinimoMaximo).subscribe((res) => {
      this.produtoEstoqueMininoMaximos = this.plainToClass(ProdutoEstoqueMinimoMaximo, res) as any;
      this.montarEstoqueLoja(this.produtos[indiceProduto], indiceProduto);
    });
  }

  montarEstoqueLoja(produto: Produto, posicao: number): void {
    this.posicaoProduto = posicao;
    this.estoqueProdutoMinimoMaximos = this.retornarEstoqueProdutoMinimoMaximo(produto, this.lojas, this.estoques, this.produtoEstoqueMininoMaximos);
    this.possuiFoto = this.produtos[posicao].foto == 'S';
    this.carregarFotos(this.produtos[posicao].id);
  }

  retornarEstoqueProdutoMinimoMaximo(produto: Produto, lojas: Loja[], estoques: Estoque[], produtoEstoqueMininoMaximos: ProdutoEstoqueMinimoMaximo[]): EstoqueProdutoMinimoMaximo[] {
    let estoqueProdutoMinimoMaximos: EstoqueProdutoMinimoMaximo[] = [];
    for (let loja of lojas) {
      let estoqueProdutoMinimoMaximoNovo: EstoqueProdutoMinimoMaximo = new EstoqueProdutoMinimoMaximo();
      estoqueProdutoMinimoMaximoNovo.idLoja = loja.id;
      estoqueProdutoMinimoMaximoNovo.abreviacao = loja.abreviacao;
      this.popularEstoqueProduto(estoqueProdutoMinimoMaximoNovo, produto, loja, estoques);
      this.popularEstoqueProdutoMinimoMaximo(estoqueProdutoMinimoMaximoNovo, produto, loja, produtoEstoqueMininoMaximos);
      estoqueProdutoMinimoMaximos.push(estoqueProdutoMinimoMaximoNovo);
    }
    return estoqueProdutoMinimoMaximos.sort((a, b) => (a['abreviacao'] === b['abreviacao'] ? 0 : a['abreviacao'] > b['abreviacao'] ? 1 : -1));
  }

  popularEstoqueProduto(estoqueProdutoMinimoMaximo: EstoqueProdutoMinimoMaximo, produto: Produto, loja: Loja, estoques: Estoque[]): void {
    let estoque: Estoque = new Estoque();
    let estoqueProduto: Estoque[] = estoques.filter((estoqueBusca) => estoqueBusca.idLoja == loja.id && estoqueBusca.idProduto == produto.id);
    estoqueProduto.forEach((estoqueBusca) => {
      estoque.quantidadeReservada += this.getQuantidadeComBaseProdutoFator(produto, estoqueBusca.quantidadeReservada);
      estoque.quantidadeCarteira += this.getQuantidadeComBaseProdutoFator(produto, estoqueBusca.quantidadeCarteira);
      estoque.quantidadeDisponivel += this.getQuantidadeComBaseProdutoFator(produto, estoqueBusca.quantidadeDisponivel);
      estoque.quantidadeFisica += this.getQuantidadeComBaseProdutoFator(produto, estoqueBusca.quantidadeFisica);
      estoque.quantidadeComprada += this.getQuantidadeComBaseProdutoFator(produto, estoqueBusca.quantidadeComprada);
      estoque.quantidadeNfe += this.getQuantidadeComBaseProdutoFator(produto, estoqueBusca.quantidadeNfe);
    });
    if (estoque) {
      estoqueProdutoMinimoMaximo.quantidadeReservada = estoque.quantidadeReservada;
      estoqueProdutoMinimoMaximo.quantidadeCarteira = estoque.quantidadeCarteira;
      estoqueProdutoMinimoMaximo.quantidadeDisponivel = estoque.quantidadeDisponivel;
      estoqueProdutoMinimoMaximo.quantidadeFisica = estoque.quantidadeFisica;
      estoqueProdutoMinimoMaximo.quantidadeComprada = estoque.quantidadeComprada;
      estoqueProdutoMinimoMaximo.quantidadeNfe = estoque.quantidadeNfe;
    }
  }

  popularEstoqueProdutoMinimoMaximo(estoqueProdutoMinimoMaximo: EstoqueProdutoMinimoMaximo, produto: Produto, loja: Loja, produtoEstoqueMininoMaximos: ProdutoEstoqueMinimoMaximo[]): void {
    for (let produtoEstoqueMinimoMaximo of produtoEstoqueMininoMaximos) {
      if (produtoEstoqueMinimoMaximo.idLoja == loja.id && produtoEstoqueMinimoMaximo.idProduto == produto.id) {
        estoqueProdutoMinimoMaximo.quantidadeMinima = this.getQuantidadeComBaseProdutoFator(produto, produtoEstoqueMinimoMaximo.quantidadeMinima);
        estoqueProdutoMinimoMaximo.quantidadeMaxima = this.getQuantidadeComBaseProdutoFator(produto, produtoEstoqueMinimoMaximo.quantidadeMaxima);
      }
    }
  }

  getQuantidadeComBaseProdutoFator(produto: Produto, valor: number): number {
    if (produto.fator) {
      if (produto.operacao == 'M') {
        return valor / produto.fator;
      }
      if (produto.operacao == 'D') {
        return valor * produto.fator;
      }
    }
    return valor;
  }
}
