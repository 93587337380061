<layout [subTitulo]="categoria" (filtroEmt)="listar($event)" [filtros]="filtros">
<registro *ngIf="quantidadeRegistro" [numeroRegistro]="quantidadeRegistro"></registro>
<table class="table table-responsive-sm">
    <thead>
      <tr>
        <th class="l-5 clique" (click)="ordenar('abreviacao')">{{ bibDialogo.loja }}</th>
        <th class="l-10 text-right clique" (click)="ordenar('numero')">{{ bibDialogo.numero }}</th>
        <th class="l-10 clique" (click)="ordenar('data')">{{ bibDialogo.dataNegociacao }}</th>
        <th class="l-30 clique" (click)="ordenar('parceiro')">{{ bibDialogo.cliente }}</th>
        <th class="l-30 clique" (click)="ordenar('colaborador')">{{ bibDialogo.vendedor }}</th>
        <th class="l-5 clique" (click)="ordenar('permiteEntrega')"></th>
        <th class="l-5"></th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let movimentacao of movimentacoes; let i = index">
        <tr [ngClass]="{ tachado: movimentacao.ativo != 'S' }">
          <td>{{ movimentacao.abreviacao }}</td>
          <td class="text-right">{{ movimentacao.numero }}</td>
          <td>{{ movimentacao.data | data: 'dd/MM/yy' }}</td>
          <td class="limitar">
            <a href="#" (click)="irEntrega(movimentacao, i, movimentacoes)">{{ movimentacao.parceiro }}</a>
          </td>
          <td class="limitar">{{ movimentacao.colaborador }}</td>
          <td><icone [icone]="bibIcone.circulo" [cor]="movimentacao.financeiro == 'N' || movimentacao.permitirEntregaDireta == 'S' || (movimentacao.permiteEntrega == 'S' && movimentacao.permitirEntregaDireta != 'S') ? bibPropriedade.icone.verde : movimentacao.negociacao?.permitirEntregaDireta == 'A' ? bibPropriedade.icone.laranja : bibPropriedade.icone.vermelho" [mensagem]="movimentacao.financeiro == 'N' || movimentacao.permitirEntregaDireta == 'S' || (movimentacao.permiteEntrega == 'S' && movimentacao.permitirEntregaDireta != 'S') ? bibDialogo.aguardandoEntrega : movimentacao.negociacao?.permitirEntregaDireta == 'A' ? bibDialogo.aguardandoAprovacao : bibDialogo.aguardandoPagamento"></icone></td>
          <td class="row justify-content-end">
            <btnAdicional [id]="'entregar' + i" [icone]="bibIcone.entrega" [ajuda]="bibDialogo.realizarEntrega" (btnAdicional)="irEntrega(movimentacao, i, movimentacoes)"></btnAdicional>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</layout>
