<layout [titulo]="movimentacaoProduto.produto">
  <div *ngIf="!entradaServico" class="row m-0 p-0">
    <div class="col-sm-8 row align-items-center">
      <atencao *ngIf="!parceiroEndereco && !parceiroDestino" [atencao]="nomeParceiro + ' ' + bibDialogo.clienteSemEnderecoPrincipal" [tipo]="bibPropriedade.atencao.cuidado"></atencao>
      <atencao *ngIf="parceiroEndereco || parceiroDestino" [atencao]="util.substituir((bibDialogo.estadoDefinidoNoEnderecoDoCliente + nomeParceiro.toLowerCase() + ' - ' + bibDialogo.inscricao + ': ' + (parceiroInscricao ? parceiroInscricao : bibDialogo.naoDefinida)), [parceiroEndereco ? parceiroEndereco.estado : parceiroDestino ? parceiroDestino.estado : ''])" [tipo]="bibPropriedade.atencao.sucesso"></atencao>
      <atencao *ngIf="movimentacaoProduto.valorTotal <= 0" [atencao]="bibDialogo.atencao + ' ' + bibDialogo.valorTotalItem + ' ' + bibDialogo.zerado.toLowerCase()" [tipo]="bibPropriedade.atencao.alerta"></atencao>
      <atencao *ngIf="movimentacaoProduto.getIcmsBase() == 0 && movimentacaoProduto.getIcmsAliquota() == 0 && movimentacaoProduto.destacarSemLojaEstado" [atencao]="bibDialogo.atencao + ' ' + util.substituir(bibDialogo.baseNaoCalculada, [parceiroEndereco ? parceiroEndereco.estado : parceiroDestino ? parceiroDestino.estado : ''])" [tipo]="bibPropriedade.atencao.alerta"></atencao> 
    </div>
    <div *ngIf="movimentacaoProduto.importacaoXml == 'N'" class="col-sm justify-content-end">
      <botao *ngIf="completo" class="text-right" [compacto]="compacto" [legenda]="bibDialogo.listarInformacoesFiscais" (botaoEmt)="listarImpostos()" [desabilitado]="bloqueado" [focoId]="focoId"></botao>
    </div>
  </div>
  <div class="row">
    <div *ngIf="completo" [ngClass]="compacto ? 'col-sm-12' : 'col-sm'">
      <titulo [titulo]="bibDialogo.cfop"></titulo>
      <lista [id]="'cfop'" [rotulo]="bibDialogo.cfop" [campo]="movimentacaoProduto.idCfopEmpresa" (alteracao)="setCfopEmpresa($event)" [lista]="cfopsEmpresa" [obrigatorio]="obrigatorio" [foco]="true" [desabilitado]="bloqueado && bloqueiaAlteracao" [icone]="bibIcone.atualizar" (btnAdicional)="listarCfopEmpresa()" [idMenu]="permissaoCfopEmpresa && permissaoCfopEmpresa.inserir == 'S' ? menuCfopEmpresa.idMenu : null"></lista>
      <div class="row">
        <div [ngClass]="compacto ? 'col-sm-2' : 'ml-1'"></div>
        <atencao *ngIf="this.movimentacaoProduto.numeroProdutoNfe" class="limitar" [ngClass]="compacto ? '' : 'tamanho-maximo-425'" [atencao]="movimentacaoProduto.cfopSaidaNfe"></atencao>
      </div>
    </div>
    <div *ngIf="!entradaServico" [ngClass]="compacto ? 'col-sm-8' : 'col-sm-4'">
      <titulo [titulo]="bibDialogo.icmsSt"></titulo>
      <div class="row">
        <decimal *ngIf="completo" class="col-sm-6" [rotulo]="bibDialogo.base" [campo]="movimentacaoProduto.getIcmsStBase()" (alteracao)="movimentacaoProduto.setIcmsStBase($event)" [obrigatorio]="obrigatorio" [desabilitado]="bloqueado" [atencao]="movimentacaoProduto.valorTotal == 0 ? bibDialogo.valorTotal + ' ' + bibDialogo.zerado.toLowerCase() : null"> </decimal>
        <decimal class="col-sm-6" [rotulo]="bibDialogo.valor" [campo]="movimentacaoProduto.getIcmsStValor()" (alteracao)="movimentacaoProduto.setIcmsStValor($event)" [obrigatorio]="obrigatorio" [desabilitado]="bloqueado"> </decimal>
      </div>
    </div>
    <div *ngIf="!entradaServico" [ngClass]="compacto ? 'col-sm-4' : 'col-sm-2'">
      <titulo class="justify-content-end" [titulo]="bibDialogo.icmsDesonerado"></titulo>
      <decimal [rotulo]="bibDialogo.valor" [campo]="movimentacaoProduto.getIcmsDesonerado()" (alteracao)="movimentacaoProduto.setIcmsDesonerado($event)" [obrigatorio]="obrigatorio" [desabilitado]="bloqueado"> </decimal>
    </div>
  </div>
  <titulo *ngIf="completo" [titulo]="bibDialogo.icms"></titulo>
  <div *ngIf="completo" class="row">
    <lista [id]="'cstIcms'" [ngClass]="compacto ? 'col-sm-12' : 'col-sm-6'" [rotulo]="bibDialogo.cst" [campo]="movimentacaoProduto.idCstIcms" (alteracao)="setCstIcms($event)" [lista]="icmsCsts" [obrigatorio]="obrigatorio" [desabilitado]="bloqueado && bloqueiaAlteracao"></lista>
    <decimal [ngClass]="compacto ? 'col-sm-4' : 'col-sm-2'" [rotulo]="bibDialogo.base" [campo]="movimentacaoProduto.getIcmsBase()" (alteracao)="movimentacaoProduto.setIcmsBase($event)" [obrigatorio]="obrigatorio" [desabilitado]="bloqueado && bloqueiaAlteracao" [atencao]="movimentacaoProduto.valorTotal == 0 ? bibDialogo.valorTotal + ' ' + bibDialogo.zerado.toLowerCase() : null"> </decimal>
    <decimal [ngClass]="compacto ? 'col-sm-4' : 'col-sm-2'" [rotulo]="bibDialogo.aliquota" [campo]="movimentacaoProduto.getIcmsAliquota()" (alteracao)="movimentacaoProduto.setIcmsAliquota($event)" [obrigatorio]="obrigatorio" [desabilitado]="bloqueado && bloqueiaAlteracao"> </decimal>
    <decimal [ngClass]="compacto ? 'col-sm-4' : 'col-sm-2'" [rotulo]="bibDialogo.valor" [campo]="movimentacaoProduto.getIcmsValor()" (alteracao)="movimentacaoProduto.setIcmsValor($event)" [obrigatorio]="obrigatorio" [desabilitado]="bloqueado && bloqueiaAlteracao"> </decimal>
  </div>
  <div *ngIf="completo && mostraCodigoBeneficioFiscal" class="row">
    <texto [ngClass]="compacto ? 'col-sm-4' : 'col-sm-2'" [rotulo]="bibDialogo.codigoBeneficioFiscal" [campo]="movimentacaoProduto.getCodigoBeneficioFiscal()" (alteracao)="movimentacaoProduto.setCodigoBeneficioFiscal($event)" [ajuda]="bibDialogo.codigoBeneficioFiscalOitoCaracteres" [maximoCaracteres]="8" [desabilitado]="bloqueado && bloqueiaAlteracao"> </texto>
  </div>  
  <titulo *ngIf="completo" [titulo]="bibDialogo.cofins"></titulo>
  <div *ngIf="completo" class="row">
    <lista [id]="'cstCofins'" [ngClass]="compacto ? 'col-sm-12' : 'col-sm-6'" [rotulo]="bibDialogo.cst" [campo]="movimentacaoProduto.idCstCofins" (alteracao)="movimentacaoProduto.idCstCofins = $event.id" [lista]="pisCofinsCsts" [obrigatorio]="obrigatorio" [desabilitado]="bloqueado && bloqueiaAlteracao"></lista>
    <decimal [ngClass]="compacto ? 'col-sm-4' : 'col-sm-2'" [rotulo]="bibDialogo.base" [campo]="movimentacaoProduto.getCofinsBase()" (alteracao)="movimentacaoProduto.setCofinsBase($event)" [obrigatorio]="obrigatorio" [desabilitado]="bloqueado && bloqueiaAlteracao" [atencao]="movimentacaoProduto.valorTotal == 0 ? bibDialogo.valorTotal + ' ' + bibDialogo.zerado.toLowerCase() : null"> </decimal>
    <decimal [ngClass]="compacto ? 'col-sm-4' : 'col-sm-2'" [rotulo]="bibDialogo.aliquota" [campo]="movimentacaoProduto.getCofinsAliquota()" (alteracao)="movimentacaoProduto.setCofinsAliquota($event)" [obrigatorio]="obrigatorio" [desabilitado]="bloqueado && bloqueiaAlteracao"> </decimal>
    <decimal [ngClass]="compacto ? 'col-sm-4' : 'col-sm-2'" [rotulo]="bibDialogo.valor" [campo]="movimentacaoProduto.getCofinsValor()" (alteracao)="movimentacaoProduto.setCofinsValor($event)" [obrigatorio]="obrigatorio" [desabilitado]="bloqueado && bloqueiaAlteracao"> </decimal>
  </div>
  <titulo *ngIf="completo" [titulo]="bibDialogo.pis"></titulo>
  <div *ngIf="completo" class="row">
    <lista [id]="'cstPis'" [ngClass]="compacto ? 'col-sm-12' : 'col-sm-6'" [rotulo]="bibDialogo.cst" [campo]="movimentacaoProduto.idCstPis" (alteracao)="movimentacaoProduto.idCstPis = $event.id" [lista]="pisCofinsCsts" [obrigatorio]="obrigatorio" [desabilitado]="bloqueado && bloqueiaAlteracao"></lista>
    <decimal [ngClass]="compacto ? 'col-sm-4' : 'col-sm-2'" [rotulo]="bibDialogo.base" [campo]="movimentacaoProduto.getPisBase()" (alteracao)="movimentacaoProduto.setPisBase($event)" [obrigatorio]="obrigatorio" [desabilitado]="bloqueado && bloqueiaAlteracao" [atencao]="movimentacaoProduto.valorTotal == 0 ? bibDialogo.valorTotal + ' ' + bibDialogo.zerado.toLowerCase() : null"> </decimal>
    <decimal [ngClass]="compacto ? 'col-sm-4' : 'col-sm-2'" [rotulo]="bibDialogo.aliquota" [campo]="movimentacaoProduto.getPisAliquota()" (alteracao)="movimentacaoProduto.setPisAliquota($event)" [obrigatorio]="obrigatorio" [desabilitado]="bloqueado && bloqueiaAlteracao"> </decimal>
    <decimal [ngClass]="compacto ? 'col-sm-4' : 'col-sm-2'" [rotulo]="bibDialogo.valor" [campo]="movimentacaoProduto.getPisValor()" (alteracao)="movimentacaoProduto.setPisValor($event)" [obrigatorio]="obrigatorio" [desabilitado]="bloqueado && bloqueiaAlteracao"> </decimal>
  </div>
  <titulo *ngIf="!entradaServico" [titulo]="bibDialogo.ipi"></titulo>
  <div *ngIf="!entradaServico"class="row">
    <lista *ngIf="completo" [id]="'cstIpi'" [ngClass]="compacto ? 'col-sm-12' : 'col-sm-6'" [rotulo]="bibDialogo.cst" [campo]="movimentacaoProduto.idCstIpi" (alteracao)="movimentacaoProduto.idCstIpi = $event.id" [lista]="ipiCsts" [obrigatorio]="obrigatorio" [desabilitado]="bloqueado"></lista>
    <decimal *ngIf="completo" [ngClass]="compacto ? 'col-sm-4' : 'col-sm-2'" [rotulo]="bibDialogo.base" [campo]="movimentacaoProduto.getIpiBase()" (alteracao)="movimentacaoProduto.setIpiBase($event)" [obrigatorio]="obrigatorio" [desabilitado]="bloqueado" [atencao]="movimentacaoProduto.valorTotal == 0 ? bibDialogo.valorTotal + ' ' + bibDialogo.zerado.toLowerCase() : null"> </decimal>
    <decimal *ngIf="completo" [ngClass]="compacto ? 'col-sm-4' : 'col-sm-2'" [rotulo]="bibDialogo.aliquota" [campo]="movimentacaoProduto.getIpiAliquota()" (alteracao)="movimentacaoProduto.setIpiAliquota($event)" [obrigatorio]="obrigatorio" [desabilitado]="bloqueado"> </decimal>
    <decimal [ngClass]="compacto ? 'col-sm-4' : 'col-sm-2'" [rotulo]="bibDialogo.valor" [campo]="movimentacaoProduto.getIpiValor()" (alteracao)="movimentacaoProduto.setIpiValor($event)" [obrigatorio]="obrigatorio" [desabilitado]="bloqueado"> </decimal>
  </div>
  <div *ngIf="entradaServico" class="row">
    <div [ngClass]="compacto ? 'col-sm-4' : 'col-sm-4'">
      <titulo [titulo]="bibDialogo.inssRetido"></titulo>
      <decimal [rotulo]="bibDialogo.valor" [campo]="movimentacaoProduto.getInssRetidoValor()" (alteracao)="movimentacaoProduto.setInssRetidoValor($event)" [obrigatorio]="obrigatorio" [desabilitado]="bloqueado"> </decimal>
    </div>
    <div [ngClass]="compacto ? 'col-sm-4' : 'col-sm-4'">
      <titulo [titulo]="bibDialogo.irrfRetido"></titulo>
      <decimal [rotulo]="bibDialogo.valor" [campo]="movimentacaoProduto.getIrrfRetidoValor()" (alteracao)="movimentacaoProduto.setIrrfRetidoValor($event)" [obrigatorio]="obrigatorio" [desabilitado]="bloqueado"> </decimal>
    </div>
    <div [ngClass]="compacto ? 'col-sm-4' : 'col-sm-4'">
      <titulo [titulo]="bibDialogo.csllRetido"></titulo>
      <decimal [rotulo]="bibDialogo.valor" [campo]="movimentacaoProduto.getCsllRetidoValor()" (alteracao)="movimentacaoProduto.setCsllRetidoValor($event)" [obrigatorio]="obrigatorio" [desabilitado]="bloqueado"> </decimal>
    </div>
  </div>

  <div *ngIf="entradaServico" class="row">
    <div [ngClass]="compacto ? 'col-sm-4' : 'col-sm-4'">
      <titulo [titulo]="bibDialogo.pisRetido"></titulo>
      <decimal [rotulo]="bibDialogo.valor" [campo]="movimentacaoProduto.getPisRetidoValor()" (alteracao)="movimentacaoProduto.setPisRetidoValor($event)" [obrigatorio]="obrigatorio" [desabilitado]="bloqueado"> </decimal>
    </div>
    <div [ngClass]="compacto ? 'col-sm-4' : 'col-sm-4'">
      <titulo [titulo]="bibDialogo.cofinsRetido"></titulo>
      <decimal [rotulo]="bibDialogo.valor" [campo]="movimentacaoProduto.getCofinsRetidoValor()" (alteracao)="movimentacaoProduto.setCofinsRetidoValor($event)" [obrigatorio]="obrigatorio" [desabilitado]="bloqueado"> </decimal>
    </div>
    <div [ngClass]="compacto ? 'col-sm-4' : 'col-sm-4'">
      <titulo [titulo]="bibDialogo.deducoes"></titulo>
      <decimal [rotulo]="bibDialogo.valor" [campo]="movimentacaoProduto.getDeducoesValor()" (alteracao)="movimentacaoProduto.setDeducoesValor($event)" [obrigatorio]="obrigatorio" [desabilitado]="bloqueado"> </decimal>
    </div>
    <div [ngClass]="compacto ? 'col-sm-4' : 'col-sm-4'">
      <titulo [titulo]="bibDialogo.outrasRetencoes"></titulo>
      <decimal [rotulo]="bibDialogo.valor" [campo]="movimentacaoProduto.getOutrasRetencoesValor()" (alteracao)="movimentacaoProduto.setOutrasRetencoesValor($event)" [obrigatorio]="obrigatorio" [desabilitado]="bloqueado"> </decimal>
    </div>
  </div>  

  <titulo *ngIf="entradaServico" [titulo]="bibDialogo.iss"></titulo>
  <div *ngIf="entradaServico" class="row">
    <decimal [ngClass]="compacto ? 'col-sm-4' : 'col-sm-4'" [rotulo]="bibDialogo.base" [campo]="movimentacaoProduto.getIssBase()" (alteracao)="movimentacaoProduto.setIssBase($event)" [obrigatorio]="obrigatorio" [desabilitado]="bloqueado && bloqueiaAlteracao"> </decimal>
    <decimal [ngClass]="compacto ? 'col-sm-4' : 'col-sm-4'" [rotulo]="bibDialogo.aliquota" [campo]="movimentacaoProduto.getIssAliquota()" (alteracao)="movimentacaoProduto.setIssAliquota($event)" [obrigatorio]="obrigatorio" [desabilitado]="bloqueado && bloqueiaAlteracao"> </decimal>
    <decimal [ngClass]="compacto ? 'col-sm-4' : 'col-sm-4'" [rotulo]="bibDialogo.valor" [campo]="movimentacaoProduto.getIssValor()" (alteracao)="movimentacaoProduto.setIssValor($event)" [obrigatorio]="obrigatorio" [desabilitado]="bloqueado && bloqueiaAlteracao"> </decimal>
  </div>    

</layout>
<bloqueado [bloqueado]="bloqueado"></bloqueado>
<atencao *ngIf="bloqueiaAlteracao == false && alimentaCustoReposicao" [atencao]="bibDialogo.atencao" [tipo]="bibPropriedade.atencao.cuidado"></atencao>
<atencao *ngIf="bloqueiaAlteracao == false && alimentaCustoReposicao" [atencao]="'Ao alterar os impostos, o custos calculados anteriormente NÃO serão modificados. Se os impostos compõem a sua formação de custo, atualize os custos manualmente.'" [tipo]="bibPropriedade.atencao.alerta"></atencao>
<div class="row align-items-center justify-content-between p-0 m-0">  
  <botao [compacto]="compacto" [legenda]="bibDialogo.adicionar" [fundo]="true" (botaoEmt)="aceitarProdutoImposto()" [desabilitado]="bloqueado && bloqueiaAlteracao" (saindo)="focoId = $event"></botao>
  <atencao [atencao]="bibDialogo.valorTotalItem + ': ' + (movimentacaoProduto.valorTotal | monetario)" [tipo]="bibPropriedade.atencao.sucesso"></atencao>
</div>