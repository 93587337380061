import { Injectable } from '@angular/core';
import { plainToClass } from 'class-transformer';
import { BuscaAnterior } from '../modelo/buscaAnterior';
import { ConfiguracaoFinanceiroTotalContabil } from '../modelo/configuracaoFinanceiroTotalContabil';
import { ConfiguracaoMovimentacaoTotalContabil } from '../modelo/configuracaoMovimentacaoTotalContabil';
import { ContaContabil } from '../modelo/contaContabil';
import { Criterio } from '../modelo/criterio';
import { DestinoParceiro } from '../modelo/destinoParceiro';
import { Empresa } from '../modelo/empresa';
import { EmpresaOperacaoBloqueio } from '../modelo/empresaOperacaoBloqueio';
import { Filtro } from '../modelo/filtro';
import { Identificacao } from '../modelo/identificacao';
import { IntegracaoUsuario } from '../modelo/integracaoUsuario';
import { Loja } from '../modelo/loja';
import { MenuApelido } from '../modelo/menuApelido';
import { MenuEmpresa } from '../modelo/menuEmpresa';
import { Nomenclatura } from '../modelo/nomenclatura';
import { Operacao } from '../modelo/operacao';
import { OperacaoParametro } from '../modelo/operacaoParametro';
import { Permissao } from '../modelo/permissao';
import { Preferencia } from '../modelo/preferencia';
import { Relatorio } from '../modelo/relatorio';
import { RelatorioAdicional } from '../modelo/relatorioAdicional';
import { RelatorioMenu } from '../modelo/relatorioMenu';
import { RelatorioUsuario } from '../modelo/relatorioUsuario';
import { Resultado } from '../modelo/resultado';
import { Usuario } from '../modelo/usuario';
import { UsuarioConta } from '../modelo/usuarioConta';
import { UsuarioMenu } from '../modelo/usuarioMenu';
import { UtilCriptografia } from './utilCriptografia';

@Injectable()
export class UtilSessao {
  private permissaoEspecifica: Permissao;
  private resultados: Resultado[] = [];
  private utilCriptografia: UtilCriptografia = new UtilCriptografia();
  public alertaAlteracao: boolean = false;
  public ambienteProducao: boolean = true;
  public falha: Boolean = false;
  public identificacoes: Identificacao[] = [];
  public informacaoAlert: boolean = false;
  public mensagemAguardando: boolean = false;
  public mensagemNFe: string = '';
  public mensagemDesistirEspera: boolean = false;
  public modal: boolean = false;
  public modalFiltro: boolean;
  public modalNomes: string[] = [];
  public mostraId: boolean = false;
  public operacaoParametros: OperacaoParametro[] = [];
  public processando: number = 0;
  public telaFiltrada: string;
  public telaInterna: boolean = false;
  public iniciarListagem: boolean = false;
  public criterios: Criterio[] = [];
  public numeroRegistro: number = 0;
  public posicao: number = 0;
  public sistema: string = 'aGrow-w';
  public backend: string = 'localhost';
  public idRelatorioInterno: number = null;
  public contaContabeis: ContaContabil[] = [];
  public idsPropriedadesExcluir: number[] = [];
  public idCaixa: number = 0;

  getIdLojaMovimentacao(): number {
    return JSON.parse(sessionStorage.getItem('idLojaMovimentacao'));
  }

  setIdLojaMovimentacao(idLojaMovimentacao: number) {
    sessionStorage.setItem('idLojaMovimentacao', JSON.stringify(idLojaMovimentacao));
  }

  getDataAberturaCaixa(): Date {
    return JSON.parse(sessionStorage.getItem('dataAberturaCaixa'));
  }

  setDataAberturaCaixa(dataAberturaCaixa: Date) {
    sessionStorage.setItem('dataAberturaCaixa', JSON.stringify(dataAberturaCaixa));
  }

  getIdCaixa(): number {
    return JSON.parse(sessionStorage.getItem('idCaixa'));
  }

  setIdCaixa(idCaixa: number) {
    sessionStorage.setItem('idCaixa', JSON.stringify(idCaixa));
  }

  getIdConta(): number {
    return JSON.parse(sessionStorage.getItem('idConta'));
  }

  setIdConta(idConta: number) {
    sessionStorage.setItem('idConta', JSON.stringify(idConta));
  }

  getBuscaAnterior(): BuscaAnterior[] {
    return JSON.parse(sessionStorage.getItem('buscaAnterior'));
  }

  setBuscaAnterior(buscaAnterior: BuscaAnterior[]) {
    sessionStorage.setItem('buscaAnterior', JSON.stringify(buscaAnterior));
  }

  getFiltroNome(): string {
    return JSON.parse(sessionStorage.getItem('filtroNome'));
  }

  setFiltroNome(filtroNome: string): void {
    sessionStorage.setItem('filtroNome', JSON.stringify(filtroNome));
  }

  setBloqueado(bloqueado: boolean): any {
    localStorage.setItem('bloqueado', JSON.stringify(bloqueado));
  }

  getBloqueado(): boolean {
    return JSON.parse(localStorage.getItem('bloqueado'));
  }

  setFiltro(filtros: Filtro[]): void {
    localStorage.setItem('filtros', JSON.stringify(filtros));
  }

  getFiltro(idMenu: number): Filtro[] {
    const filtros: Filtro[] = JSON.parse(localStorage.getItem('filtros'));
    if (filtros) {
      return filtros.filter((filtro) => filtro.idMenu == idMenu);
    }
    return [];
  }

  setFiltroSemAlteracaoManual(idMenu: number): void {
    const filtros: Filtro[] = JSON.parse(localStorage.getItem('filtros')).filter((filtro) => filtro.idMenu == idMenu);
    filtros.forEach((filtro) => {
      if (filtro.manual == 'S') {
        filtro.valor = null;
      }
    });
    this.setFiltro(filtros);
  }

  getFiltroRelatorio(idRelatorio: number): Filtro[] {
    const filtros: Filtro[] = JSON.parse(localStorage.getItem('filtros'));
    if (filtros) {
      return filtros.filter((filtro) => filtro.idRelatorio == idRelatorio);
    }
    return [];
  }

  getRelatorioNome(): string {
    return sessionStorage.getItem('relatorioNome');
  }

  setRelatorioNome(relatorioNome: string): void {
    sessionStorage.setItem('relatorioNome', relatorioNome);
  }

  getFiltroIntegracao(idIntegracao: number): Filtro[] {
    const filtros: Filtro[] = JSON.parse(localStorage.getItem('filtros'));
    if (filtros) {
      return filtros.filter((filtro) => filtro.idIntegracao == idIntegracao);
    }
    return [];
  }

  setLimparFiltro(): void {
    const filtros: Filtro[] = [];
    localStorage.setItem('filtros', JSON.stringify(filtros));
  }

  setCriterio(criterios: Criterio[]): void {
    let criteriosSessao: Criterio[] = JSON.parse(sessionStorage.getItem('criterios'));
    if (criteriosSessao && criterios.length > 0) {
      criteriosSessao = criteriosSessao.filter((criterio) => criterio.idMenu != criterios[0].idMenu);
    } else {
      criteriosSessao = [];
    }
    criterios.forEach((criterio) => {
      criteriosSessao.push(criterio);
    });
    sessionStorage.setItem('criterios', JSON.stringify(criteriosSessao));
  }

  getCriterio(): Criterio[] {
    const criterios: Criterio[] = JSON.parse(sessionStorage.getItem('criterios'));
    return criterios;
  }

  setCriterios(criterios: Criterio[]): void {
    this.criterios = criterios;
  }

  getCriterios(): Criterio[] {
    return this.criterios;
  }

  setLimparCriterio(idMenu: number, limparCriterio: boolean = false): void {
    // if (limparCriterio) {
    //   let criteriosSessao: Criterio[] = JSON.parse(sessionStorage.getItem('criterios'));
    //   if (criteriosSessao) {
    //     criteriosSessao.forEach((criterio) => {
    //       if (criterio.idMenu == idMenu) {
    //         criterio.valor = '';
    //       }
    //     });
    //   }
    //   sessionStorage.setItem('criterios', JSON.stringify(criteriosSessao));
    // }
  }

  getCriterioRelatorio(idRelatorio: number): Criterio[] {
    let criterios: Criterio[] = JSON.parse(sessionStorage.getItem('criterios'));
    if (criterios) {
      criterios = criterios.filter((criterio) => criterio.idRelatorio == idRelatorio);
      return criterios;
    }
    return criterios;
  }

  getCriterioIntegracao(idIntegracao: number): Criterio[] {
    let criterios: Criterio[] = JSON.parse(sessionStorage.getItem('criterios'));
    if (criterios) {
      criterios = criterios.filter((criterio) => criterio.idIntegracao == idIntegracao);
      return criterios;
    }
    return criterios;
  }

  setParametroTemporario(idOperacao: number): void {
    sessionStorage.setItem('parametroTemporario', JSON.stringify(idOperacao));
  }

  getParametroTemporario(): number {
    return JSON.parse(sessionStorage.getItem('parametroTemporario'));
  }

  getParametroHistorico(): string {
    return JSON.parse(sessionStorage.getItem('parametroHistorico'));
  }

  setParametroHistorico(parametroHistorico: string): void {
    sessionStorage.setItem('parametroHistorico', JSON.stringify(parametroHistorico));
  }

  setIdPai(idPai: any): void {
    sessionStorage.setItem('idPai', JSON.stringify(idPai));
  }

  getIdPai(): any {
    return JSON.parse(sessionStorage.getItem('idPai'));
  }

  setMenuPersonalizado(menuPersonalizado: boolean): void {
    sessionStorage.setItem('menuPersonalizado', JSON.stringify(menuPersonalizado));
  }

  getMenuPersonalizado(): boolean {
    return JSON.parse(sessionStorage.getItem('menuPersonalizado'));
  }

  setMenuEmpresa(menus: MenuEmpresa[]): void {
    localStorage.setItem('menus', JSON.stringify(menus));
  }

  getMenuEmpresa(): MenuEmpresa[] {
    return JSON.parse(localStorage.getItem('menus'));
  }

  setMenuApelido(menusApelidos: MenuApelido[]): void {
    localStorage.setItem('menusApelidos', JSON.stringify(menusApelidos));
  }

  getMenuApelido(): MenuApelido[] {
    return JSON.parse(localStorage.getItem('menusApelidos'));
  }

  setIdLoja(idLoja: number): void {
    localStorage.setItem('idLoja', JSON.stringify(idLoja));
  }

  getIdLoja(): number {
    return JSON.parse(localStorage.getItem('idLoja'));
  }

  setModulos(modulos: any) {
    localStorage.setItem('modulos', JSON.stringify(modulos));
  }

  getModulos(): any {
    if (this.getModulosEmpresa().length == 0) {
      return JSON.parse(localStorage.getItem('modulos'));
    } else {
      return this.getModulosEmpresa();
    }
  }

  setModulosEmpresa(modulosEmpresa: any): void {
    localStorage.setItem('modulosEmpresa', JSON.stringify(modulosEmpresa));
  }

  getModulosEmpresa(): any {
    return JSON.parse(localStorage.getItem('modulosEmpresa'));
  }

  setOperacao(operacao: Operacao) {
    sessionStorage.setItem('operacao', JSON.stringify(operacao));
  }

  getOperacao(): Operacao {
    return JSON.parse(sessionStorage.getItem('operacao'));
  }

  setOperacaoParametros(operacaoParametros: OperacaoParametro[]): void {
    let operacaoParametrosSessao: OperacaoParametro[] = this.getOperacaoParametros() ? this.getOperacaoParametros() : [];
    operacaoParametrosSessao.push(...operacaoParametros);
    localStorage.setItem('operacaoParametros', JSON.stringify(operacaoParametrosSessao));
  }

  getOperacaoParametros(): OperacaoParametro[] {
    return JSON.parse(localStorage.getItem('operacaoParametros'));
  }

  setParametro(parametro: any): void {
    sessionStorage.setItem('parametro', JSON.stringify(parametro));
  }

  getParametro(): any {
    if (typeof sessionStorage.getItem('parametro') != 'undefined') {
      return JSON.parse(sessionStorage.getItem('parametro'));
    } else {
      return null;
    }
  }

  setParametroCadastro(parametroCadastro: any): void {
    sessionStorage.setItem('parametroCadastro', JSON.stringify(parametroCadastro));
  }

  getParametroCadastro(): any {
    if (typeof sessionStorage.getItem('parametroCadastro') != 'undefined') {
      return JSON.parse(sessionStorage.getItem('parametroCadastro'));
    } else {
      return null;
    }
  }

  setPermissao(permissao: any) {
    sessionStorage.setItem('permissao', JSON.stringify(permissao));
  }

  getPermissao(): any {
    const permissaoEspecifica: Permissao = this.getPermissaoEspecifica();
    if (permissaoEspecifica && permissaoEspecifica != undefined) {
      return permissaoEspecifica;
    } else {
      return JSON.parse(sessionStorage.getItem('permissao'));
    }
  }

  setPermissaoEspecifica(permissaoEspecifica: any) {
    this.permissaoEspecifica = permissaoEspecifica;
  }

  getPermissaoEspecifica(): any {
    return this.permissaoEspecifica;
  }

  setPermissoes(permissoes: any): void {
    localStorage.setItem('permissoes', JSON.stringify(permissoes));
  }

  getPermissoes(): any {
    return JSON.parse(localStorage.getItem('permissoes'));
  }

  setPesquisando(pesquisando: boolean) {
    sessionStorage.setItem('pesquisando', JSON.stringify(pesquisando));
  }

  getPesquisando(): boolean {
    return JSON.parse(sessionStorage.getItem('pesquisando'));
  }

  setExisteTipoLancamentoContabil(existeTipoLancamentoContabil: boolean) {
    localStorage.setItem('existeTipoLancamentoContabil', JSON.stringify(existeTipoLancamentoContabil));
  }

  getExisteTipoLancamentoContabil(): boolean {
    return JSON.parse(localStorage.getItem('existeTipoLancamentoContabil'));
  }

  setPreferencia(preferencia: Preferencia): void {
    localStorage.setItem('preferencia', JSON.stringify(preferencia));
  }

  getPreferencia(): Preferencia {
    let preferencia = JSON.parse(localStorage.getItem('preferencia'));
    return preferencia;
  }

  setUsuario(usuario: Usuario): void {
    let usuarioCriptografar: Usuario = plainToClass(Usuario, usuario);
    if (usuarioCriptografar) {
      Object.keys(usuarioCriptografar).forEach((atributo) => {
        if (atributo != 'nome') {
          usuarioCriptografar[atributo] = atributo.indexOf('foto') == -1 ? this.utilCriptografia.criptografar(usuarioCriptografar[atributo]) : usuarioCriptografar[atributo];
        }
      });
    }
    localStorage.setItem('usuario', JSON.stringify(usuarioCriptografar));
  }

  getUsuario(): Usuario {
    let usuario: Usuario = JSON.parse(localStorage.getItem('usuario'));
    if (usuario) {
      Object.keys(usuario).forEach((atributo) => {
        if (atributo != 'nome') {
          usuario[atributo] = atributo.indexOf('foto') == -1 ? this.utilCriptografia.descriptografar(usuario[atributo]) : usuario[atributo];
        }
      });
    }
    return usuario;
  }

  setLoginCompleto(loginCompleto: boolean) {
    localStorage.setItem('loginCompleto', JSON.stringify(loginCompleto));
  }

  getLoginCompleto(): boolean {
    return JSON.parse(localStorage.getItem('loginCompleto'));
  }

  setRelatorios(relatorios: Relatorio[]): void {
    localStorage.setItem('relatorios', JSON.stringify(relatorios));
  }

  getRelatorios(): Relatorio[] {
    return JSON.parse(localStorage.getItem('relatorios'));
  }

  setUsuarioIntegracoes(usuarioIntegracoes: IntegracaoUsuario[]): void {
    localStorage.setItem('usuarioIntegracao', JSON.stringify(usuarioIntegracoes));
  }

  getUsuarioIntegracoes(): IntegracaoUsuario[] {
    return JSON.parse(localStorage.getItem('usuarioIntegracao'));
  }

  setRelatorioAdicionais(relatorioAdicionais: RelatorioAdicional[]): void {
    if (relatorioAdicionais != null) {
      let relatorioAdicionaisSessao: RelatorioAdicional[] = this.getRelatorioAdicionais();
      if (relatorioAdicionaisSessao != null) {
        relatorioAdicionais.forEach((relatorioAdicional) => {
          if (relatorioAdicionaisSessao.find((relatorioAdicionalSessao) => relatorioAdicionalSessao.id == relatorioAdicional.id) == undefined) {
            relatorioAdicionaisSessao.push(relatorioAdicional);
          }
        });
      } else {
        relatorioAdicionaisSessao = relatorioAdicionais;
      }
      localStorage.setItem('relatorioAdicionais', JSON.stringify(relatorioAdicionaisSessao));
    } else {
      localStorage.setItem('relatorioAdicionais', JSON.stringify(relatorioAdicionais));
    }
  }

  getRelatorioAdicionais(): RelatorioAdicional[] {
    return JSON.parse(localStorage.getItem('relatorioAdicionais'));
  }

  setRelatorioMenus(relatorioMenus: RelatorioMenu[]) {
    localStorage.setItem('relatorioMenus', JSON.stringify(relatorioMenus));
  }

  getRelatorioMenus(): RelatorioMenu[] {
    return JSON.parse(localStorage.getItem('relatorioMenus'));
  }

  setRelatorioUsuarios(relatorioUsuarios: RelatorioUsuario[]): void {
    localStorage.setItem('relatorioUsuarios', JSON.stringify(relatorioUsuarios));
  }

  getRelatorioUsuarios(): RelatorioUsuario[] {
    return JSON.parse(localStorage.getItem('relatorioUsuarios'));
  }

  setDashboardUsuarios(relatorioUsuarios: RelatorioUsuario[]): void {
    localStorage.setItem('dashboardUsuarios', JSON.stringify(relatorioUsuarios));
  }

  getDashboardUsuarios(): RelatorioUsuario[] {
    return JSON.parse(localStorage.getItem('dashboardUsuarios'));
  }

  setRelatorioUsuarioMenus(relatorioUsuarios: RelatorioUsuario[]): void {
    localStorage.setItem('relatorioUsuarioMenus', JSON.stringify(relatorioUsuarios));
  }

  getRelatorioUsuarioMenus(): RelatorioUsuario[] {
    return JSON.parse(localStorage.getItem('relatorioUsuarioMenus'));
  }

  setEmpresaOperacaoBloqueia(empresaOperacaoBloqueios: EmpresaOperacaoBloqueio[]) {
    localStorage.setItem('empresaOperacaoBloqueios', JSON.stringify(empresaOperacaoBloqueios));
  }

  getEmpresaOperacaoBloqueia(): EmpresaOperacaoBloqueio[] {
    return JSON.parse(localStorage.getItem('empresaOperacaoBloqueios'));
  }

  setEmpresa(empresa: Empresa): void {
    Object.keys(empresa).forEach((atributo) => {
      if (atributo != 'fotoEmpresa') {
        empresa[atributo] = this.utilCriptografia.criptografar(empresa[atributo]);
      }
    });
    localStorage.setItem('empresa', JSON.stringify(empresa));
  }

  getEmpresa(): Empresa {
    let empresa = JSON.parse(localStorage.getItem('empresa'));
    Object.keys(empresa).forEach((atributo) => {
      if (atributo != 'fotoEmpresa') {
        empresa[atributo] = this.utilCriptografia.descriptografar(empresa[atributo]);
      }
    });
    return empresa;
  }

  setDestinoParceiroPesquisa(destinoParceiroPesquisa: DestinoParceiro) {
    localStorage.setItem('destinoParceiroPesquisa', JSON.stringify(destinoParceiroPesquisa));
  }

  getDestinoParceiroPesquisa(): DestinoParceiro {
    return JSON.parse(localStorage.getItem('destinoParceiroPesquisa'));
  }

  setLojas(lojas: Loja[]): void {
    lojas.forEach((loja) => {
      Object.keys(loja).forEach((atributo) => {
        if (atributo != 'nomeFantasia' && atributo != 'razaoSocial' && atributo != 'fotoLoja') {
          loja[atributo] = this.utilCriptografia.criptografar(loja[atributo]);
        }
      });
    });
    localStorage.setItem('lojas', JSON.stringify(lojas));
  }

  getLojas(): Loja[] {
    let lojas: Loja[] = plainToClass(Loja, JSON.parse(localStorage.getItem('lojas'))) as any;
    lojas.forEach((loja) => {
      Object.keys(loja).forEach((atributo) => {
        if (atributo != 'nomeFantasia' && atributo != 'razaoSocial' && atributo != 'fotoLoja') {
          loja[atributo] = this.utilCriptografia.descriptografar(loja[atributo]);
        }
      });
    });
    return lojas;
  }

  setUsuarioContas(usuarioContas: UsuarioConta[]): void {
    localStorage.setItem('usuarioContas', JSON.stringify(usuarioContas));
  }

  getUsuarioContas(): UsuarioConta[] {
    return JSON.parse(localStorage.getItem('usuarioContas'));
  }

  setUsuarioMenus(usuarioMenus: UsuarioMenu[]): void {
    localStorage.setItem('usuarioMenus', JSON.stringify(usuarioMenus));
  }

  getUsuarioMenus(): UsuarioMenu[] {
    return JSON.parse(localStorage.getItem('usuarioMenus'));
  }

  setNomenclaturaEmpresas(nomenclaturaEmpresas: Nomenclatura[]): void {
    localStorage.setItem('nomenclaturaEmpresas', JSON.stringify(nomenclaturaEmpresas));
  }

  getNomenclaturaEmpresas(): Nomenclatura[] {
    return JSON.parse(localStorage.getItem('nomenclaturaEmpresas'));
  }

  getAlteracao(): boolean {
    return JSON.parse(sessionStorage.getItem('alteracao'));
  }

  setAlteracao(alteracao: boolean) {
    sessionStorage.setItem('alteracao', JSON.stringify(alteracao));
  }

  getMostrarAtencaoSalvar(): boolean {
    return JSON.parse(localStorage.getItem('atencaoSalvar'));
  }

  setMostrarAtencaoSalvar(mostrar: boolean) {
    localStorage.setItem('atencaoSalvar', JSON.stringify(mostrar));
  }

  setRelatorioCriterios(criterios: Criterio[]): void {
    localStorage.setItem('criterioRelatorio', JSON.stringify(criterios));
    sessionStorage.removeItem('criterioRelatorio');
  }

  getRelatorioCriterios(idRelatorio: number): Criterio[] {
    let criterios: Criterio[] = JSON.parse(sessionStorage.getItem('criterioRelatorio'));
    if (criterios == null) {
      criterios = JSON.parse(localStorage.getItem('criterioRelatorio'));
      if (criterios) {
        localStorage.removeItem('criterioRelatorio');
        sessionStorage.setItem('criterioRelatorio', JSON.stringify(criterios));
      }
    }
    if (criterios) {
      if (criterios.find((criterio) => criterio.idRelatorio && criterio.idRelatorio != idRelatorio)) {
        criterios = null;
      }
    }
    return criterios;
  }

  iniciarProcesso(): void {
    this.processando++;
    if (this.iniciarListagem) {
      setTimeout(() => {
        if (this.processando > 0) {
          this.mensagemAguardando = true;
        }
      }, 5000);

      setTimeout(() => {
        if (this.processando > 0) {
          this.mensagemDesistirEspera = true;
        }
      }, 30000);
    }
  }

  finalizarProcesso(completo: boolean = false): void {
    this.processando = completo ? 0 : this.processando - 1;
    if (this.processando < 0) {
      this.processando = 0;
    }
    this.iniciarListagem = false;
    this.mensagemAguardando = false;
    this.mensagemDesistirEspera = false;
  }

  mensagemProcessandoNFe(etapa: string): void {
    this.mensagemNFe = '';
    if (etapa != '') {
      this.mensagemNFe = etapa;
    }
  }

  setResultado(resultado: Resultado): void {
    if (!resultado.sucesso) {
      //this.finalizarProcesso();
      this.falha = true;
    }

    let mensagemRepetida: Resultado = this.resultados.find((resultadoBusca) => resultadoBusca.mensagem && resultadoBusca.mensagem == resultado.mensagem);
    if (!mensagemRepetida) {
      this.resultados.push(resultado);
    }
  }

  getResultados(): Resultado[] {
    return this.resultados;
  }

  getTelaHistorico(): string {
    return JSON.parse(sessionStorage.getItem('telaHistorico'));
  }

  setTelaHistorico(fechar: string): void {
    sessionStorage.setItem('telaHistorico', JSON.stringify(fechar));
  }

  getConfiguracaoMovimentacaoTotalContabil(): ConfiguracaoMovimentacaoTotalContabil[] {
    return JSON.parse(localStorage.getItem('configuracaoContabil'));
  }

  setConfiguracaoMovimentacaoTotalContabil(configuracaoMovimentacaoTotalContabeis: ConfiguracaoMovimentacaoTotalContabil[]) {
    localStorage.setItem('configuracaoContabil', JSON.stringify(configuracaoMovimentacaoTotalContabeis));
  }

  getConfiguracaoFinanceiroTotalContabil(): ConfiguracaoFinanceiroTotalContabil[] {
    return JSON.parse(localStorage.getItem('configuracaoFinanceiroContabil'));
  }

  setConfiguracaoFinanceiroTotalContabil(configuracaoFinanceiroTotalContabeis: ConfiguracaoFinanceiroTotalContabil[]) {
    let configuracaoFinanceiroTotalContabilNovos: ConfiguracaoFinanceiroTotalContabil[] = this.getConfiguracaoFinanceiroTotalContabil() ? this.getConfiguracaoFinanceiroTotalContabil() : [];
    configuracaoFinanceiroTotalContabeis.forEach((configuracaoFinanceiroTotalContabil) => {
      configuracaoFinanceiroTotalContabilNovos.push(configuracaoFinanceiroTotalContabil);
    });
    localStorage.setItem('configuracaoFinanceiroContabil', JSON.stringify(configuracaoFinanceiroTotalContabeis));
  }

  getPaginaAtual(): number {
    return JSON.parse(sessionStorage.getItem('paginaAtual'));
  }

  setPaginaAtual(paginaAtual: number): void {
    sessionStorage.setItem('paginaAtual', JSON.stringify(paginaAtual));
  }

  setIdentificacao(identificacao: Identificacao): void {
    let identificacoes: Identificacao[] = [];
    this.excluirIdentificacao(identificacao.nome);
    if (identificacao.nome != 'id') {
      identificacoes = this.getIdentificacoes();
    }
    identificacoes.push(identificacao);
    this.setIdentificacoes(identificacoes, identificacao.sessao);
  }

  getIdentificacao(nome: string, sessao: boolean = true): Identificacao {
    let identificacoes: Identificacao[] = this.getIdentificacoes(sessao);
    const _identificacoes: Identificacao[] = identificacoes.filter((identificacao) => identificacao.nome == nome);
    let identificacao: Identificacao = null;
    if (_identificacoes.length > 0) {
      identificacao = _identificacoes[0];
    }
    if (nome == 'objeto') {
      this.excluirIdentificacao(nome);
    }
    return identificacao;
  }

  private setIdentificacoes(identificacoes: Identificacao[], sessao: boolean = true): void {
    if (sessao) {
      sessionStorage.setItem('identificacoes', JSON.stringify(identificacoes));
    } else {
      this.identificacoes = identificacoes;
    }
  }

  getIdentificacoes(sessao: boolean = true): Identificacao[] {
    let identificacoes: Identificacao[];
    if (sessao) {
      identificacoes = JSON.parse(sessionStorage.getItem('identificacoes'));
    } else {
      identificacoes = this.identificacoes;
    }
    if (!identificacoes) {
      identificacoes = [];
    }
    return identificacoes;
  }

  excluirIdentificacao(nome: string): void {
    const identificacoes: Identificacao[] = this.getIdentificacoes();
    for (let i = 0; i < identificacoes.length; i++) {
      if (identificacoes[i].nome == nome) {
        identificacoes.splice(i, 1);
        i = identificacoes.length;
      }
      this.setIdentificacoes(identificacoes);
    }
  }

  limparIdentificacoes(): void {
    this.setIdentificacoes([]);
  }

  limparResultado(): void {
    this.resultados = [];
    this.falha = false;
    this.informacaoAlert = false;
    this.alertaAlteracao = false;
  }

  reiniciar(): void {
    sessionStorage.clear();
  }

  mascaraCnpj(value): string {
    return value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '$1.$2.$3/$4-$5');
  }

  setDominio(dominio: string): any {
    localStorage.setItem('dominio', JSON.stringify(dominio));
  }

  getDominio(concatenaDominio: string = ''): string {
    const dominio: string = JSON.parse(localStorage.getItem('dominio'));
    if (dominio) {
      return concatenaDominio + dominio;
    }
    return '';
  }

  getRealizandoBaixa(): any {
    return JSON.parse(localStorage.getItem('realizandoBaixa'));
  }

  setRealizandoBaixa(realizandoBaixa: string) {
    localStorage.setItem('realizandoBaixa', JSON.stringify(realizandoBaixa));
  }

  setApresentaCategoria(categoria: string): void {
    localStorage.setItem('categoria', JSON.stringify(categoria));
  }

  getApresentaCategoria(): string {
    return JSON.parse(localStorage.getItem('categoria'));
  }

  setDataUsuarioLogin(data: Date): void {
    localStorage.setItem('usuarioData', JSON.stringify(data));
  }

  getDataUsuarioLogin(): Date {
    return JSON.parse(localStorage.getItem('usuarioData'));
  }

  getDataAtualLogin(): Date {
    return JSON.parse(localStorage.getItem('dataAtualLogin'));
  }

  setDataAtualLogin(data: Date): void {
    localStorage.setItem('dataAtualLogin', JSON.stringify(data));
  }
}
