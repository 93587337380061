import { Type } from 'class-transformer';

export class Aprovacao {
  @Type(() => Date)
  public data: Date;

  public aprovacaoMotivo: string;
  public ativo: string;
  public categoria: string;
  public chave: string;
  public dataHoraAlteracao: Date;
  public dataHoraInclusao: Date;
  public descricao: string;
  public finalizado: string;
  public id: number;
  public idAprovacaoMotivo: number;
  public idEmpresa: number;
  public idLoja: number;
  public idParceiro: number;
  public idUsuarioAlteracao: number;
  public idUsuarioAprovacao: number;
  public idUsuarioInclusao: number;
  public justificativa: string;
  public lojaAbreviacao: string;
  public numero: number = 0;
  public numeroParceiro: number;
  public numeroResponsavel: number;
  public origem: number;
  public parceiro: string;
  public responsavel: string;
  public responsavelAbreviacao: string;
  public situacao: string;
  public finalizadoOrigem: string;
  public aprovadorReprovador: string;
}
