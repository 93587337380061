import { Total } from 'src/app/interface/total';

const totais: Total[] = [
  { campo: 'quantidade', posicao: 7, mostrar: false },
  { campo: 'valorFinal', posicao: 11 },
  { campo: 'comissao', posicao: 12, mostrar: false },
  { campo: 'pesoLiquido', posicao: 13, mostrar: false },
  { campo: 'pesoBruto', posicao: 14, mostrar: false },
];
export default totais;
