const bibPropriedade = {
  lista: {
    contaUsuario: 'contaUsuario',
    lojaUsuario: 'lojaUsuario',
    produtoUnidade: 'produtoUnidade',
    lote: 'lote',
  },
  atencao: {
    sucesso: 'sucesso',
    cuidado: 'cuidado',
    alerta: 'alerta',
    tachado: 'tachado',
    discreto: 'discreto',
    informativo: 'informativo',
    roxo: 'roxo',
  },
  botao: {
    sucesso: 'sucesso',
    cuidado: 'cuidado',
    atencao: 'atencao',
    escuro: 'escuro',
  },
  texto: {
    email: 'email',
    senha: 'senha',
    inteiro: 'inteiro',
    numero: 'numero',
    contabil: 'contabil',
  },
  mascara: {
    cpf: 'cpf',
    cnpj: 'cnpj',
    cep: 'cep',
    telefone: 'telefone',
    hora: 'hora',
    horaMinuto: 'horaMinuto',
    contabil: 'contabil',
  },
  filtro: {
    dataHoje: 'DATA_HOJE',
    texto: 0,
    data: 1,
    busca: 2,
    numero: 3,
    lista: 4,
    combo: 5,
    checklist: 6,
    valorPadrao: 7,
    interruptor: 8,
    listaLojaUsuario: 9,
    mascara: 10,
    decimal: 11,
    checklista: 12,
    mascaraContabil: 13,
  },
  icone: {
    amarelo: 'amarelo',
    azul: 'azul',
    verde: 'verde',
    vermelho: 'vermelho',
    roxo: 'roxo',
    cinza: 'cinza',
    laranja: 'laranja',
    cinzaEscuro: 'cinza escuro',
    preto: 'preto',
    ciano: 'ciano',
    branco: 'branco',
  },
  cor: {
    amarelo: 'bg-amarelo',
    azul: 'bg-azul',
    cinza: 'bg-cinza',
    cinzaEscuro: 'bg-cinza-escuro',
    verde: 'bg-verde',
    vermelho: 'bg-vermelho',
    laranja: 'bg-laranja',
    ciano: 'bg-ciano',
  },
  totalizador: {
    esquerda: 'alinhar-esquerda',
    centro: 'alinhar-centro',
    direita: 'alinhar-direita',
  },
  tamanho: {
    medio: '20px',
  }
};
export default bibPropriedade;
