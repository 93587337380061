import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { plainToClass } from 'class-transformer';
import { Observable, of } from 'rxjs';
import { share } from 'rxjs/operators';
import bibClasse from 'src/app/biblioteca/bibClasse';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import bibIcone from 'src/app/biblioteca/bibIcone';
import bibImagem from 'src/app/biblioteca/bibImagem';
import bibPropriedade from 'src/app/biblioteca/bibPropriedade';
import bibServico from 'src/app/biblioteca/bibServico';
import { Identificacao } from 'src/app/modelo/identificacao';
import { Permissao } from 'src/app/modelo/permissao';
import { Resultado } from 'src/app/modelo/resultado';
import { Transporte } from 'src/app/modelo/transporte';
import { LoginEmitter } from 'src/app/pagina/login/login.emitter';
import { ComunicacaoService } from 'src/app/servico/comunicacao.service';
import { Util } from 'src/app/utilitario/util';
import { UtilSessao } from 'src/app/utilitario/util.sessao';
import bibAtalho from '../biblioteca/bibAtalho';
import { BibNomenclatura } from '../biblioteca/bibNomenclatura';
import { ModalNotificacao } from '../essencial/modal/modal.notificacao';
import { Criterio } from '../modelo/criterio';
import { Empresa } from '../modelo/empresa';
import { MenuEmpresa } from '../modelo/menuEmpresa';
import { ComunicacaoNsService } from '../servico/comunicacaoNs.service';
import { ComunicacaoNsCTeService } from '../servico/comunicacaoNsCTe.service';
import { ComunicacaoTecnoSpeedService } from '../servico/comunicacaoTecnoSpeed.service';
import { ComunicacaoTecnoSpeedBoletoService } from '../servico/comunicacaoTecnoSpeedBoleto.service';
import { ComunicacaoTecnoSpeedMDFeService } from '../servico/comunicacaoTecnoSpeedMDFe.service';
import { UtilBusca } from './util.busca';
import { UtilNotificacao } from './util.notificacao';

@Component({
  template: '',
})
export class PaginaComponent {
  private somenteFalhaAprovacao: boolean = true;
  public bibAtalho = bibAtalho;
  public bibClasse = bibClasse;
  public bibDialogo = bibDialogo;
  public bibIcone = bibIcone;
  public bibImagem = bibImagem;
  public bibNomenclatura: BibNomenclatura = new BibNomenclatura();
  public bibPropriedade = bibPropriedade;
  public bibServico = bibServico;
  public compacto: boolean;
  public ehModoClaro: boolean = this.utilSessao.getUsuario().usaModoClaro == 'S';
  public empresaRegraCasasDecimais: number;
  public focoId: number;
  public menuBairro: MenuEmpresa;
  public menuCategoria: MenuEmpresa;
  public menuCompendioEletronico: MenuEmpresa;
  public menuCategoriaRoteiroContabil: MenuEmpresa;
  public menuCentroDeResultado: MenuEmpresa;
  public menuCfopEmpresa: MenuEmpresa;
  public menuCidade: MenuEmpresa;
  public menuClassificacaoFiscal: MenuEmpresa;
  public menuCliente: MenuEmpresa;
  public menuColaborador: MenuEmpresa;
  public menuConfiguracaoClienteEspecial: MenuEmpresa;
  public menuConfiguracaoFinanceiroContabil: MenuEmpresa;
  public menuConfiguracaoTransferenciaContabil: MenuEmpresa;
  public menuConta: MenuEmpresa;
  public menuContaContabil: MenuEmpresa;
  public menuContaCorrente: MenuEmpresa;
  public menuCustoGerencial: MenuEmpresa;
  public menuCustoReposicao: MenuEmpresa;
  public menuEquipamento: MenuEmpresa;
  public menuFabricante: MenuEmpresa;
  public menuFamilia: MenuEmpresa;
  public menuFornecedor: MenuEmpresa;
  public menuGrupo: MenuEmpresa;
  public menuGrupoContabil: MenuEmpresa;
  public menuGrupoPermisao: MenuEmpresa;
  public menuHistorico: MenuEmpresa;
  public menuLancamentoContabil: MenuEmpresa;
  public menuLimiteCredito: MenuEmpresa;
  public menuLocal: MenuEmpresa;
  public menuNatureza: MenuEmpresa;
  public menuNaturezaOperacao: MenuEmpresa;
  public menuNegociacao: MenuEmpresa;
  public menuPlanoConta: MenuEmpresa;
  public menuPolegada: MenuEmpresa;
  public menuProduto: MenuEmpresa;
  public menuProdutoInformacoesFiscais: MenuEmpresa;
  public menuPraca: MenuEmpresa;
  public menuProdutoFormulado: string;
  public menuRecomendacao: MenuEmpresa;
  public menuRamoAtividade: MenuEmpresa;
  public menuRelacionamento: MenuEmpresa;
  public menuRoteiroContabil: MenuEmpresa;
  public menuTipoDeTitulo: MenuEmpresa;
  public menuTipoLancamentoContabil: MenuEmpresa;
  public menuTransportadora: MenuEmpresa;
  public menuUnidade: MenuEmpresa;
  public menuUnidadeAlternativa: string;
  public menuVinculo: MenuEmpresa;
  public paginacao: number = 100;
  public paginacaoAlterada: boolean;
  public permissao: Permissao;
  public permissaoAbrirMovimentacao: Permissao;
  public permissaoBairro: Permissao;
  public permissaoCategoria: Permissao;
  public permissaoCategoriaRoteiroContabil: Permissao;
  public permissaoCentroDeResultado: Permissao;
  public permissaoCfopEmpresa: Permissao;
  public permissaoCidade: Permissao;
  public permissaoClassificacaoFiscal: Permissao;
  public permissaoCliente: Permissao;
  public permissaoColaborador: Permissao;
  public permissaoConfiguracaoFinanceiroContabil: Permissao;
  public permissaoConfiguracaoTransferenciaConatbil: Permissao;
  public permissaoConta: Permissao;
  public permissaoContaContabil: Permissao;
  public permissaoEquipamento: Permissao;
  public permissaoFabricante: Permissao;
  public permissaoFamilia: Permissao;
  public permissaoFornecedor: Permissao;
  public permissaoGrupo: Permissao;
  public permissaoHistorico: Permissao;
  public permissaoLocal: Permissao;
  public permissaoLimiteCredito: Permissao;
  public permissaoNatureza: Permissao;
  public permissaoNegociacao: Permissao;
  public permissaoPlanoConta: Permissao;
  public permissaoPolegada: Permissao;
  public permissaoProduto: Permissao;
  public permissaoProdutoCustoGerencial: Permissao;
  public permissaoProdutoCustoReposicao: Permissao;
  public permissaoProdutoImposto: Permissao;
  public permissaoProdutoValor: Permissao;
  public permissaoPraca: Permissao;
  public permissaoRecomendacao: Permissao;
  public permissaoRamoAtividade: Permissao;
  public permissaoRelacionamento: Permissao;
  public permissaoRoteiroContabil: Permissao;
  public permissaoTipoDeTitulo: Permissao;
  public permissaoTipoLancamentoContabil: Permissao;
  public permissaoTransportadora: Permissao;
  public permissaoUnidade: Permissao;
  public permissaoVinculo: Permissao;
  public permissaoSafra: Permissao;
  public permissaoLoja: Permissao;
  public permissaoAprovacaoDeposito: Permissao;
  public plainToClass: Function = plainToClass;
  public posicao: number = 0;
  public temPermissaoEdicao: boolean = true;
  public temPermissaoExcluir: boolean = false;
  public temPermissaoInativar: boolean = true;
  public temPermissaoInserir: boolean = true;
  public util: Util;
  public utilBusca: UtilBusca;
  public descricaoUtilizacaoNcm: string = '';
  public maisDeUmaLoja: boolean = false;
  public empresa: Empresa;

  constructor(public comunicacaoService: ComunicacaoService, public activatedRoute: ActivatedRoute, public router: Router, public utilSessao: UtilSessao, public modalNotificacao: ModalNotificacao, public loginEmitter: LoginEmitter, public titleService: Title, public comunicacaoTecnoSpeedService: ComunicacaoTecnoSpeedService, public comunicacaoNsService: ComunicacaoNsService, public comunicacaoNsCTeService: ComunicacaoNsCTeService, public utilNotificacao: UtilNotificacao, public comunicacaoTecnoSpeedBoletoService: ComunicacaoTecnoSpeedBoletoService, public comunicacaoTecnoSpeedMDFeService: ComunicacaoTecnoSpeedMDFeService, public http: HttpClient) {
    this.maisDeUmaLoja = this.utilSessao.getLojas().length > 1;
    this.empresa = this.utilSessao.getEmpresa();
    this.util = new Util(router);
    this.permissao = this.utilSessao.getPermissao();
    if (this.permissao) {
      this.temPermissaoEdicao = this.permissao.editar == 'S' ? true : false;
      this.temPermissaoInserir = this.permissao.inserir == 'S' ? true : false;
      this.temPermissaoExcluir = this.permissao.excluir == 'S' ? true : false;
      this.temPermissaoInativar = this.permissao.inativar == 'S' ? true : false;
      this.utilBusca = new UtilBusca(comunicacaoService, utilSessao);
    }
    this.compacto = this.utilSessao.getEmpresa().compacto == 'S';
    this.empresaRegraCasasDecimais = this.utilSessao.getEmpresa().regraCasasDecimais;
    this.menuBairro = this.utilSessao.getMenuEmpresa().find((menuEmpresa) => menuEmpresa.idMenu == 2);
    this.menuCategoria = this.utilSessao.getMenuEmpresa().find((menuEmpresa) => menuEmpresa.idMenu == 12);
    this.menuCategoriaRoteiroContabil = this.utilSessao.getMenuEmpresa().find((menuEmpresa) => menuEmpresa.idMenu == 157);
    this.menuCentroDeResultado = this.utilSessao.getMenuEmpresa().find((menuEmpresa) => menuEmpresa.idMenu == 32);
    this.menuCfopEmpresa = this.utilSessao.getMenuEmpresa().find((menuEmpresa) => menuEmpresa.idMenu == 24);
    this.menuCidade = this.utilSessao.getMenuEmpresa().find((menuEmpresa) => menuEmpresa.idMenu == 1);
    this.menuClassificacaoFiscal = this.utilSessao.getMenuEmpresa().find((menuEmpresa) => menuEmpresa.idMenu == 60);
    this.menuCliente = this.utilSessao.getMenuEmpresa().find((menuEmpresa) => menuEmpresa.idMenu == 5);
    this.menuColaborador = this.utilSessao.getMenuEmpresa().find((menuEmpresa) => menuEmpresa.idMenu == 56);
    this.menuCompendioEletronico = this.utilSessao.getMenuEmpresa().find((menuEmpresa) => menuEmpresa.idMenu == 141);
    this.menuConfiguracaoClienteEspecial = this.utilSessao.getMenuEmpresa().find((menuEmpresa) => menuEmpresa.idMenu == 177);
    this.menuConfiguracaoFinanceiroContabil = this.utilSessao.getMenuEmpresa().find((menuEmpresa) => menuEmpresa.idMenu == 137);
    this.menuConfiguracaoTransferenciaContabil = this.utilSessao.getMenuEmpresa().find((menuEmpresa) => menuEmpresa.idMenu == 189);
    this.menuConta = this.utilSessao.getMenuEmpresa().find((menuEmpresa) => menuEmpresa.idMenu == 29);
    this.menuContaContabil = this.utilSessao.getMenuEmpresa().find((menuEmpresa) => menuEmpresa.idMenu == 132);
    this.menuContaCorrente = this.utilSessao.getMenuEmpresa().find((menuEmpresa) => menuEmpresa.idMenu == 114);
    this.menuCustoGerencial = this.utilSessao.getMenuEmpresa().find((menuEmpresa) => menuEmpresa.idMenu == 85);
    this.menuCustoReposicao = this.utilSessao.getMenuEmpresa().find((menuEmpresa) => menuEmpresa.idMenu == 84);
    this.menuEquipamento = this.utilSessao.getMenuEmpresa().find((menuEmpresa) => menuEmpresa.idMenu == 145);
    this.menuFabricante = this.utilSessao.getMenuEmpresa().find((menuEmpresa) => menuEmpresa.idMenu == 9);
    this.menuFamilia = this.utilSessao.getMenuEmpresa().find((menuEmpresa) => menuEmpresa.idMenu == 19);
    this.menuFornecedor = this.utilSessao.getMenuEmpresa().find((menuEmpresa) => menuEmpresa.idMenu == 54);
    this.menuGrupo = this.utilSessao.getMenuEmpresa().find((menuEmpresa) => menuEmpresa.idMenu == 11);
    this.menuGrupoContabil = this.utilSessao.getMenuEmpresa().find((menuEmpresa) => menuEmpresa.idMenu == 171);
    this.menuGrupoPermisao = this.utilSessao.getMenuEmpresa().find((menuEmpresa) => menuEmpresa.idMenu == 153);
    this.menuHistorico = this.utilSessao.getMenuEmpresa().find((menuEmpresa) => menuEmpresa.idMenu == 131);
    this.menuLancamentoContabil = this.utilSessao.getMenuEmpresa().find((menuEmpresa) => menuEmpresa.idMenu == 134);
    this.menuLimiteCredito = this.utilSessao.getMenuEmpresa().find((menuEmpresa) => menuEmpresa.idMenu == 123);
    this.menuLocal = this.utilSessao.getMenuEmpresa().find((menuEmpresa) => menuEmpresa.idMenu == 120);
    this.menuNatureza = this.utilSessao.getMenuEmpresa().find((menuEmpresa) => menuEmpresa.idMenu == 31);
    this.menuNaturezaOperacao = this.utilSessao.getMenuEmpresa().find((menuEmpresa) => menuEmpresa.idMenu == 44);
    this.menuNegociacao = this.utilSessao.getMenuEmpresa().find((menuEmpresa) => menuEmpresa.idMenu == 71);
    this.menuPlanoConta = this.utilSessao.getMenuEmpresa().find((menuEmpresa) => menuEmpresa.idMenu == 132);
    this.menuPolegada = this.utilSessao.getMenuEmpresa().find((menuEmpresa) => menuEmpresa.idMenu == 169);
    this.menuPraca = this.utilSessao.getMenuEmpresa().find((menuEmpresa) => menuEmpresa.idMenu == 6);
    this.menuProduto = this.utilSessao.getMenuEmpresa().find((menuEmpresa) => menuEmpresa.idMenu == 7);
    this.menuProdutoFormulado = this.utilSessao.getMenuEmpresa().find((menuEmpresa) => menuEmpresa.idMenu == 142) ? this.utilSessao.getMenuEmpresa().find((menuEmpresa) => menuEmpresa.idMenu == 142).apelido : bibDialogo.produtoFormulado;
    this.menuProdutoInformacoesFiscais = this.utilSessao.getMenuEmpresa().find((menuEmpresa) => menuEmpresa.idMenu == 116);
    this.menuRecomendacao = this.utilSessao.getMenuEmpresa().find((menuEmpresa) => menuEmpresa.idMenu == 146);
    this.menuRamoAtividade = this.utilSessao.getMenuEmpresa().find((menuEmpresa) => menuEmpresa.idMenu == 21);
    this.menuRelacionamento = this.utilSessao.getMenuEmpresa().find((menuEmpresa) => menuEmpresa.idMenu == 122);
    this.menuRoteiroContabil = this.utilSessao.getMenuEmpresa().find((menuEmpresa) => menuEmpresa.idMenu == 133);
    this.menuTipoDeTitulo = this.utilSessao.getMenuEmpresa().find((menuEmpresa) => menuEmpresa.idMenu == 30);
    this.menuTipoLancamentoContabil = this.utilSessao.getMenuEmpresa().find((menuEmpresa) => menuEmpresa.idMenu == 135);
    this.menuTransportadora = this.utilSessao.getMenuEmpresa().find((menuEmpresa) => menuEmpresa.idMenu == 55);
    this.menuUnidade = this.utilSessao.getMenuEmpresa().find((menuEmpresa) => menuEmpresa.idMenu == 8);
    this.menuUnidadeAlternativa = this.utilSessao.getMenuEmpresa().find((menuEmpresa) => menuEmpresa.idMenu == 144) ? this.utilSessao.getMenuEmpresa().find((menuEmpresa) => menuEmpresa.idMenu == 144).apelido : bibDialogo.unidadeAlternativa;
    this.menuVinculo = this.utilSessao.getMenuEmpresa().find((menuEmpresa) => menuEmpresa.idMenu == 14);
    this.permissaoAbrirMovimentacao = this.utilSessao.getPermissoes().find((permissao) => permissao.idMenu == 48);
    this.permissaoBairro = this.utilSessao.getPermissoes().find((permissao) => permissao.idMenu == 2);
    this.permissaoCategoria = this.utilSessao.getPermissoes().find((permissao) => permissao.idMenu == 12);
    this.permissaoCategoriaRoteiroContabil = this.utilSessao.getPermissoes().find((permissao) => permissao.idMenu == 157);
    this.permissaoCentroDeResultado = this.utilSessao.getPermissoes().find((permissao) => permissao.idMenu == 32);
    this.permissaoCfopEmpresa = this.utilSessao.getPermissoes().find((permissao) => permissao.idMenu == 24);
    this.permissaoCidade = this.utilSessao.getPermissoes().find((permissao) => permissao.idMenu == 1);
    this.permissaoClassificacaoFiscal = this.utilSessao.getPermissoes().find((permissao) => permissao.idMenu == 60);
    this.permissaoCliente = this.utilSessao.getPermissoes().find((permissao) => permissao.idMenu == 5);
    this.permissaoColaborador = this.utilSessao.getPermissoes().find((permissao) => permissao.idMenu == 56);
    this.permissaoConfiguracaoFinanceiroContabil = this.utilSessao.getPermissoes().find((permissao) => permissao.idMenu == 137);
    this.permissaoConfiguracaoTransferenciaConatbil = this.utilSessao.getPermissoes().find((permissao) => permissao.idMenu == 189);
    this.permissaoConta = this.utilSessao.getPermissoes().find((permissao) => permissao.idMenu == 29);
    this.permissaoContaContabil = this.utilSessao.getPermissoes().find((permissao) => permissao.idMenu == 132);
    this.permissaoEquipamento = this.utilSessao.getPermissoes().find((permissao) => permissao.idMenu == 145);
    this.permissaoFabricante = this.utilSessao.getPermissoes().find((permissao) => permissao.idMenu == 9);
    this.permissaoFamilia = this.utilSessao.getPermissoes().find((permissao) => permissao.idMenu == 19);
    this.permissaoFornecedor = this.utilSessao.getPermissoes().find((permissao) => permissao.idMenu == 54);
    this.permissaoGrupo = this.utilSessao.getPermissoes().find((permissao) => permissao.idMenu == 11);
    this.permissaoHistorico = this.utilSessao.getPermissoes().find((permissao) => permissao.idMenu == 131);
    this.permissaoLocal = this.utilSessao.getPermissoes().find((permissao) => permissao.idMenu == 120);
    this.permissaoLimiteCredito = this.utilSessao.getPermissoes().find((permissao) => permissao.idMenu == 123);
    this.permissaoNatureza = this.utilSessao.getPermissoes().find((permissao) => permissao.idMenu == 31);
    this.permissaoNegociacao = this.utilSessao.getPermissoes().find((permissao) => permissao.idMenu == 71);
    this.permissaoPlanoConta = this.utilSessao.getPermissoes().find((permissao) => permissao.idMenu == 132);
    this.permissaoPolegada = this.utilSessao.getPermissoes().find((permissao) => permissao.idMenu == 169);
    this.permissaoProduto = this.utilSessao.getPermissoes().find((permissao) => permissao.idMenu == 7);
    this.permissaoProdutoCustoGerencial = this.utilSessao.getPermissoes().find((permissao) => permissao.idMenu == 85);
    this.permissaoProdutoCustoReposicao = this.utilSessao.getPermissoes().find((permissao) => permissao.idMenu == 84);
    this.permissaoProdutoImposto = this.utilSessao.getPermissoes().find((permissao) => permissao.idMenu == 116);
    this.permissaoProdutoValor = this.utilSessao.getPermissoes().find((permissao) => permissao.idMenu == 73);
    this.permissaoPraca = this.utilSessao.getPermissoes().find((permissao) => permissao.idMenu == 6);
    this.permissaoRecomendacao = this.utilSessao.getPermissoes().find((permissao) => permissao.idMenu == 146);
    this.permissaoRamoAtividade = this.utilSessao.getPermissoes().find((menuEmpresa) => menuEmpresa.idMenu == 21);
    this.permissaoRelacionamento = this.utilSessao.getPermissoes().find((permissao) => permissao.idMenu == 122);
    this.permissaoRoteiroContabil = this.utilSessao.getPermissoes().find((permissao) => permissao.idMenu == 133);
    this.permissaoTipoDeTitulo = this.utilSessao.getPermissoes().find((permissao) => permissao.idMenu == 30);
    this.permissaoTipoLancamentoContabil = this.utilSessao.getPermissoes().find((permissao) => permissao.idMenu == 135);
    this.permissaoTransportadora = this.utilSessao.getPermissoes().find((permissao) => permissao.idMenu == 55);
    this.permissaoUnidade = this.utilSessao.getPermissoes().find((permissao) => permissao.idMenu == 8);
    this.permissaoVinculo = this.utilSessao.getPermissoes().find((permissao) => permissao.idMenu == 14);
    this.permissaoSafra = this.utilSessao.getPermissoes().find((permissao) => permissao.idMenu == 200);
    this.permissaoLoja = this.utilSessao.getPermissoes().find((permissao) => permissao.idMenu == 70);
    this.permissaoAprovacaoDeposito = this.utilSessao.getPermissoes().find((permissao) => permissao.idMenu == 160);
  }

  ngOnInit(): void {
    this.descricaoUtilizacaoNcm = this.util.substituir(this.bibDialogo.ncmDescricao, [this.menuProduto.apelido, this.menuProdutoInformacoesFiscais.apelido]);
    this.utilNotificacao.ehModoClaroEmmiter.subscribe((modoClaro) => {
      this.ehModoClaro = modoClaro;
    });
  }

  ir(id: number, posicao: number, lista: any[]): void {
    event.preventDefault();
    let url: string = this.permissao.tela + (this.permissao.modularizado == 'S' ? '/Frm' : 'Frm');
    this.utilSessao.setIdentificacao(new Identificacao('id', id));
    this.utilSessao.setIdentificacao(new Identificacao('posicao', posicao));
    const ids: number[] = lista.map((objeto) => objeto.id);
    this.utilSessao.setIdentificacao(new Identificacao('ids', ids));
    if (this.permissao.parametro) {
      this.utilSessao.setIdentificacao(new Identificacao('parametro', this.permissao.parametro));
    }
    this.router.navigate([url]);
  }

  excluir<T>(id: number, lista: T[], nome: string, servico: string = null): Observable<any> {
    if (this.temPermissaoExcluir) {
      if (confirm(`Deseja excluir ${nome ? nome.toString().trim() : ''}?`)) {
        const transporte: Transporte = new Transporte(id);
        transporte.idMenu = this.permissao.idMenu;
        this.utilSessao.limparResultado();
        const resposta: Observable<any> = this.comunicacaoService.excluir(transporte, this.bibDialogo.backendW == 'S' ? servico : this.permissao.servico);
        resposta.subscribe((res) => {
          const somenteSucesso: boolean = Object.values(res).find((objeto) => objeto['sucesso'] == false) != null ? false : true;
          Object.values(res).map((objeto) => {
            const resultado = objeto as Resultado;
            if (somenteSucesso) {
              lista.forEach((item, i) => {
                if (lista[i]['id'] == id) {
                  lista.splice(i, 1);
                }
              });
            }
            this.utilSessao.setResultado(resultado);
          });
        });
        return resposta;
      }
      return of(lista);
    } else {
      this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.naoPossuiPermissaoParaExcluir));
      return of(lista);
    }
  }

  excluirLista(listaId: number[], servico: string): Observable<Response> {
    if (this.temPermissaoExcluir) {
      this.utilSessao.limparResultado();
      let transporte = new Transporte(listaId);
      transporte.idMenu = this.permissao.idMenu;
      const resposta: any = this.comunicacaoService.excluir(transporte, servico).pipe(share());
      resposta.subscribe((res) => {
        Object.values(res).map((objeto) => {
          const resultado: Resultado = objeto as Resultado;
          this.utilSessao.setResultado(resultado);
        });
      });
      return resposta;
    }
    this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.naoPossuiPermissaoParaExcluir));
    return null;
  }

  ehExcluir(posicao: number, lista: any[], excluir: Number[], id: number): void {
    this.utilSessao.setAlteracao(true);
    if (this.temPermissaoExcluir || id == undefined || id == null) {
      this.util.excluir(posicao, lista, excluir);
    } else {
      this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.naoPossuiPermissaoParaExcluir));
    }
  }

  persistir(transporte: Transporte, servico: string, objetoNovo: Object): Observable<Response | null> {
    transporte.idMenu = this.permissao.idMenu;
    this.utilSessao.limparResultado();
    this.somenteFalhaAprovacao = true;
    let resposta: any;
    if (this.bibDialogo.backendW == 'S') {
      resposta = this.comunicacaoService.persistir(transporte.objetos, servico).pipe(share());
    } else {
      resposta = this.comunicacaoService.persistir(transporte, servico).pipe(share());
    }
    resposta.subscribe((res) => {
      Object.values(res).map((objeto) => {
        const resultado: Resultado = objeto as Resultado;
        this.utilSessao.setResultado(resultado);

        if (resultado.descricaoNerd && (resultado.descricaoNerd.indexOf('Index: 0, Size: 0') >= 0 || resultado.descricaoNerd.indexOf('from update [0]; actual row count: 0;') >= 0)) {
          this.utilSessao.mostraId = true;
          this.utilNotificacao.setMostrarId(this.utilSessao.mostraId);
        }

        if (resultado.classe != this.bibClasse.aprovacao && !resultado.sucesso) {
          this.somenteFalhaAprovacao = false;
        }
      });
      if (this.ehValidoPersistir(res)) {
        if (this.utilSessao.getResultados()[0].id > 0 && this.bibDialogo.backendW == 'N') {
          if (transporte.transporteItens[0].lista[0] && !transporte.transporteItens[0].lista[0]['id']) {
            transporte.transporteItens[0].lista[0]['id'] = this.utilSessao.getResultados()[0].id;
          }
        }
        if (this.utilSessao.getResultados()[0].id > 0 && this.bibDialogo.backendW == 'S') {
          if (transporte.objetos[0] && !transporte.objetos[0]['id']) {
            transporte.objetos[0]['id'] = this.utilSessao.getResultados()[0].id;
          }
        }
        if (objetoNovo) {
          this.reiniciarConteudo(transporte.transporteItens[0].lista[0], objetoNovo);
        }
      }
    });
    if (this.utilSessao.modal == true) {
      this.utilSessao.setAlteracao(true);
    } else {
      this.utilSessao.setAlteracao(false);
    }
    return resposta;
  }

  ehValidoPersistir(res: any): boolean {
    const ehValidoSemResultado: boolean = this.ehValidoSemResultado(res);
    const ehValidoFalha: boolean = this.ehValidoFalha();
    return ehValidoSemResultado && ehValidoFalha;
  }

  ehValidoSemResultado(res: any): boolean {
    if (res.length == 0) {
      const resultado: Resultado = new Resultado(false, this.bibDialogo.semResultadoServidor);
      this.utilSessao.setResultado(resultado);
      return false;
    }
    return true;
  }

  ehValidoFalha(): boolean {
    return !this.utilSessao.falha || this.somenteFalhaAprovacao;
  }

  reiniciarConteudo(objetoAtual: any, objetoNovo: any): void {
    window.scrollTo(0, 0);
    for (const campo of Object.keys(objetoAtual)) {
      objetoAtual[campo] = objetoNovo[campo];
    }
    this.utilNotificacao.limpaBuscaEmt.emit(true);
  }

  ehAtualizacao(objeto: any): Object {
    let identificacao: Identificacao = this.utilSessao.getIdentificacao('objeto');
    if (identificacao) {
      if (identificacao.conteudo != null) {
        if (typeof identificacao.conteudo == 'number') {
          objeto['id'] = identificacao.conteudo;
        } else {
          const objetoSessao: any = identificacao.conteudo;
          if (objetoSessao.id && !objetoSessao.alterado) {
            objeto['id'] = objetoSessao.id;
          } else {
            return objetoSessao;
          }
        }
      }
      return objeto;
    }
    identificacao = this.utilSessao.getIdentificacao('id');
    if (identificacao) {
      if (identificacao.conteudo) {
        objeto['id'] = identificacao.conteudo;
      }
    }
    return objeto;
  }

  adicionarTemporario(objeto: any, lista: any[]) {
    this.utilSessao.setAlteracao(true);
    if (objeto.id) {
      for (let i = 0; i < lista.length; i++) {
        if (lista[i]['id'] == objeto.id) {
          lista[i] = objeto;
          return;
        }
      }
    } else {
      if (objeto.idTemporario) {
        for (let i = 0; i < lista.length; i++) {
          if (lista[i]['idTemporario'] == objeto.idTemporario) {
            lista[i] = objeto;
            return;
          }
        }
      }
    }
    objeto.idTemporario = Math.random();
    lista.push(objeto);
  }

  definirId(lista: any[], classeNome: string, atulizaMesmoComID: boolean = false): void {
    if (!this.utilSessao.falha || this.somenteFalhaAprovacao) {
      if (lista && lista.length > 0) {
        const resultados: Resultado[] = this.utilSessao
          .getResultados()
          .filter((resultado) => resultado.classe != null)
          .filter((resultado) => resultado.classe.toLocaleLowerCase() == classeNome.toLocaleLowerCase() && resultado.operacao == 'I');
        if (resultados.length > 0) {
          if (atulizaMesmoComID == false) {
            lista = lista.filter((lista) => !lista.id);
          }
          for (let i = 0; i < lista.length; i++) {
            lista[i]['id'] = resultados[i].id;
          }
        }
      }
    }
  }

  add(campo: string): Criterio[] {
    let criterios: Criterio[] = this.utilSessao.criterios ? this.utilSessao.criterios : [];
    if (criterios.length == 0) {
      const novoCriterio: Criterio = new Criterio('ID', 0);
      criterios.push(novoCriterio);
    }
    const novoCriterio: Criterio = new Criterio('ORDEM', campo);
    let ordemCriterio: Criterio = criterios.find((criterio) => criterio.nome == novoCriterio.nome);
    if (ordemCriterio) {
      if (ordemCriterio.valor == novoCriterio.valor + ' asc') {
        ordemCriterio.valor = novoCriterio.valor + ' desc';
      } else {
        ordemCriterio.valor = novoCriterio.valor + ' asc';
      }
    } else {
      novoCriterio.valor += ' asc';
      criterios.push(novoCriterio);
    }
    return criterios;
  }

  bloquearPermissao(id: number): boolean {
    return ((this.temPermissaoEdicao && id) || (this.temPermissaoInserir && !id)) ? false : true;
  }
}
