import { EventEmitter, Injectable } from '@angular/core';
import { Modulo } from 'src/app/modelo/modulo';
import { ModuloEmpresa } from 'src/app/modelo/moduloEmpresa';
import { Permissao } from 'src/app/modelo/permissao';
import { Usuario } from 'src/app/modelo/usuario';
import { UtilSessao } from '../../utilitario/util.sessao';

@Injectable()
export class LoginEmitter {
  constructor(private utilSessao: UtilSessao) { }
  public acessoSemLoginEmitter = new EventEmitter<boolean>();
  public idEmitter = new EventEmitter<number>();
  public menuEmitter = new EventEmitter();
  public modularizarEmitter = new EventEmitter();
  public modularizarEmpresaEmitter = new EventEmitter();
  public permitirEmitter = new EventEmitter();
  public relatoriosCategoriaEmitter = new EventEmitter();
  public relatoriosEmitter = new EventEmitter();
  public saindo = new EventEmitter<boolean>();
  public usuarioEmitter = new EventEmitter<Usuario>();
  public loginCompletoEmitter = new EventEmitter<boolean>();

  sair(situacao: boolean): void {
    this.saindo.emit(situacao);
  }

  definirIdUsuario(idUsuario: number): void {
    this.idEmitter.emit(idUsuario);
  }

  permitir(permissoes: Permissao[]): void {
    this.utilSessao.setPermissoes(permissoes);
    this.permitirEmitter.emit(permissoes);
  }

  modularizar(modulos: Modulo[]): void {
    this.utilSessao.setModulos(modulos);
    this.modularizarEmitter.emit(modulos);
  }

  modularizarEmpresa(modulosEmpresa: ModuloEmpresa[]): void {
    this.utilSessao.setModulosEmpresa(modulosEmpresa);
    this.modularizarEmpresaEmitter.emit(modulosEmpresa);
  }

  logar(usuario: Usuario, emitir: Boolean): void {
    if (usuario) {
      usuario.usuarioContas = [];
      usuario.usuarioLojas = [];
      usuario.usuarioMenus = [];
    }
    this.utilSessao.setUsuario(usuario);
    if (emitir) {
      this.usuarioEmitter.emit(usuario);
    }
  }

  reiniciar(utilSessao: UtilSessao): void {
    this.sair(true);
    utilSessao.reiniciar();
    this.permitir(null);
    this.modularizar(null);
    this.logar(null, true);
    sessionStorage.removeItem('entregaCriterios');
    sessionStorage.removeItem('entregaMovimentacoes');
    sessionStorage.removeItem('entregaId');
  }
}
