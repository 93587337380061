<div class="page-section">
  <div class="container" [ngClass]="{ tituloFixo: frm && modal == false, 'titulo-fixo-escuro': !ehModoClaro }">
    <nav *ngIf="frm && !modal && permissao" aria-label="breadcrumb" class="naoImprimir">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a (click)="frm ? fecharFrm() : ''" href="#" tabindex="-1"> <i class="breadcrumb-icon fa fa-angle-left mr-2"></i>{{ bibDialogo.ondeVoceEsta + ': ' + permissao.menu }} </a>
        </li>
        <li class="breadcrumb-item active">{{ id ? bibDialogo.edicao : bibDialogo.insercao }}</li>
      </ol>
    </nav>
    <div *ngIf="this.utilSessao.getAlteracao() && frm && this.utilSessao.getMostrarAtencaoSalvar() && !modal" class="divSeta naoImprimir">
      <img class="imagemSeta" [src]="'img/seta.png'" height="40px" width="54px" />
      <div class="atencaoSalvar piscar">
        <atencao [atencao]="bibDialogo.aguardandoSalvar" [tipo]="bibPropriedade.atencao.alerta"></atencao>
      </div>
    </div>
    <div class="row justify-content-between">
      <div class="col-sm">
        <div class="row align-items-center" [ngClass]="_relatorio ? 'naoImprimir' : ''">
          <a href="#" (click)="copiar(id)"><atencao *ngIf="id && mostraId && !_relatorio" class="naoImprimir mb-2 pb-2'" [atencao]="id ? id : ''" [ajuda]="bibDialogo.cliqueAquiParaCopiarId"></atencao></a>
          <h4 id="titulo-layout" *ngIf="titulo != '' && !editaTituloRelatorio && telaInterna == false">{{ this.titulo ? this.titulo.toUpperCase() : this.permissao ? this.permissao.menu : '' }}</h4>
          <icone *ngIf="_relatorio && _relatorio.id && !editaTituloRelatorio && _relatorio.layoutEspecifico == 'N'" class="ml-1" [icone]="bibIcone.editar" (click)="editaTituloRelatorio = true"></icone>
          <texto *ngIf="_relatorio && _relatorio.id && editaTituloRelatorio" [prefixo]="this.titulo" (alteracao)="relatorioTituloAdicional.emit($event)" [foco]="editaTituloRelatorio"></texto>
          <atencao *ngIf="!frm && !modal && !_relatorio" class="naoImprimir" [atencao]="tag ? tag : permissao ? this.permissao.tag : null"></atencao>
          <btnAdicional *ngIf="!frm && !modal && !_relatorio && permissao && permissao.descricao" class="ml-2 naoImprimir" [icone]="bibIcone.infoCirculo" (btnAdicional)="apresentaDescricao = $event ? (apresentaDescricao == true ? false : true) : apresentaDescricao" [ajuda]="bibDialogo.descricao"></btnAdicional>
        </div>
      </div>
      <div class="row alinha-titulo" [ngClass]="{ 'titulo-modo-claro': ehModoClaro, 'titulo-modo-escuro': !ehModoClaro, 'alinha-titulo-padrao': !_relatorio }">
        <utilitario class="rm-12" *ngIf="!modal && !_relatorio && frm && this.utilSessao.getIdentificacao('ids')?.conteudo.length > 1" [utilitario]="utilitarioVoltar" (utilitarioEmt)="voltarAvancar(true)"></utilitario>
        <utilitario class="rm-12" *ngIf="!modal && !_relatorio && frm && this.utilSessao.getIdentificacao('ids')?.conteudo.length > 1" [utilitario]="utilitarioAvancar" (utilitarioEmt)="voltarAvancar(false)"></utilitario>
        <ng-container *ngIf="_idRelatorioInterno == null && (permissao || _relatorio)">
          <div class="row" *ngIf="!modal">
            <ng-container *ngIf="((frm && id) || (!frm && chave)) && mostraRelatorio">
              <ng-container *ngFor="let utilitarioRelatorioMenu of utilitarioRelatorioMenus">
                <utilitario [utilitario]="utilitarioRelatorioMenu" (utilitarioEmt)="irRelatorio(utilitarioRelatorioMenu.legenda)"></utilitario>
              </ng-container>
            </ng-container>
            <ng-container><utilitario *ngFor="let utilitario of utilitarios" [utilitario]="utilitario.visivel ? utilitario : null" (utilitarioEmt)="notificarUtilitario($event)" [atencao]="utilitario.atencao"></utilitario></ng-container>
            <utilitario [utilitario]="utilitarioFavorito" (utilitarioEmt)="permissao ? favoritarTela() : _relatorio ? favoritarRelatorio() : null" [cor]="(permissao && permissao.favorito == 'S') || (_relatorio && _relatorio.favorito == 'S') ? 'amarelo' : ''"></utilitario>
            <utilitario *ngIf="(!frm && permissao && permissao.filtro != '-') || (_relatorio && _relatorio.usaFiltro)" [utilitario]="utilitarioFiltro" (utilitarioEmt)="abrirFiltro()"></utilitario>
            <utilitario *ngIf="(apresentaImpressao && !frm) || (!frm && permissao && permissao.imprimir == 'S')" [utilitario]="utilitarioImprimir" (utilitarioEmt)="imprimir()"></utilitario>
          </div>
        </ng-container>
        <utilitario class="rm-12" *ngIf="_idRelatorioInterno == null && !modal" [utilitario]="utilitarioFechar" (utilitarioEmt)="ehFechar()"></utilitario>
      </div>
    </div>
    <div *ngIf="utilSessao.alertaAlteracao" class="alert alert-info alert-dismissible fade show" role="alert">
      {{ bibDialogo.efetueLoginNovamenteParaUtilizarAlteracoes + '. ' }} <a  class="clique" (click)="sair()"><b>{{ bibDialogo.cliqueAqui }}</b></a> {{" " + bibDialogo.para.toLowerCase() + " " + bibDialogo.sair.toLowerCase() }}
      <button type="button" class="close" data-dismiss="alert" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>
  <div *ngIf="apresentaDescricao" class="alert alert-info alert-dismissible fade show" role="alert">
    <strong>{{ bibDialogo.descricao + ': ' }}</strong> {{ permissao.descricao + '.' }}
    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <section [ngClass]="{ apoioTituloFixo: frm && modal == false, 'card card-fluid': !telaInterna }">
    <div *ngIf="subTitulo && telaInterna == false" class="sub-titulo" [ngClass]="{ 'sub-titulo-modo-escuro': !ehModoClaro }">
      <h3 class="p-2 m-0" id="titulo-sub-titulo">{{ subTitulo }}</h3>
    </div>
    <div class="card-body" [ngClass]="_relatorio ? 'p-0' : null">
      <bloqueado *ngIf="frm && id" [bloqueado]="edicao" [bloqueiaTela]="edicao && !menuCompendio"></bloqueado>
      <ng-content></ng-content>
    </div>
  </section>
</div>
<div *ngIf="!frm && ((permissao && permissao.inserir == 'S' && permissao.insercao == 'S') || podeAdicionarLoja)" class="naoImprimir">
  <button id="inserir" type="button" (click)="irFormularioNovo()" class="posicao btn btn-success btn-floated">
    <span [class]="bibIcone.mais"></span>
  </button>
</div>
<div *ngIf="utilSessao.processando > 0">
  <div class="divAguarde align-items-center">
    <div class="loader loader-lg"></div>
    <div *ngIf="utilSessao.mensagemAguardando || utilSessao.mensagemNFe != null">
      <div>
        <span class="badge compacto badge-light" [ngClass]="utilSessao.mensagemDesistirEspera ? 'margem-mensagem' : 'margem-mensagem-loader'">{{ utilSessao.mensagemDesistirEspera ? bibDialogo.mensagemAguardarEspera : utilSessao.mensagemNFe ? utilSessao.mensagemNFe : bibDialogo.aguardeUmMomento }}</span>
      </div>
      <div>
        <span *ngIf="utilSessao.mensagemDesistirEspera" class="badge compacto badge-light" [ngClass]="utilSessao.mensagemDesistirEspera ? 'margem-mensagem-espera-loader' : 'margem-mensagem-loader'">{{ bibDialogo.mensagemAguardarEsperaDesistir }}</span>
        <span *ngIf="utilSessao.mensagemDesistirEspera" class="clique badge compacto badge-success ml-1" (click)="utilSessao.finalizarProcesso()">{{ bibDialogo.sim }}</span>
        <span *ngIf="utilSessao.mensagemDesistirEspera" class="clique badge compacto badge-danger ml-1" (click)="utilSessao.mensagemDesistirEspera = false">{{ bibDialogo.nao }}</span>
      </div>
    </div>
  </div>
  <div class="divAguardeFundo"></div>
</div>
<div *ngIf="utilSessao.getResultados().length > 0 && !utilSessao.falha && !modal && !utilSessao.informacaoAlert" class="alert mb-5 frenteTudo naoImprimir alert-success" role="alert">
  <button type="button" tabindex="-1" class="close" data-dismiss="alert" aria-label="Close" (click)="utilSessao.limparResultado()"><span aria-hidden="true">&times;</span></button>
  <h4 class="alert-heading">Sucesso!</h4>
</div>
<div *ngIf="utilSessao.getResultados().length > 0 && (utilSessao.falha || utilSessao.informacaoAlert) && !modal" class="alert naoImprimir" [ngClass]="[utilSessao.informacaoAlert ? 'alert-primary' : 'alert-danger']" role="alert">
  <div class="container frenteTudo">
    <div class="row justify-content-between">
      <h4 class="alert-heading">{{ bibDialogo.atencao }}</h4>
      <utilitario [id]="'fechar'" class="utilitario-fechar" [utilitario]="utilitarioFechar" (utilitarioEmt)="fechar()"></utilitario>
    </div>
  </div>
  <ng-container *ngFor="let resultado of utilSessao.getResultados()">
    <div *ngIf="resultado.sucesso != true && resultado.mensagem != null && resultado.mensagem != ''" class="resultado frenteTudo p-1 mb-1 rounded">
      <div class="row texto-negrito">
        <div class="col-sm-11">
          <ng-container *ngFor="let linha of resultado.mensagem ? resultado.mensagem.split('\n') : resultado.mensagem">{{ linha }}<br /></ng-container>
        </div>
        <div class="col-sm-1 justify-content-center">
          <ajuda class="mr-2" *ngIf="resultado.descricaoNerd" [ajuda]="resultado.descricaoNerd" (click)="copiar(resultado.descricaoNerd)"></ajuda>
        </div>
      </div>
    </div>
  </ng-container>
</div>
<div *ngIf="utilSessao.getUsuario()?.agrow == 'S' && !utilSessao.getIdentificacao('modalAberto')?.conteudo == true" role="button" class="p-2 direita bg-black-50 naoImprimir" style="z-index: 5000">
  <div class="naoImprimir">{{ utilSessao.getEmpresa().id + ' - ' + utilSessao.getEmpresa().nome }}</div>
</div>
<div *ngIf="utilSessao.getResultados().length > 0 && utilSessao.falha && !modal" class="sombra naoImprimir"></div>
<modal *ngIf="componente" [componente]="componente" (modalEmt)="componente = null"></modal>
<div class="fixed-bottom margem ml-3 tamanho naoImprimir" *ngIf="utilSessao.getPermissao() && utilSessao.sistema && utilSessao.getPermissao().menuLink && utilSessao.sistema == 'aGrow-w'">
  <a class="clique" (click)="abrirModalYoutube()">
    <i placement="top-left float left'" ngbTooltip="{{ bibDialogo.treinamento }}">
      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="black" viewBox="0 0 16 16">
          <path d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.01 2.01 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.01 2.01 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31 31 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.01 2.01 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A100 100 0 0 1 7.858 2zM6.4 5.209v4.818l4.157-2.408z"/>
      </svg>
    </i>    
  </a>
</div>
<whatsapp [nomeMenu]="this.titulo ? this.titulo.toUpperCase() : this.permissao ? this.permissao.menu : ''" [id]="id"></whatsapp>