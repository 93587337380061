import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { Criterio } from 'src/app/modelo/criterio';
import { Transporte } from 'src/app/modelo/transporte';
import { RelatorioComponent } from '../relatorio.component';
import filtros from './acessorio/filtro';

@Component({
  selector: 'r2023',
  templateUrl: './r2023.component.html',
  styleUrls: ['./r2023.component.css'],
})
export class R2023Component extends RelatorioComponent {
  public filtros = filtros;
  public contaContabeis: any[] = [];
  public contaContabeisSaldoAnteriores: any[] = [];
  public lancamentoContabeis: any[] = [];

  listarRelatorio(criterios: Criterio[]): void {
    super.listar(criterios).subscribe(() => {
      this.contaContabeis = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'ContaContabil').lista;
      this.contaContabeisSaldoAnteriores = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'ContaContabilSaldoAnterior').lista;
      this.lancamentoContabeis = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'LancamentoContabil').lista;
      this.agruparLancamentoContabil();
    });
  }

  agruparLancamentoContabil(): void {
    for (const contaContabil of this.contaContabeis) {
      const contaContabilSaldoAnterior: any = this.contaContabeisSaldoAnteriores.find((contaContabilSaldoAnterior) => contaContabilSaldoAnterior.id == contaContabil.id);
      contaContabil.saldoInicial = contaContabil.saldoInicial + (contaContabilSaldoAnterior ? contaContabilSaldoAnterior.saldoAnterior : 0);
      contaContabil.lancamentoContabeis = this.lancamentoContabeis.filter((lancamentoContabil) => lancamentoContabil.idContaContabil == contaContabil.id);
      this.atualizarLancamentoContabil(contaContabil);
    };
  }

  atualizarLancamentoContabil(contaContabil: any): void {
    console.table(contaContabil);
    let saldo: number = contaContabil.saldoInicial;
    if (contaContabil.lancamentoContabeis) {
      for (const lancamentoContabil of contaContabil.lancamentoContabeis) {
        const contaAtivo: boolean = this.ehContaAtivo(lancamentoContabil.numeracao);
        lancamentoContabil.saldo = saldo;
        lancamentoContabil.saldo += lancamentoContabil.debito ? lancamentoContabil.debito * (contaAtivo ? 1 : -1) : 0;
        lancamentoContabil.saldo -= lancamentoContabil.credito ? lancamentoContabil.credito * (contaAtivo ? 1 : -1) : 0;
        saldo = lancamentoContabil.saldo;
        this.atualizarHistorico(lancamentoContabil);
      };
    }
  }

  atualizarHistorico(lancamentoContabil: any) {
    this.substituirHistorico(lancamentoContabil, '01/01');
    this.substituirHistorico(lancamentoContabil, '1/1');
    this.substituirHistorico(lancamentoContabil, '1x');
    this.substituirHistorico(lancamentoContabil, ',,');
    this.substituirHistorico(lancamentoContabil, ' , ', ' ');
    this.substituirHistorico(lancamentoContabil, '  ', ' ');
  }

  substituirHistorico(lancamentoContabil: any, de: string, para: string = '') {
    while (lancamentoContabil.historico.indexOf(de) >= 0) {
      lancamentoContabil.historico = lancamentoContabil.historico.replace(de, para);
    }
  }

  ehContaAtivo(numeracao: string): boolean {
    return numeracao.substring(0, 1) == '1' || numeracao.substring(0, 1) == '3' ? true : false;
  }
}
