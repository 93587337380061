import { Component, HostListener } from '@angular/core';
import { FiltroComponent } from 'src/app/essencial/filtro/filtro.component';
import { Criterio } from 'src/app/modelo/criterio';
import { Filtro } from 'src/app/modelo/filtro';
import { Identificacao } from 'src/app/modelo/identificacao';
import { IntegracaoUsuario } from 'src/app/modelo/integracaoUsuario';
import { Transporte } from 'src/app/modelo/transporte';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';
import filtros from './filtros/filtro';

@Component({
  templateUrl: './integracaoLista.component.html',
  styleUrls: ['./integracaoLista.component.css'],
})
export class IntegracaoListaComponent extends PaginaComponent {
  public campoPesquisa: string = this.bibDialogo.nome;
  public componente: any;
  public filtro: string = '';
  public filtros: Filtro[];
  public focado: boolean;
  public indice: number = 0;
  public integracoes: IntegracaoUsuario[] = [];
  public totalIntegracoes: IntegracaoUsuario[] = [];

  public lista: any[] = [
    { id: this.bibDialogo.categoria, nome: this.bibDialogo.categoria },
    { id: this.bibDialogo.nome, nome: this.bibDialogo.nome },
  ];

  @HostListener('window:keydown', ['$event'])
  keyboardInput(event: KeyboardEvent) {
    if (this.focado) {
      switch (event.keyCode) {
        case this.bibAtalho.setaCima:
          event.preventDefault();
          this.indice--;
          if (this.indice < 0) this.indice = 0;
          break;
        case this.bibAtalho.setaBaixo:
          event.preventDefault();
          this.indice++;
          if (this.indice >= this.integracoes.length) this.indice = this.integracoes.length - 1;
          break;
        case this.bibAtalho.enter:
          event.preventDefault();
          this.irIntegracao();
          break;
      }
    }
  }

  ngOnInit(): void {
    this.utilSessao.setPermissao(null);
    this.integracoes = this.utilSessao.getUsuarioIntegracoes();
    this.titleService.setTitle(this.bibDialogo.integracao);
    this.ordenarIntegracoes();
    this.totalIntegracoes = this.integracoes;
    this.modalNotificacao.modalEmt.subscribe((identificacao) => {
      if (identificacao != null && identificacao.nome == this.bibClasse.criterio) {
        let criterios: Criterio[] = this.plainToClass(Criterio, identificacao.conteudo) as any;
        criterios = criterios.filter((criterio) => criterio.idIntegracao);
        if (criterios.length > 0) {
          this.listarIntegracao(criterios);
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.utilSessao.excluirIdentificacao('idIntegracao');
  }

  ordenarIntegracoes(): void {
    this.integracoes.sort((a, b) => {
      return a['integracaoCategoria'] === b['integracaoCategoria'] ? 0 : a['integracaoCategoria'] > b['integracaoCategoria'] ? 1 : -1;
    });
  }

  irIntegracao(): void {
    if (this.indice > -1) {
      const integracaoId: number = this.getIdIntegracao();
      switch (integracaoId) {
        case 1:
          this.filtros = filtros.spedFiscal;
          break;
        case 2:
          this.filtros = filtros.spedContribuicao;
          break;
        case 3:
          this.filtros = filtros.etiqueta;
          break;
        case 4:
          this.filtros = filtros.resultadoConsultor;
          break;
        case 5:
          this.filtros = filtros.estoque;
          break;
        case 6:
          this.filtros = filtros.neogrid;
          break;
        case 7:
          this.filtros = filtros.sicca;
          break;
        case 8:
          this.filtros = filtros.dominio;
          break;
        case 9:
          this.filtros = filtros.creaSp;
          break;
      }
      this.abrirFiltro(integracaoId);
    }
  }

  abrirFiltro(idIntegracao: number): void {
    this.utilSessao.limparResultado();
    this.filtros.forEach((filtro) => {
      filtro.idIntegracao = idIntegracao;
    });
    this.utilSessao.setIdentificacao(new Identificacao('idIntegracao', idIntegracao));
    this.setFiltroSessao(this.filtros, idIntegracao);
    this.componente = FiltroComponent;
  }

  setFiltroSessao(filtros: Filtro[], idIntegracao: number): void {
    if (filtros && idIntegracao) {
      const filtros: Filtro[] = this.utilSessao.getFiltroIntegracao(idIntegracao);
      if (filtros.length == 0) {
        this.utilSessao.setFiltro(this.filtros);
      }
    }
  }

  getIdIntegracao(): number {
    if (this.filtro != '' && this.filtro != null) {
      let integracoes: IntegracaoUsuario[] = [];
      this.filtro = this.filtro.toLowerCase();
      switch (this.campoPesquisa) {
        case this.bibDialogo.categoria:
          integracoes = this.integracoes.filter((integracao) => integracao.integracaoCategoria.toLowerCase().includes(this.filtro));
          return integracoes[this.indice].idIntegracao ? integracoes[this.indice].idIntegracao : null;
        case this.bibDialogo.nome:
          integracoes = this.integracoes.filter((integracao) => (integracao.id + integracao.integracao).toLowerCase().includes(this.filtro));
          return integracoes[this.indice].idIntegracao ? integracoes[this.indice].idIntegracao : null;
      }
    } else {
      return this.integracoes[this.indice].idIntegracao ? this.integracoes[this.indice].idIntegracao : null;
    }
  }

  setFiltro(filtro: string): void {
    this.filtro = filtro;
    if (this.filtro != null) {
      if (this.campoPesquisa == this.bibDialogo.categoria) {
        this.totalIntegracoes = this.integracoes.filter((integracao) => integracao.integracaoCategoria.toLowerCase().includes(this.filtro.toLowerCase()));
      } else {
        this.totalIntegracoes = this.integracoes.filter((integracao) => (integracao.id + integracao.integracao).toLowerCase().includes(this.filtro.toLowerCase()));
      }
    } else {
      this.totalIntegracoes = this.integracoes;
    }
  }

  listarIntegracao(criterios: Criterio[]): void {
    criterios.push(new Criterio('ID_INTEGRACAO', this.getIdIntegracao()));
    //const file-save: string = this.criarNomeArquivo(criterios);
    this.comunicacaoService.listar(new Transporte(criterios), this.bibServico.integracaoResultado, false, true, true, true, 1000000, false).subscribe((res) => {
      res.forEach(documento => {
        if (documento?.arquivo != undefined) {
          this.downloadArquivo(documento.dado, 'text/plain;charset=utf-8', documento.extensao, this.criarNomeArquivo(documento.arquivo, criterios));
        }
      });
    });
  }

  criarNomeArquivo(arquivo: string, criterios: Criterio[]): string {
    let dataDe: string = null;
    let dataAte: string = null;
    let idLoja: number = null;
    let loja: string = null;
    if ([1, 2].includes(this.getIdIntegracao())) {
      dataDe = criterios.find((criterio) => criterio.nome == 'DATA_DE').valor;
      dataAte = criterios.find((criterio) => criterio.nome == 'DATA_ATE').valor;
      idLoja = criterios.find((criterio) => criterio.nome == 'ID_LOJA').valor;
      loja = this.utilSessao.getLojas().find((loja) => loja.id == idLoja).abreviacao;
    }

    switch (this.getIdIntegracao()) {
      case 1:
        return 'SPED_F_' + loja + '_' + new Date(dataDe).toLocaleDateString().replace("'", '').replace('/', '_') + '_' + new Date(dataAte).toLocaleDateString().replace("'", '').replace('/', '_');
      case 2:
        return 'SPED_C_' + loja + '_' + new Date(dataAte).toLocaleDateString().replace("'", '').replace('/', '_') + '_' + new Date(dataAte).toLocaleDateString().replace("'", '').replace('/', '_');
      default:
        return arquivo;
    }
  }

  downloadArquivo(dados: string, tipo: string, extensao: string, nomeArquivo: string): void {
    const arquivo: Blob = new Blob([dados], { type: tipo });
    const elemento: any = window.document.createElement('a');
    elemento.href = window.URL.createObjectURL(arquivo);
    elemento.download = `${nomeArquivo}.${extensao}`;
    document.body.appendChild(elemento);
    elemento.click();
    document.body.removeChild(elemento);
  }
}
