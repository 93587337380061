import { plainToClass } from 'class-transformer';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import bibPropriedade from 'src/app/biblioteca/bibPropriedade';
import bibServico from 'src/app/biblioteca/bibServico';
import { Filtro } from 'src/app/modelo/filtro';
import { FiltroCombo } from 'src/app/modelo/filtroCombo';

const filtroComboEntradaSaida: FiltroCombo[] = plainToClass(FiltroCombo, [
  { nome: 'ENTRADA', id: 'E' },
  { nome: 'SAÍDA', id: 'S' },
]);

const filtros: Filtro[] = plainToClass(Filtro, [
  { rotulo: bibDialogo.dataDe, nome: 'PERIODO_DE', coluna: 6, tipo: bibPropriedade.filtro.data, obrigatorio: 'S' },
  { rotulo: bibDialogo.dataAte, nome: 'PERIODO_ATE', coluna: 6, tipo: bibPropriedade.filtro.data, obrigatorio: 'S' },
  { rotulo: bibDialogo.clienteFornecedor, nome: 'ID_PARCEIRO', coluna: 12, servicoWeb: bibServico.parceiro, tipo: bibPropriedade.filtro.busca },
  { rotulo: bibDialogo.vendedor, nome: 'ID_COLABORADOR', coluna: 6, servicoWeb: bibServico.colaborador, tipo: bibPropriedade.filtro.busca },
  { rotulo: bibDialogo.cfop, nome: 'CFOP', coluna: 6, servicoWeb: bibServico.cfopEmpresa, tipo: bibPropriedade.filtro.checklista },
  { rotulo: bibDialogo.entradaSaida, nome: 'ENTRADA_SAIDA', coluna: 6, tipo: bibPropriedade.filtro.combo, lista: filtroComboEntradaSaida },
  { rotulo: bibDialogo.naturezaOperacao, nome: 'IDS_NATUREZA_OPERACAO', coluna: 6, servicoWeb: bibServico.naturezaOperacao, tipo: bibPropriedade.filtro.checklista },
  { rotulo: bibDialogo.loja, nome: 'IDS_LOJA', coluna: 6, servicoWeb: bibServico.loja, tipo: bibPropriedade.filtro.checklista },

]);
export default filtros;
