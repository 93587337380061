<table *ngIf="((i + 1 == 1 || (i + 1) % quantidadProduto == 0) && i + 1 != contagem) || contagem == 1">
  <tbody>
    <tr *ngIf="((i + 1 == 1 || (i + 1) % quantidadProduto == 0) && i + 1 != contagem) || contagem == 1" class="top">
      <td class="r-8-5 relatorio-titulo">
        <div class="row">
          <span class="r-3 col-sm text-left">{{ movimentacao.operacao }}</span> <span class="r-5 text-right mr-2">{{ mostraVia == true ? movimentacao.via : '' }}</span>
          <div *ngIf="movimentacao.status != 'S'" class="tarja-nao-finalizado">{{ bibDialogo.naoFinalizado }}</div>
        </div>
      </td>
      <td class="r-3-5 align-items-center">
        <div class="row">
          <span class="r-1 text-right relatorio-rotulo">{{ bibDialogo.numero + ': ' }} </span> <span class="r-2-5">{{ movimentacao.numero }} </span>
        </div>
        <div class="row">
          <span class="r-1 text-right relatorio-rotulo">{{ bibDialogo.emissao + ': ' }} </span> <span class="r-2-5">{{ movimentacao.dataHoraAlteracao | data: 'dd/MM/yyyy HH:mm:ss' }} </span>
        </div>
      </td>
    </tr>
    <tr>
      <td>
        <div class="row d-flex flex-row align-items-center justify-content-center">
          <div *ngIf="usaLogomarca" class="col-sm r-1">
            <img [src]="foto ? foto : imagemEmpresa" class="p-1" [alt]="empresa.nome" height="80" width="80" style="object-fit: contain" />
          </div>
          <div class="col-sm">
            <div class="p-0 ml-3">     
              <div class="row">
                <span class="r-1 text-right relatorio-rotulo">{{ bibDialogo.loja + ': ' }} </span><span class="r-6 limitar"> {{ movimentacao.loja }}</span>
              </div> 
              <div class="row">
                <span class="r-1 text-right relatorio-rotulo">{{ bibDialogo.endereco + ': ' }} </span>
                <div class="alinhar-vertical r-6">
                  <span class="fonte-endereco"> {{ movimentacao.lojaEndereco }} </span>
                </div>
              </div>   
              <div class="row">
                <span class="r-1 text-right relatorio-rotulo">{{ bibDialogo.email + ': ' }} </span>
                <div class="alinhar-vertical r-6 limitar">
                  <span class="fonte-endereco"> {{ movimentacao.lojaEmail }} </span>
                </div>
              </div>                       
              <div class="row">
                <ng-container *ngIf="movimentacao.identificacao == 1 || (movimentacao.identificacao == 2 && movimentacao.entradaSaidaInterna == -1) || (movimentacao.identificacao == 4 && movimentacao.entradaSaidaInterna == -1) || (movimentacao.identificacao == 3 && movimentacao.entradaSaidaInterna == -1)"><span class="r-1 text-right relatorio-rotulo">{{ bibDialogo.vendedor + ': ' }} </span><span class="r-4-5 limitar"> {{ movimentacao.colaborador }}</span></ng-container>
                <ng-container *ngIf="(movimentacao.identificacao == 2 && movimentacao.entradaSaidaInterna == 1) || (movimentacao.identificacao == 4 && movimentacao.entradaSaidaInterna == 1) || (movimentacao.identificacao == 3 && movimentacao.entradaSaidaInterna == 1)"><span class="r-1 text-right relatorio-rotulo">{{ bibDialogo.comprador + ': ' }} </span><span class="r-4-5 limitar"> {{ movimentacao.colaborador }}</span></ng-container>
                <ng-container *ngIf="movimentacao.identificacao == 6 || movimentacao.identificacao == 7 || movimentacao.identificacao == 5 || movimentacao.identificacao == 8"><span class="r-1 text-right relatorio-rotulo">{{ bibDialogo.responsavel + ': ' }} </span><span class="r-4-5 limitar"> {{ movimentacao.colaborador }}</span></ng-container>
              </div>
            </div>
          </div>
        </div>                           
      </td>
      <td>
        <div class="row">
          <span class="r-1 text-right relatorio-rotulo">{{ bibDialogo.telefone + ': ' }} </span><span class="r-2-5 limitar"> {{ movimentacao.lojaTelefone }}</span>
        </div> 
        <div class="row">
          <span class="r-1 text-right relatorio-rotulo">{{ bibDialogo.cnpj + ': ' }} </span><span class="r-2-5 limitar"> {{ movimentacao.lojaCnpj }}</span>
        </div>     
        <div class="row">
          <span class="r-1 text-right relatorio-rotulo">{{ bibDialogo.inscricao + ': ' }} </span><span class="r-2-5 limitar"> {{ movimentacao.lojaInscricao }}</span>
        </div>                 
        <div class="row">
          <span class="r-1 text-right relatorio-rotulo">{{ bibDialogo.contato + ': ' }} </span><span class="r-2-5 limitar"> {{ movimentacao.colaboradorTelefone }}</span>
        </div>                              
      </td>         
    </tr>
    <tr *ngIf="((i + 1 == 1 || (i + 1) % quantidadProduto == 0) && i + 1 != contagem) || contagem == 1">
      <td colspan="2">
        <div class="row">
          <span *ngIf="movimentacao.identificacao == 1 || (movimentacao.identificacao == 4 && movimentacao.entradaSaidaInterna == -1) || (movimentacao.identificacao == 2 && movimentacao.entradaSaidaInterna == -1) || (movimentacao.identificacao == 3 && movimentacao.entradaSaidaInterna == -1)" class="r-1-5 text-right relatorio-rotulo">{{ bibDialogo.cliente + ': ' }} </span>
          <span *ngIf="(movimentacao.identificacao == 2 && movimentacao.entradaSaidaInterna == 1) || (movimentacao.identificacao == 3 && movimentacao.entradaSaidaInterna == 1) || (movimentacao.identificacao == 4 && movimentacao.entradaSaidaInterna == 1)" class="r-1-5 text-right relatorio-rotulo">{{ bibDialogo.fornecedor + ': ' }} </span>
          <span *ngIf="movimentacao.identificacao == 5 || movimentacao.identificacao == 6 || movimentacao.identificacao == 7 || movimentacao.identificacao == 8" class="r-1-5 text-right relatorio-rotulo">{{ bibDialogo.colaborador + ': ' }} </span> <span class="r-6 limitar"> {{ movimentacao.parceiro }}</span> <span class="relatorio-rotulo" *ngIf="movimentacao.consumidorFinal == 'S' && movimentacao.movimentacaoCpfCnpj != null">{{ bibDialogo.cpf + ': ' }} </span> <span class="r-2" *ngIf="movimentacao.consumidorFinal == 'S' && movimentacao.movimentacaoCpfCnpj != null"> {{ movimentacao.movimentacaoCpfCnpj }} </span>
          <span class="relatorio-rotulo r-2 text-right">{{ bibDialogo.telefone + ':' }} </span> <span class="r-2"> {{ movimentacao.parceiroTelefone }} </span>
        </div>                
        <div class="row">
          <span class="relatorio-rotulo r-1-5  relatorio-rotul text-right">{{ bibDialogo.cpf + ':' }} </span> <span class="r-3"> {{ movimentacao.parceiroCnpjCpf }} </span> <span class="relatorio-rotulo r-2 text-right">{{ bibDialogo.inscricao + ':' }} </span> <span class="r-4"> {{ movimentacao.parceiroInscricaoEstadual }} </span>
        </div>        
        <div class="row">
          <span class="r-1-5 text-right relatorio-rotulo">{{ bibDialogo.endereco + ': ' }} </span>
          <div class="alinhar-vertical r-10-5 limitar">
            <span class="fonte-endereco"> {{ movimentacao.parceiroEndereco }} </span>
          </div>
        </div>
      </td>
    </tr>
    <tr *ngIf="((i + 1 == 1 || (i + 1) % quantidadProduto == 0) && i + 1 != contagem) || contagem == 1">
      <td colspan="2" class="bottom p-0 m-0">
        <div [ngClass]="ehMatricial ? 'matricial' : ''" class="tabela-produtos">
          <thead>
            <tr>
              <th class="r-0-5 text-right">{{ bibDialogo.numeroAbreviacao }}</th>
              <th class="r-1 text-right">{{ bibDialogo.quantidade }}</th>
              <th class="r-0-5">{{ bibDialogo.unidadeAbreviacao }}</th>
              <th class="r">{{ bibDialogo.descricaoPodutos }}</th>
              <th *ngIf="usaValorUnitario || usaDescontoValorFinal" class="r-1 text-right">{{ bibDialogo.valor }}<br/>{{ bibDialogo.unitario.toLowerCase() }}</th>
              <th *ngIf="usaDescontoValorFinal" class="r-1 text-right">{{ bibDialogo.desconto }}
              <th *ngIf="usaValorUnitario && !usaDescontoValorFinal" class="r-1 text-right">{{ bibDialogo.total }}</th>
              <th *ngIf="usaDescontoValorFinal" class="r-1 text-right">{{ bibDialogo.total }}</th>
              <th *ngIf="usaDesconto" class="r-1 text-right">{{ bibDialogo.valorTabela }}</th>
              <th *ngIf="usaDesconto" class="r-1 text-right">{{ bibDialogo.desconto }}</th>
              <th *ngIf="usaDesconto" class="r-1 text-right">{{ bibDialogo.valor }}<br/>{{ bibDialogo.unitario.toLowerCase() }}</th>
              <th *ngIf="usaValorUnitarioComDesconto" class="r-2 text-right">{{ bibDialogo.valorUnitario }}</th>
              <th *ngIf="usaValorUnitarioComDesconto || usaDesconto" class="r-1 text-right">{{ bibDialogo.total }}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let movimentacaoProduto of movimentacaoProdutos | filtrarMovimentacaoVinculada: { idMovimentacao: movimentacao.id }; let j = index">
              <ng-container *ngIf="j + 1 >= i + 1 + (i == 0 ? 0 : 1) && j + 1 <= i + quantidadProduto + (i == 0 ? 0 : 1)">
                <ng-container *ngIf="j == (quantidadProduto-1) && movimentacaoProdutos.length > quantidadProduto">
                  <td colspan="100" class="relatorio-rotulo"><atencao [atencao]="movimentacao.operacao + ' COM MUITOS ITENS (' + movimentacaoProdutos.length + ') PARA IMPRESSÃO NESTE MODELO DE FORMULÁRIO. FALTANDO ' + (movimentacaoProdutos.length - (quantidadProduto - 1)) + ' REGISTROS PARA IMPRESSÃO.'" [tipo]="bibPropriedade.atencao.alerta"></atencao></td>
                </ng-container>
                <ng-container *ngIf="j < (quantidadProduto-1) || movimentacaoProdutos.length <= quantidadProduto">
                  <td class="r-0-5 text-right">{{ movimentacaoProduto.produtoNumero }}</td>
                  <td class="r-1 text-right">{{ movimentacaoProduto.quantidade | monetario: this.empresaRegraCasasDecimais }}</td>
                  <td class="r-0-5 fonte-endereco limitar">{{ movimentacaoProduto.unidadeAbreviacao }}</td>
                  <td class="r">{{ movimentacaoProduto.produto.length > 60 ? movimentacaoProduto.produto.substring(0, 60)+'...' : movimentacaoProduto.produto }}{{usaLote && movimentacaoProduto.lote && movimentacaoProduto.lote.length > 0 ? ' - LOTE: ' + movimentacaoProduto.lote : ''}}</td>
                  <td *ngIf="usaValorUnitario || usaDescontoValorFinal" class="r-1 text-right">{{ movimentacaoProduto.valorUnitario + movimentacaoProduto.descontoValorUnitario | monetario: this.empresaRegraCasasDecimais }}</td>
                  <td *ngIf="usaDescontoValorFinal" class="r-1 text-right">{{ movimentacaoProduto.descontoValor + (movimentacaoProduto.quantidade * movimentacaoProduto.descontoValorUnitario) + movimentacaoProduto.descontoRateado | monetario: this.empresaRegraCasasDecimais }}</td>
                  <td *ngIf="usaValorUnitario && !usaDescontoValorFinal" class="r-1 text-right">{{ movimentacaoProduto.quantidade * (movimentacaoProduto.valorUnitario + movimentacaoProduto.descontoValorUnitario) | monetario }}</td>
                  <td *ngIf="usaDescontoValorFinal" class="r-1 text-right">{{ movimentacaoProduto.valorFinal | monetario }}</td>
                  <td *ngIf="usaDesconto" class="r-1 text-right">{{ movimentacaoProduto.valorTabela | monetario }}</td>  
                  <td *ngIf="usaDesconto" class="r-1 text-right">{{ (movimentacaoProduto.descontoValor / movimentacaoProduto.quantidade) + (movimentacaoProduto.descontoValorUnitario) | monetario }}</td>   
                  <td *ngIf="usaDesconto" class="r-1 text-right">{{ (movimentacaoProduto.valorTotal / movimentacaoProduto.quantidade) | monetario }}</td>
                  <td *ngIf="usaValorUnitarioComDesconto" class="r-2 text-right">{{ (movimentacaoProduto.valorTotal / movimentacaoProduto.quantidade) | monetario }}</td>  
                  <td *ngIf="usaValorUnitarioComDesconto || usaDesconto" class="r-1 text-right">{{ movimentacaoProduto.valorTotal | monetario }}</td>                           
                </ng-container>
              </ng-container>
            </tr>
          </tbody>
        </div>
      </td>
    </tr>
    <tr>
      <td *ngIf="usaObservacao && ((i + 1 == 1 || (i + 1) % quantidadProduto == 0) && i + 1 != contagem) || contagem == 1" colspan="2" class="bottom">
        <span class="r-1 relatorio-rotulo text-right">{{ bibDialogo.observacao + ': ' }} </span> <span class="limitar ml-1">{{ movimentacao.observacao }}</span>
      </td>
    </tr>
    <tr *ngIf="(movimentacaoProdutoTotais[0].pesoTotalLiquido && movimentacaoProdutoTotais[0].pesoTotalLiquido > 0) || (movimentacaoProdutoTotais[0].pesoTotalBruto && movimentacaoProdutoTotais[0].pesoTotalBruto > 0)">
      <td *ngIf="((i + 1 == 1 || (i + 1) % quantidadProduto == 0) && i + 1 != contagem) || contagem == 1" class="bottom p-0">
        <tr style="border: 0px;">
          <td *ngIf="movimentacaoProdutoTotais[0].pesoTotalLiquido && movimentacaoProdutoTotais[0].pesoTotalLiquido > 0" class="r-4" style="border: 0px;"><span class="r-1 relatorio-rotulo text-right">{{ bibDialogo.pesoLiquido + ': ' }} </span> <span class="limitar ml-1">{{ movimentacaoProdutoTotais[0].pesoTotalLiquido ? (movimentacaoProdutoTotais[0].pesoTotalLiquido | monetario) + ' kg' : '' }}</span></td>
          <td *ngIf="movimentacaoProdutoTotais[0].pesoTotalBruto && movimentacaoProdutoTotais[0].pesoTotalBruto > 0" class="r-4" style="border: 0px;"><span class="tr-1 relatorio-rotulo text-right">{{ bibDialogo.pesoBruto + ': ' }} </span> <span class="limitar ml-1">{{ movimentacaoProdutoTotais[0].pesoTotalBruto ? (movimentacaoProdutoTotais[0].pesoTotalBruto | monetario) + ' kg' : '' }}</span></td>
        </tr>
      </td>
      <td>
      </td>    
    </tr>    
    <tr class="altura-fixa-vencimento mt-0" *ngIf="((i + 1 == 1 || (i + 1) % quantidadProduto == 0) && i + 1 != contagem) || contagem == 1">
      <td>
        <div class="col r-8-5 row">
          <div class="r-1-5">
            <span *ngIf="usaFormaPagamento" class="text-right relatorio-rotulo">{{ bibDialogo.vencimento + '(S): ' }}</span>
          </div>
          <ng-container *ngFor="let movimentacaoParcela of movimentacaoParcelas | filtrarMovimentacaoVinculada: { idMovimentacao: movimentacao.id }; let i = index">
            <div class="r-2">
              <span *ngIf="usaFormaPagamento">{{ i + 1 + 'º: ' }} {{ movimentacaoParcela.dataVencimento | data }} {{ ' - ' }} {{ movimentacaoParcela.valorParcela | monetario }} </span>
            </div>
          </ng-container>
        </div>
        <div class="col r-8-5 row">
          <span *ngIf="usaFormaPagamento" class="text-right relatorio-rotulo">{{ bibDialogo.tipoPagamento + ': ' }}</span><span *ngIf="usaFormaPagamento">{{ montarFormaPagamento(movimentacao.id) }}</span>
        </div>
      </td>
      <td>
        <div *ngIf="movimentacao.descontoTotal > 0 && (usaValorUnitario || usaDescontoValorFinal || usaDesconto || usaValorUnitarioComDesconto)" class="r-3-5 row justify-content-end relatorio-rotulo">{{ bibDialogo.totais }}</div>
        <div *ngIf="movimentacao.descontoTotal > 0 && usaDescontoValorFinal == true" class="row">
          <span class="r-1-5 relatorio-rotulo text-right">{{ bibDialogo.subTotal + ': ' }} </span> <span class="r-2 text-right"> {{ movimentacao.valorTotal + movimentacao.descontoTotal | monetario }}</span>
        </div>
        <div *ngIf="movimentacao.descontoTotal > 0 && usaDescontoValorFinal == true" class="row">
          <span class="r-1-5 relatorio-rotulo text-right">{{ bibDialogo.desconto + ': ' }} </span> <span *ngIf="movimentacao.descontoTotal > 0" class="r-2 text-right"> {{ movimentacao.descontoTotal | monetario }}</span>
        </div>
        <div *ngIf="mostraTotal == true" class="row">
          <span class="r-1-5 relatorio-rotulo text-right">{{ bibDialogo.total + ': ' }} </span> <span class="r-2 text-right relatorio-negrito"> {{ movimentacao.valorTotal | monetario }}</span>
        </div>
      </td>
    </tr>
    <tr *ngIf="((i + 1 == 1 || (i + 1) % quantidadProduto == 0) && i + 1 != contagem) || contagem == 1">
      <td colspan="2">
        <div class="row justify-content-center">
          <div class="r-6 assinatura text-center">{{ movimentacao.parceiroRazaoSocial }}</div>
        </div>
      </td>
    </tr>    
    <tr *ngIf="empresa.contrato && (((i + 1 == 1 || (i + 1) % quantidadProduto == 0) && i + 1 != contagem) || contagem == 1)">
      <td colspan="2">
        <div class="row p-0 m-0">
          <div class="border-right border-black r text-center">{{ empresa.contrato.substring(0, empresa.contrato.length) }}</div>
        </div>
      </td>
    </tr>
  </tbody>
</table>
