import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { plainToClass } from 'class-transformer';
import bibAtalho from 'src/app/biblioteca/bibAtalho';
import bibClasse from 'src/app/biblioteca/bibClasse';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import bibIcone from 'src/app/biblioteca/bibIcone';
import bibPropriedade from 'src/app/biblioteca/bibPropriedade';
import bibServico from 'src/app/biblioteca/bibServico';
import { Criterio } from 'src/app/modelo/criterio';
import { Filtro } from 'src/app/modelo/filtro';
import { Identificacao } from 'src/app/modelo/identificacao';
import { Permissao } from 'src/app/modelo/permissao';
import { Relatorio } from 'src/app/modelo/relatorio';
import { RelatorioUsuario } from 'src/app/modelo/relatorioUsuario';
import { Transporte } from 'src/app/modelo/transporte';
import { UsuarioMenu } from 'src/app/modelo/usuarioMenu';
import { Utilitario } from 'src/app/modelo/utilitario';
import { VideoComponent } from 'src/app/pagina/video/video.component';
import { ComunicacaoService } from 'src/app/servico/comunicacao.service';
import { Util } from 'src/app/utilitario/util';
import { UtilNotificacao } from 'src/app/utilitario/util.notificacao';
import { UtilSessao } from 'src/app/utilitario/util.sessao';
import { FiltroComponent } from '../filtro/filtro.component';
import { ModalNotificacao } from '../modal/modal.notificacao';

@Component({
  selector: 'layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.css'],
})
export class LayoutComponent {
  @Input() componente: any;
  @Input() id: string;
  @Input() chave: string;
  @Input() subTitulo: string;
  @Input() tag: string;
  @Input() titulo: string;
  @Input() utilitarios: Utilitario[] = [];
  @Input() mostraRelatorio: boolean = true;
  @Output() filtroEmt = new EventEmitter<Criterio[]>();
  @Output() filtroAbertoEmt = new EventEmitter<boolean>();
  @Output() relatorioTituloAdicional = new EventEmitter<string>();
  @Output() utilitarioEmt = new EventEmitter<string>();
  @Output() voltarAvancarEmt = new EventEmitter<boolean>();
  private util: Util;
  public apresentaDescricao: boolean = false;
  public apresentaImpressao: boolean = true;
  public bibClasse = bibClasse;
  public bibDialogo = bibDialogo;
  public bibIcone = bibIcone;
  public bibPropriedade = bibPropriedade;
  public criterios: Criterio[];
  public edicao: boolean = this.utilSessao.getPermissao() && this.utilSessao.getPermissao().editar == 'N' ? true : false;
  public editaTituloRelatorio: boolean = false;
  public ehModoClaro: boolean = this.utilSessao.getUsuario().usaModoClaro == 'S';
  public frm: boolean = location.href.indexOf('Frm') > 0;
  public menuCompendio: boolean = this.utilSessao.getPermissao() && this.utilSessao.getPermissao().idMenu == 141 ? true : false;
  public modal: boolean = false;
  public telaInterna: boolean = false;
  public mostraId: boolean;
  public permissao: Permissao;
  public utilitarioRelatorioMenus: Utilitario[] = [];
  public utilitarioAvancar = new Utilitario(bibIcone.setaDireita, this.bibDialogo.avancar);
  public utilitarioFavorito = new Utilitario(bibIcone.estrela, this.bibDialogo.favorito);
  public utilitarioFechar = new Utilitario(bibIcone.sair, this.bibDialogo.fechar + ' ' + this.bibDialogo.f9);
  public utilitarioFiltro = new Utilitario(bibIcone.filtro, this.bibDialogo.pesquisar + ' ' + bibDialogo.f2);
  public utilitarioImprimir = new Utilitario(bibIcone.impressora, this.bibDialogo.imprimir);
  public utilitarioVoltar = new Utilitario(bibIcone.setaEsquerda, this.bibDialogo.voltar);
  public podeAdicionarLoja: boolean = false;

  public _idRelatorioInterno: number = null;
  @Input()
  set idRelatorioInterno(idRelatorioInterno: number) {
    this._idRelatorioInterno = idRelatorioInterno;
  }

  public _relatorio: Relatorio;
  @Input()
  set relatorio(relatorio: Relatorio) {
    this._relatorio = relatorio;
    this.excluirFiltroId();
  }

  public _filtros: Filtro[] = [];
  @Input()
  set filtros(filtros: Filtro[]) {
    if (filtros != undefined) {
      filtros = this.criarFiltroId(filtros);
      filtros.forEach((filtro) => {
        if (filtro.idRelatorio == null && this.utilSessao.getPermissao()) {
          filtro.idMenu = this.utilSessao.getPermissao().idMenu;
          this.atualizarRotuloFiltro(filtro);
          this.atualizarRotuloNomenclatura(filtro);
        }
      });
      this._filtros = filtros;
      this.atualizarDataFiltro();
      this.utilSessao.setFiltro(this._filtros);
    }
  }

  criarFiltroId(filtros: Filtro[]): Filtro[] {
    if (this._relatorio == null) {
      const temFiltroId: boolean = filtros.filter((_filtro) => _filtro.nome == 'ID').length > 0 ? true : false;
      if (this.utilSessao.getUsuario()?.agrow == 'S') {
        if (temFiltroId == false) {
          const filtro: Filtro = new Filtro();
          filtro.tipo = 3;
          filtro.nome = 'ID';
          filtro.rotulo = 'ID';
          filtro.coluna = 12;
          filtros.unshift(filtro);
        }
      }
    }
    return filtros;
  }

  excluirFiltroId(): void {
    if (this.filtros) {
      if (this.filtros[0].nome == 'ID') {
        this.filtros.splice(0, 1);
      }
    }
  }

  constructor(public utilSessao: UtilSessao, public activatedRoute: ActivatedRoute, private router: Router, private modalNotificacao: ModalNotificacao, private utilNotificacao: UtilNotificacao, private comunicacaoService: ComunicacaoService) {
    this.util = new Util(router);
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(() => {
      this.permissao = this.utilSessao.getPermissao();
      this.limparCriterioFiltroMenuAtual();
      this.setRelatorioMenu();
      this.ehAtualizaIdMenuFiltro();
      this.utilSessao.setTelaHistorico(this.router.url);
    });
    this.modal = this.utilSessao.modal;
    this.telaInterna = this.utilSessao.telaInterna;
    this.mostraId = this.utilSessao.mostraId;
    this.modalNotificacao.modalEmt.subscribe((identificacao) => {
      if (identificacao != null && identificacao.nome == this.bibClasse.criterio) {
        this.utilSessao.posicao = 0;
        this.utilSessao.numeroRegistro = 0;
        this.utilSessao.setCriterios(identificacao.conteudo);
        this.filtroEmt.emit(plainToClass(Criterio, identificacao.conteudo) as any);
      }
    });
    const filtroNome: String = this.criarFiltroNome();
    if (this.utilSessao.getFiltroNome() == filtroNome && !this.modal) {
      this.criterios = this.utilSessao.getCriterio();
      this.criterios = this.criterios.filter((criterio) => this.permissao && criterio.idMenu == this.permissao.idMenu && criterio.valor != '' && criterio.valor != '-');
      this.criterios.forEach((criterio) => {
        if (criterio.tipo == 12) {
          criterio.valor = criterio.valor.map((registro) => registro.id).toString();
        }
      });
      this.utilSessao.setCriterios(this.criterios);
      if (this.criterios && this.criterios.length > 0) {
        this.filtroEmt.emit(plainToClass(Criterio, this.criterios) as any);
      }
    } else {
      if (!this.modal) {
        this.utilSessao.excluirIdentificacao('id');
        this.utilSessao.setFiltroNome(null);
        this.utilSessao.setPaginaAtual(null);
        this.utilSessao.setCriterios(null);
        this.utilSessao.numeroRegistro = 0;
      }
    }
    this.utilNotificacao.mostrarIdEmitter.subscribe((mostraId) => (this.mostraId = mostraId));
    this.utilNotificacao.ehModoClaroEmmiter.subscribe((modoClaro) => {
      this.ehModoClaro = modoClaro;
    });
    this.utilSessao.limparResultado();
    this.ehFecharNavegador();
    this.mostrarId();
    this.podeAdicionarLoja = this.utilSessao.getUsuario().agrow == 'S' && this.permissao && this.permissao.idMenu == 70;
  }

  mostrarId(): void {
    if (this.utilSessao.getUsuario().agrow == 'S') {
      this.mostraId = true;
    }
  }

  copiar(conteudo: string) {
    event.preventDefault();
    navigator.clipboard.writeText(conteudo);
  }

  atualizarRotuloFiltro(filtro: Filtro): void {
    this.utilSessao.getMenuEmpresa().forEach((menuEmpresa) => {
      if (filtro.rotulo && filtro.rotulo.toLocaleLowerCase() == menuEmpresa.menu.toLocaleLowerCase()) {
        filtro.rotulo = menuEmpresa.apelido;
        filtro.visualizacaoInicial = menuEmpresa.ativo == 'S' ? true : false;
      }
    });
  }

  atualizarRotuloNomenclatura(filtro: Filtro): void {
    this.utilSessao.getNomenclaturaEmpresas().forEach((nomenclaturaEmpresa) => {
      if (filtro.rotulo && filtro.rotulo.toLocaleLowerCase().replace('nº ', '') == nomenclaturaEmpresa.nome.toLocaleLowerCase()) {
        filtro.rotulo = filtro.rotulo.startsWith('Nº') ? 'Nº ' + nomenclaturaEmpresa.apelido.toLocaleLowerCase() : nomenclaturaEmpresa.apelido;
      }
    });
  }

  ehAtualizaIdMenuFiltro(): void {
    if (this._filtros[0] && this._filtros[0].idMenu && this._filtros[0].idMenu != this.utilSessao.getPermissao().idMenu) {
      this._filtros.forEach((filtro) => {
        filtro.idMenu = this.utilSessao.getPermissao().idMenu;
      });
      this.utilSessao.setFiltro(this._filtros);
    }
  }

  setRelatorioMenu(): void {
    this.utilSessao.getRelatorioMenus().forEach((relatorioMenu) => {
      if (this.permissao) {
        if (relatorioMenu.idMenu == this.permissao.idMenu) {
          const utilitario: Utilitario = new Utilitario(bibIcone.relatorio, relatorioMenu.idRelatorio.toString(), (relatorioMenu.idRelatorio + ' - ' + relatorioMenu.relatorio).toString());
          this.utilitarioRelatorioMenus.push(utilitario);
          this.apresentaImpressao = false;
        }
      }
    });
  }

  criarFiltroNome(): string {
    const idRelatorio: number = this.utilSessao.getIdentificacao('idRelatorio')?.conteudo;
    const idIntegracao: number = this.utilSessao.getIdentificacao('idIntegracao')?.conteudo;
    if (idRelatorio) {
      return 'Relatório ' + idRelatorio;
    } else {
      if (idIntegracao) {
        return 'Integração ' + idIntegracao;
      } else {
        return 'Menu ' + (this.permissao ? this.permissao.idMenu + '_' + this.permissao.parametro + '_' + this.permissao.idOperacao : ' minhaConta');
      }
    }
  }

  @HostListener('window:keyup', ['$event'])
  keyboardInput(event: KeyboardEvent) {
    if (this.utilSessao.informacaoAlert && bibAtalho.enter) {
      this.ehFechar();
    }
    if (event.ctrlKey && event.key === bibAtalho.b) {
      event.preventDefault();
      if (!this.utilSessao.modal) {
        this.utilNotificacao.salvarEmt.emit(true);
      }
    }
    if (event.ctrlKey && event.key === bibAtalho.bMaiusculo) {
      event.preventDefault();
      if (!this.utilSessao.modal) {
        this.utilNotificacao.finalizarEmt.emit(true);
      }
    }
    if (event.ctrlKey && event.keyCode == bibAtalho.setaEsquerda && event.shiftKey) {
      this.voltarAvancar(true);
    }
    if (event.ctrlKey && event.keyCode == bibAtalho.setaDireita && event.shiftKey) {
      this.voltarAvancar(false);
    }
    if (this.utilSessao.getBloqueado() != true) {
      if (this.utilSessao.getResultados().length > 0 && event.keyCode == bibAtalho.espaco) {
        event.preventDefault();
        this.utilSessao.limparResultado();
      } else {
        switch (event.keyCode) {
          case bibAtalho.novo:
            if (!this.frm && ((this.permissao && this.permissao.inserir == 'S' && this.permissao.insercao == 'S') || this.podeAdicionarLoja)) {
              this.irFormularioNovo();
            }
            break;
          case bibAtalho.listar:
            this.abrirFiltro();
            break;
          case bibAtalho.fechar:
            this.ehFechar();
            break;
        }
      }
    }
  }

  ehFecharNavegador(): void {
    window.onbeforeunload = () => {
      if (this.frm == true && this.utilSessao.ambienteProducao == true && this.utilSessao.getAlteracao() && !this._relatorio) {
        return bibDialogo.desejaSair;
      }
    };
  }

  ehDesejaSalvar(): boolean {
    if (this.utilSessao.ambienteProducao == true && this.utilSessao.getAlteracao() && (!this.utilSessao.modal || this.telaInterna) && !this.utilSessao.falha && !this._relatorio) {
      if (confirm(bibDialogo.desejaSair)) {
        this.utilSessao.setAlteracao(false);
        return true;
      }
      return false;
    } else {
      return true;
    }
  }

  ehFechar(): void {
    if (this._relatorio) {
      window.close();
    }
    if (this.frm) {
      this.fechar();
    } else {
      if (this.ehDesejaSalvar()) {
        this.fechar();
      }
    }
  }

  favoritarTela(): void {
    this.permissao.favorito = this.permissao.favorito == 'S' ? 'N' : 'S';
    const permissoes = this.utilSessao.getPermissoes();
    let permissao = permissoes.filter((permissao) => permissao.idMenuTemporario == this.permissao.idMenuTemporario)[0];
    permissao.favorito = this.permissao.favorito;
    this.utilSessao.setPermissao(this.permissao);
    this.utilSessao.setPermissoes(permissoes);
    let usuarioMenu: UsuarioMenu = this.utilSessao.getUsuarioMenus().find((usuarioMenu) => usuarioMenu.idMenu == this.permissao.idMenu);
    usuarioMenu.favorito = this.permissao.favorito;
    this.comunicacaoService.persistir(new Transporte(usuarioMenu), bibServico.usuarioMenu).subscribe();
  }

  favoritarRelatorio(): void {
    this._relatorio.favorito = this._relatorio.favorito == 'S' ? 'N' : 'S';
    let relatorios: Relatorio[] = this.utilSessao.getRelatorios();
    relatorios.forEach((relatorio) => {
      if (relatorio.id == this._relatorio.id) {
        relatorio.favorito = this._relatorio.favorito;
        this.persistirRelatorioUsuario(relatorio);
      }
    });
    this.utilSessao.setRelatorios(relatorios);
  }

  persistirRelatorioUsuario(relatorio: Relatorio): void {
    let relatorioDashboardUsuarios: RelatorioUsuario[] = this.utilSessao.getRelatorioUsuarios().concat(this.utilSessao.getDashboardUsuarios());
    let relatorioUsuario: RelatorioUsuario = relatorioDashboardUsuarios.find((relatorioUsuario) => relatorioUsuario.idRelatorio == relatorio.id);
    relatorioUsuario.favorito = relatorio.favorito;
    this.comunicacaoService.persistir(new Transporte(relatorioUsuario), bibServico.relatorioUsuario).subscribe();
  }

  imprimir(): void {
    print();
  }

  fechar(): void {
    event.preventDefault();
    if (this.utilSessao.falha && this.utilSessao.getResultados().length > 0) {
      this.utilSessao.limparResultado();
    } else {
      if (this.utilSessao.modal == true && this.utilSessao.telaInterna == false) {
        this.modalNotificacao.modalEmt.emit(new Identificacao());
      } else {
        if (this.frm) {
          this.fecharFrm();
        } else {
          sessionStorage.removeItem('criterios');
          sessionStorage.removeItem('filtroNome');
          this.router.navigate(['/']);
        }
      }
    }
  }

  fecharFrm(): void {
    event.preventDefault();
    if (window.location.href.indexOf('/usuarioFrm/MinhaConta') > 0 || (window.location.href.indexOf('/receituario/Frm') > 0 && window.history.length <= 1) ? true : false) {
      this.router.navigate(['/']);
    } else {
      if (this.ehDesejaSalvar()) {
        history.back();
      }
    }
  }

  irFormularioNovo(): void {
    let url: string = this.permissao.tela;
    if (!this.frm && (this.permissao.inserir == 'S' || this.podeAdicionarLoja)) {
      if (this.permissao.modularizado == 'S') {
        url += '/Frm';
      } else {
        url += 'Frm';
      }
      this.utilSessao.limparIdentificacoes();
      if (this.permissao.parametro) {
        this.utilSessao.setIdentificacao(new Identificacao('parametro', this.permissao.parametro));
      }
      this.router.navigate([url]);
    }
  }

  abrirFiltro(): void {
    this.filtroAbertoEmt.emit(true);
    this.utilSessao.setIdentificacao(new Identificacao('filtro', true));
    this.utilSessao.limparResultado();
    this.atualizarDataFiltro();
    this.utilSessao.setFiltro(this._filtros);
    if (!this.frm && ((this._relatorio && this._relatorio.usaFiltro) || (this.permissao && this.permissao.filtro == 'S') || (this.permissao && this.permissao.filtro == 'O'))) {
      this.componente = FiltroComponent;
    } else {
      this.utilSessao.setCriterios(null);
      this.filtroEmt.emit(null);
    }
  }

  atualizarDataFiltro(): void {
    this._filtros.forEach((filtro) => {
      if (filtro.tipo == 1 && filtro.valor != '') {
        filtro.valor = new Date();
      }
    });
  }

  setFiltroSessao(): void {
    if (this.filtros) {
      if (this._relatorio) {
        const filtros: Filtro[] = this.utilSessao.getFiltroRelatorio(this._relatorio.id);
        if (filtros.length == 0) {
          this.utilSessao.setFiltro(this.filtros);
        }
      } else {
        const filtros: Filtro[] = this.utilSessao.getFiltro(this.permissao.idMenu);
        if (filtros.length == 0) {
          this.utilSessao.setFiltro(this.filtros);
        }
      }
    }
  }

  ngOnDestroy(): void {
    this.apresentaImpressao = true;
    this.utilSessao.setPermissaoEspecifica(null);
    this.utilSessao.telaInterna = false;
    if (!this.utilSessao.modal) {
      this.utilSessao.setAlteracao(false);
    }
  }

  limparCriterioFiltroMenuAtual() {
    let rota: string = this.router.url?.split('/').length > 1 ? this.router.url.split('/')[1] : '';
    let rotaAnterior = this.utilSessao.getTelaHistorico()?.split('/').length > 1 ? this.utilSessao.getTelaHistorico().split('/')[1] : '';
    let ehFrmRota: boolean = false;
    let ehFrmRotaAnterior: boolean = false;
    if (rota.length > 3) {
      ehFrmRota = rota.slice(-3) == 'Frm' ? true : false;
      rota = ehFrmRota ? rota.slice(0, -3) : rota;
    }
    if (rotaAnterior.length > 3) {
      ehFrmRotaAnterior = rotaAnterior.slice(-3) == 'Frm' ? true : false;
      rotaAnterior = ehFrmRotaAnterior ? rotaAnterior.slice(0, -3) : rotaAnterior;
    }
    if ((rotaAnterior != 'login' && rota != rotaAnterior) || (rota == rotaAnterior && this.utilSessao.getParametro() != this.utilSessao.getParametroHistorico())) {
      this.utilSessao.setLimparCriterio(this.utilSessao.getPermissao()?.idMenu, true);
    }
  }

  irRelatorio(idRelatorio: number): void {
    if (this.id != null) {
      this.utilSessao.setRelatorioCriterios([new Criterio('ID', this.id, null, idRelatorio, 'ID', this.id)]);
    }
    if (this.chave != null) {
      this.utilSessao.setRelatorioCriterios([new Criterio('CHAVE', this.chave, null, idRelatorio, 'CHAVE', this.chave)]);
    }
    this.util.irRelatorio(idRelatorio);
  }

  notificarUtilitario(legenda: string): void {
    this.utilitarioEmt.emit(legenda);
  }

  voltarAvancar(voltando: boolean): void {
    if (this.ehDesejaSalvar()) {
      const posicaoInicial: number = this.util.coalesce(this.utilSessao.getIdentificacao('posicao')?.conteudo, 0);
      let posicao: number = posicaoInicial;
      const ids: number[] = this.utilSessao.getIdentificacao('ids')?.conteudo;
      if (ids && posicao >= 0) {
        if (voltando) {
          if (posicao - 1 >= 0) {
            posicao--;
          }
        } else {
          if (posicao + 1 < ids.length) {
            posicao++;
          }
        }
        if (posicao != posicaoInicial) {
          this.utilSessao.setIdentificacao(new Identificacao('id', ids[posicao]));
          this.utilSessao.setIdentificacao(new Identificacao('posicao', posicao));
          this.utilSessao.setIdentificacao(new Identificacao('ids', ids));
          this.voltarAvancarEmt.emit(true);
        }
      }
    }
  }

  sair(): void {
    this.router.navigate(['/login' + this.utilSessao.getDominio('/')]);
    sessionStorage.clear();
    localStorage.clear();
    localStorage.setItem('dataAtualLogin', JSON.stringify(new Date()));
  }

  abrirModalYoutube(): void {
    event.preventDefault();
    this.utilSessao.setIdentificacao(new Identificacao('botaoFecharLateral', true));
    this.componente = VideoComponent;
  }
}
